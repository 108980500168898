import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import { useEffect } from "react";
import {ThreeDots} from 'react-loader-spinner';
import useSignatories from "../../hooks/useSignatories";


const DeleteSignatoryDialog = (props) => {

    const {openDelete, handleCloseDelete, record} = props;

    console.log("record here: " , record)

    const { deleteSignatory, isDeleted, isDeleting } = useSignatories();

    const handleCloseDeleteDialog = (e) => {
        handleCloseDelete();
    }

    const handleDeleteSignatory = () => {
        deleteSignatory(record?.id);
    }

    useEffect(() => {
        if (isDeleted) {
            handleCloseDeleteDialog();
            window.location.reload();

            localStorage.removeItem("savedSignatoriesList");
        }
    }, [isDeleted])


    return(
        <Dialog open={openDelete} onClose={handleCloseDeleteDialog} handleCloseDelete={handleCloseDelete} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Delete Signatory
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography>
                    Are you sure you want to delete <b>{record?.name}</b> ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, textTransform: "none"}} 
                    onClick={(e) => handleCloseDeleteDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: "none", width: 'auto', backgroundColor: '#0178f2', borderRadius: 10, boxShadow: 'none'}}
                    onClick={(e) => handleDeleteSignatory(e)}
                    startIcon={isDeleting && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {isDeleting ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteSignatoryDialog