import React, {useState, useRef, useEffect} from "react";
import {Stack, Slider, IconButton, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import {convertUrlToFile} from "../../utils/convertUrlToFileUtil";
import {ThreeDots} from "react-loader-spinner";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ImageCropperDialog = (props) => {

    const {breakpoints, openImageEditor, setOpenImageEditor, userImg, setImageStateFromChild, setPhoto, setPhotoFile, setUserPhotoPreview, setPhotoPreview} = props;

    const url = window.location.pathname;

    const editorRef = useRef();
    const [image, setImage] = useState(null);
    const [isSavingPhoto, setSavingPhoto] = useState(false);
    const [zoomValue, setZoomValue] = useState(1.2);
    const [isZoomInDisabled, setZoomInDisabled] = useState(false);
    const [isZoomOutDisabled, setZoomOutDisabled] = useState(false);

    useEffect(() => {
        if (userImg) {
            setImage(userImg);
        }

    }, [userImg])

    const handleSliderChange = (event, newValue) => {
        let minValue = 1;
        let maxValue = 10;

        if ((zoomValue == minValue) || (zoomValue < minValue)) {
            setZoomInDisabled(true);
            setZoomOutDisabled(false);
        
        } else if ((zoomValue == maxValue) || (zoomValue > maxValue)) {
            setZoomOutDisabled(true);
            setZoomInDisabled(false);

        } else {
            setZoomInDisabled(false);
            setZoomOutDisabled(false);
        }

        setZoomValue(newValue);
    }

    const handleZoomIn = () => {
        let minValue = 1;

        setZoomOutDisabled(false);

        if ((zoomValue == minValue) || (zoomValue < minValue)) {
            setZoomInDisabled(true);

        } else {
            setZoomInDisabled(false);
            setZoomValue(zoomValue - 0.1);
        }
    }

    const handleZoomOut = () => {
        let maxValue = 10;

        setZoomInDisabled(false);

        if ((zoomValue == maxValue) || (zoomValue > maxValue)) {
            setZoomOutDisabled(true);

        } else {
            setZoomOutDisabled(false);
            setZoomValue(zoomValue + 0.1);
        }
    }

    const handleCloseDialog = (e) => {
        setOpenImageEditor(false);
        setImage(null);
        setPhotoFile(null);

        if (url == "/create-record") {
            setPhoto(null);
            setImageStateFromChild(null);

        } else if (url == "/application-form") {
            setUserPhotoPreview(null);
            setImageStateFromChild(null);
        
        } else if (url == "/edit-record") {
            setPhotoPreview(null);
        } 
    }

    const handleSave = (e) => {
        setSavingPhoto(true);

        const canvas = editorRef.current.getImageScaledToCanvas();
        const base64Image = canvas.toDataURL();

        convertUrlToFile(base64Image, "photo_file.png", "image/png").then(function(file) {
            setTimeout(() => {
                if (url == "/create-record") {
                    setPhoto(base64Image);
    
                } else if (url == "/application-form") {
                    setUserPhotoPreview(base64Image);
    
                } else if (url == "/edit-record") {
                    setPhotoPreview(base64Image);
                }

                setSavingPhoto(true);
                setOpenImageEditor(false);
                setPhotoFile(file);
            }, 2000);
        })
    }

 
    return (
        <Dialog open={openImageEditor} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 10}}}
            onClose={(e) => handleCloseDialog(e)}
        >
            <DialogTitle sx={{fontWeight: "bold"}}>
                Crop Photo
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: "24px 24px 10px"}}>
                <Box sx={{objectFit: "contain", mt: -1, textAlign: "center", zoom: (breakpoints == "xs" || breakpoints == "sm") ? "30%" : "50%"}}>
                    <AvatarEditor 
                        ref={editorRef}
                        image={image}
                        border={50}
                        color={[179, 215, 243, 0.4]}
                        rotate={0}
                        width={600}
                        height={600}
                        scale={zoomValue}
                        disableBoundaryChecks={false}
                    />
                </Box>

                <Stack spacing={2} direction="row" alignItems="center" sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? {} : "16px auto 0px"}}>
                    <IconButton onClick={(e) => (isZoomInDisabled) ? null : handleZoomIn()} 
                        sx={{cursor: (isZoomInDisabled) ? "no-drop" : "pointer"}}
                    >
                        <RemoveIcon fontSize="small" sx={{color: (isZoomInDisabled) ? "#8080808f" : "#000"}} />
                    </IconButton>

                    <Slider 
                        size="large"
                        value={zoomValue} 
                        onChange={handleSliderChange}
                        aria-label="Default"
                        min={1}
                        max={10}
                        step={0.1}
                    />

                    <IconButton onClick={(e) => (isZoomOutDisabled) ? null : handleZoomOut()} 
                        sx={{cursor: (isZoomOutDisabled) ? "no-drop" : "pointer"}}
                    >
                        <AddIcon fontSize="small" sx={{color: (isZoomOutDisabled) ? "#8080808f" : "#000"}} />
                    </IconButton>
                </Stack>
            </DialogContent>
            
            <DialogActions sx={{padding: 6, paddingTop: 3, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button sx={{width: "15%", borderRadius: 10, borderColor: "#3333cf !important", color: "#3333cf !important", textTransform: "none", fontSize: 14}} variant="outlined"
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: (isSavingPhoto) ? "auto" : "15%", backgroundColor: "#168401", borderRadius: 10, textTransform: "none", 
                    fontSize: 14, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => (image) ? handleSave(e) : null}
                    startIcon={isSavingPhoto && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isSavingPhoto) ? "Saving" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ImageCropperDialog