import React from "react";
import MUIDataTable from "mui-datatables";

const CSVRecordListComponent = (props) => {

  const {recordList} = props;

  const data = recordList;

  const columns = [
    {
      name: "id_no",
      label: "ID No.",
      options: {
        filter: true,
        sort: false,
      }
    },

    {
      name: "lastname",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      }
    },

    {
      name: "firstname",
      label: "First Name",
      options: {
        filter: true,
        sort: false,
      }
    },

    {
      name: "middlename",
      label: "Middle Name",
      options: {
        filter: true,
        sort: false,
      }
    },
  ]


  return (
    <>
      <MUIDataTable
        data={data}
        columns={columns}
        options={{
          search: false,
          download: false,
          print: false,
          viewColumns: false,
          filter: false,
          filterType: "none",
          pagination: true,
          selectableRows: "none",
          selectToolbarPlacement: "none",
        }}
      />
    </>
  )
}

export default CSVRecordListComponent