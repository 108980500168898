import React from "react";
import {Box, Grid, Typography} from "@mui/material";
import useWidth from "../hooks/useWidth";
import CreateRecordsComponent from "../components/csv/create-records.component";
import useEID from "../hooks/useEID";

const CreateRecordScreen = () => {

    const breakpoints = useWidth();

    const {createEIDRecord, loadingRecordCreation} = useEID()

    return (
        <Box sx={{width: breakpoints == 'xs' || breakpoints == 's' ? "100%" : "95%", margin: "auto"}}>
            <Box style={{flexGrow: 1, margin: "auto"}}>
                <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>
                            Create eID
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        <CreateRecordsComponent breakpoints={breakpoints} createEIDRecord={createEIDRecord} loadingRecordCreation={loadingRecordCreation} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreateRecordScreen