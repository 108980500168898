import {Dialog, DialogContent, Typography } from "@mui/material";
import AccessDenied from "../../images/expired.png";

const ExpiredAccessKeyDialog = (props) => {

    const {openExpiredAccessKeyDialog, handleCloseDialog} = props;

    const handleCloseExpiredAccessDialog = () => {
        handleCloseDialog();
        window.location.href = "/";
    }


    return (
        <Dialog open={openExpiredAccessKeyDialog} onClose={(e) => handleCloseExpiredAccessDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 10}}}>
            <DialogContent sx={{textAlign: "center", padding: "30px 30px 8px"}}>
                <img alt="" src={AccessDenied} style={{width: 200, height: 200, marginLeft: "-60px"}} />
                
                <Typography sx={{fontSize: 16}}>
                    Access Key has already expired. Please coordinate with your admin.
                </Typography>
                <br/>
            </DialogContent>
        </Dialog>
    )
}

export default ExpiredAccessKeyDialog