import { Box, Card, Grid, Typography} from "@mui/material"
import {useEffect} from "react";
import RegistrationLinkComponent from "../components/pre-registration/registration-link.component";
import useWidth from "../hooks/useWidth";
import usePreRegistration from "../hooks/usePreRegistration";
import RegistrationLinkSkeletonComponent from "../components/skeletons/registration-link-skeleton-loader.component";

function Header () {
    return (
        <Box sx={{ padding:"10px 20px 0px 20px", width: "100%", borderRadius: 0}}>
            <Grid container sx={{display:'flex', alignItems:'center',}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold", textAlign: "start"}}>
                        eID Registration Link
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

function RegistrationLinkScreen() {

    const breakpoints = useWidth();

    const {  loading, retrievePreRegistrationLink, registration } = usePreRegistration();


    useEffect(() => {
        retrievePreRegistrationLink();
    },[])


    return(

        <Box sx={{overflow: "hidden", width:'100%', margin:'auto'}}>

            <Header />

            <Box style={{flexGrow: 1, margin:'auto'}}>
                <Grid container spacing={2} direction="row" sx={{padding: 3}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        {loading ?
                            <RegistrationLinkSkeletonComponent />
                        :
                            <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, margin: 'auto', paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 'sm' ? 'auto' : 530 }}>
                                <RegistrationLinkComponent data={registration} loading={loading} />
                            </Card>
                        }
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
    
}

export default RegistrationLinkScreen