import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import { HttpPostMultipart, HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost, HttpDelete, HttpPut, HttpPut1, HttpDownloadFile } from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_service.subscriptionKey;


const retrieveeIDRecordsList = (page, limit) => {

    let query_params = {
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("retrieve eID records query_params here:", query_params);

    let result = HttpGet(routes.RETRIEVE_EID_RECORD_LIST, query_params, subscriptionKey);

    return result;

}

const createEIDRecord = (formData) => {

    let payload = formData

    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("create eID payload:", payload);

    let result = HttpPost(routes.CREATE_EID_RECORD, payload, subscriptionKey);

    return result;

}

const filterByStatus = (status, page, limit) => {


    let query_params = {
        searchFor: "status",
        searchVal: status,
        page: page,
        limit: limit
    }

    let subscriptionKey = SUBSCRIPTION_KEY;


    console.log("filterByStatus payload: ", query_params);

    return HttpGet(routes.RETRIEVE_EID_RECORD_LIST, query_params, subscriptionKey);
}

const uploadCSV = (file) => {
    let payload = file;

    console.log("uploadCSV payload xxxx", payload);

    let result = HttpPostMultipart(routes.UPLOAD_CSV, payload);

    return result;
}


const vieweIDDetails = (uuid) => {

    let query_params = {
        uuid: uuid
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("vieweIDDetails query_params xxxx", query_params);

    let result = HttpGet(routes.VIEW_EID_DETAILS, query_params, subscriptionKey);

    return result;
}


const generateEID = (uuid, signatory_id) => {

    let query_params = {
        uuid: uuid,
        signatory_id: signatory_id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("generate eID query_params xxxx", query_params);

    let result = HttpGet(routes.GENERATE_EID, query_params, subscriptionKey);

    return result;
}


const sendAppBinding = (payload) => {

    console.log("send app binding  payload :", payload);

    let result = HttpPost(routes.SEND_APP_BINDING, payload);

    return result;
}


const deleteEID = (uuid) => {
    let parameters = {
        uuid: uuid
    }

    console.log("deleteDocumentWorkflow payload xxxx", parameters);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete(routes.DELETE_EID, parameters, subscriptionKey);

    return result;
}

const approveEID = (uuid) => {
    let parameters = {
        uuid: uuid
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut1(routes.APPROVE_EID, parameters, subscriptionKey);

    return result;
}

const disapproveEID = (payload) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPut(routes.DISAPPROVE_EID, payload, subscriptionKey);

    return result;
}

const updateEIDDetails = (uuid, idNo, firstName, middleName, lastName, suffix, emailAddress, formattedDob, pob, address, gender, bloodType, maritalStatus, position, contact_person, contact_person_no, photoFile, signatureFile) => {


    const formData = new FormData();

    formData.append("uuid", uuid);
    formData.append("id_no", idNo);
    formData.append("firstname", firstName);
    formData.append("middlename", middleName);
    formData.append("lastname", lastName);
    formData.append("suffix", suffix);
    formData.append("email_address", emailAddress);
    formData.append("dob", formattedDob);
    formData.append("pob", pob);
    formData.append("address", address);
    formData.append("gender", gender);
    formData.append("blood_type", bloodType);
    formData.append("marital_status", maritalStatus);
    formData.append("position", position);
    formData.append("contact_person", contact_person);
    formData.append("contact_person_no", contact_person_no);
    formData.append("photo", photoFile);
    formData.append("e_signature", signatureFile);

    console.log("photo formData xxxx", photoFile);
    console.log("signature formData xxxx", signatureFile);
    console.log("updateEIDDetails formData xxxx", formData);

    let result = HttpPut(routes.EDIT_EID_DETAILS, formData);

    return result;
}


const revokeSuspendApplication = (payload) => {

    console.log("update eid status payload :", payload);

    let result = HttpPut(routes.REVOKE_SUSPEND_EID_APPLICATION, payload);

    return result;
}


const donwloadEID = (uuid) => {

    let query_params = {
        uuid: uuid
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("download eID query_params xxxx", query_params);

    let result = HttpDownloadFile(routes.DOWNLOAD_EID, query_params, subscriptionKey);

    return result;
}


const sendEWallet = (uuid) => {
    let query_params = {
        uuid: uuid
    }

    console.log("send to eWallet payload xxxx", query_params);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.SEND_TO_EWALLET, query_params, subscriptionKey);

    return result;
}


const retrieveActivityLogs = (uuid) => {

    let query_params = {
        uuid: uuid
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    console.log("retrieveActivityLogs query_params xxxx", query_params);

    let result = HttpGet(routes.RETRIEVE_ACTIVITY_LOGS, query_params, subscriptionKey);

    return result;
}




const EIDService = {
    retrieveeIDRecordsList,
    createEIDRecord: createEIDRecord,
    filterByStatus,
    uploadCSV: uploadCSV,
    vieweIDDetails: vieweIDDetails,
    generateEID,
    sendAppBinding,
    deleteEID,
    revokeSuspendApplication,
    approveEID: approveEID,
    disapproveEID: disapproveEID,
    donwloadEID,
    updateEIDDetails: updateEIDDetails,
    sendEWallet,
    retrieveActivityLogs
}

export default EIDService;