
import {Grid, Card, CardContent, Typography, IconButton, TextField, Button} from "@mui/material";
import {Link} from "react-router-dom";
import EditIcon from "../../icons/updateHighlight.png";
import { useEffect } from "react";
import useConfig from "../../hooks/useConfig";
import ConfigSkeletonComponent from "../skeletons/config-skeleton-loader.component";

const ViewConfigEIDComponent = (props) => {

    const {breakpoints} = props;
    const { loading, retrieveConfig, config } = useConfig();

    useEffect(() => { retrieveConfig(); },[])

    let expiryUnitString;
    
    if (config?.expiry_unit === "y") {
        expiryUnitString = "Year(s)";
    } else if (config?.expiry_unit === "m") {
        expiryUnitString = "Month(s)";
    } else if (config?.expiry_unit === "d") {
        expiryUnitString = "Day(s)";
    }

    const eid_config_information = [
        {label: 'Name', value: config?.name},
        {label: 'ID Type', value: config?.id_type},
        {label: 'Expiry Duration', value: config?.expiry_duration},
        {label: 'Expiry Unit', value: expiryUnitString}
    ]


    const url = window.location.pathname;



    return (
        <>
            {loading ?
                <ConfigSkeletonComponent />
            :
                <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 'sm' ? 'auto' : url !== "/config-eid" ? 380 : 370 }}>
                    <CardContent >
                        <Grid container sx={{textAlign:'center', margin: 'auto', justifyContent: 'center', alignSelf: 'center'}}>
                            <Grid item xs={12} sm={12} sx={{textAlign: 'end'}}>
                                {/* <IconButton component={Link} to='/update-config-eid'>
                                    <img alt="edit-icon" src={EditIcon} style={{height: 15}} />
                                </IconButton> */}
                                  <Button variant="contained" sx={{width: 'auto', backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                                    component={Link} to='/update-config-eid'>
                                    Edit
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{margin: 'auto'}} >
                                <Grid container sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                        
                                    <Grid item xs={12} sm={12} sx={{textAlign: 'start !important', pt: 4}}>
                                        {eid_config_information.map((agency) => {
                                            return (
                                                <Grid container spacing={2} sx={{marginBottom: 2, alignItems:'center'}}>
                                                    <Grid item xs={12} sm={4} >
                                                        <Typography sx={{fontWeight: 600}}>
                                                            {agency.label}:
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={8}>
                                                        <TextField 
                                                            multiline={agency.label == 'Description' ? true : false}  
                                                            value={agency.value}  
                                                            placeholder="N/A" 
                                                            sx={{
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                                },
                                                                fontSize: 16,
                                                                width: "100%", 
                                                                textAlign: "justify"
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                disableUnderline: true,
                                                                style: {fontSize: 14, padding: 1    }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid> 
                                            )
                                        })}
                                        </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>  
            }

        </>
        
    )
}

export default ViewConfigEIDComponent