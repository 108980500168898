import React, {useState, useRef} from "react";
import {Box, Button, Grid, InputBase, Typography} from "@mui/material";
import SignaturePad from "react-signature-canvas";

const CreateSignatureByDrawComponent = ({props}) => {

  const {breakpoints, setOpenSignatureDialog, setSignatureFile, setOpenSigEditor} = props;

  const signaturePadRef = useRef(null);
  const [isCanvasEmpty, setCanvasEmpty] = useState(true);

  const handleCanvasChange = () => {
    setCanvasEmpty(signaturePadRef.current.isEmpty());
  }
 
  const handleClearSignature = () => {
    signaturePadRef.current.clear();
    setCanvasEmpty(true);
  }

  const handleUploadSignature = () => {
    let signaturePad = signaturePadRef.current; 

    if (signaturePad.isEmpty()) {
      // do nothing
      
    } else {
      const signatureDataUrl = signaturePad.toDataURL();
      
      setSignatureFile(signatureDataUrl);
      setOpenSigEditor(true);
      setOpenSignatureDialog(false);
    }
  }

 
  return (
    <>
      <Box sx={{padding: (!isCanvasEmpty && (breakpoints == 'xs' || breakpoints == 'sm')) ? "0px 0px 16px 0px" 
        : (!isCanvasEmpty && (breakpoints == 'md' || breakpoints == 'lg' ||  breakpoints == 'xl')) ? "0px 40px 16px 40px" 
        : (isCanvasEmpty && (breakpoints == 'xs' || breakpoints == 'sm')) ? "23px 0px 16px 0px" : "23px 40px 16px 40px", 
        mt: (!isCanvasEmpty) ? "-14px" : {}}}
      >
        {(!isCanvasEmpty) &&
          <Grid sx={{textAlign: "end"}}>
            <Button sx={{color: "#3333cf", cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}
              onClick={() => {handleClearSignature()}}
            >
              Clear
            </Button>
          </Grid>
        }

        <Box>
          <SignaturePad ref={signaturePadRef} clearOnResize={false} onEnd={handleCanvasChange}
            canvasProps={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? 260 : 480, height: 200}}
          />
        </Box>

        <div style={{borderBottom: "1px solid #d3d3d3", marginTop: "-45px"}}>
          <InputBase disabled sx={{color: "#b9b9b9", mb: 1, ml: 1, width: "100%"}} />
        </div>

        <Typography sx={{fontSize: "14px", color: "#b9b9b9", textAlign: "center", mt: "10px", letterSpacing: "0.6px"}}>
          Draw Signature
        </Typography>

        <Box sx={{paddingTop: 3.2, textAlign: "end"}}>
          <Button onClick={(e) => setOpenSignatureDialog(false)} variant="outlined" 
            sx={{borderColor: "#3333cf", color: "#3333cf", width: 120, borderRadius: 10, textTransform: "none"}}
          >
            Cancel
          </Button>

          &nbsp; &nbsp;
            
          <Button variant="contained" sx={{width: 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none", textTransform: "none", '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
            onClick={() => handleUploadSignature()}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default CreateSignatureByDrawComponent