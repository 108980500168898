import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from '@mui/material';
import RecordsTabComponent from '../components/records/record-tab.component';
import useWidth from '../hooks/useWidth';
import useAgency from "../hooks/useAgency";
import SetupAgencyDialog from "../components/dialogs/setup-agency.dialog";
import { clearLocalStorage } from "../utils/clearLocalStorageUtil";

const EIDRecordListScreen = () => {

    const url = window.location.pathname;

    const breakpoints = useWidth();
    const { agencyInformation, retrieveAgencyInformation } = useAgency();

    const [openSetupAgency, setOpenSetupAgency] = useState(false);
    const [isNoAvailableAgency, setNoAvailableAgency] = useState(false);

    useEffect(() => {
        clearLocalStorage();

        retrieveAgencyInformation();

        if (agencyInformation) {
            let agencyInfoState = isEmptyObject(agencyInformation);

            if (agencyInfoState == true) {
                setNoAvailableAgency(true);
            }
        }

    }, [JSON.stringify(agencyInformation)])

    const isEmptyObject = (data) => {
        if (typeof data !== 'object' || data === null) {
            return false;
        }

        return Object.keys(data).length === 0;
    }

    function Header (props) {
        const {isNoAvailableAgency, setOpenSetupAgency} = props;

        return (
            <Box sx={{ padding:"10px 20px 0px 20px", width: "100%", borderRadius: 0}}>
                <Grid container sx={{display:'flex', alignItems:'center',}}>
                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{fontWeight: "bold", textAlign: "start"}}>
                           Next|ix eID Records
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign:'end'}}>
                        <Button variant="contained" sx={{backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                            onClick={() => (isNoAvailableAgency) ? setOpenSetupAgency(true) : window.location.href="/create-record"}
                        >
                            CREATE eID
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    return (
        <>
            <Box sx={{ overflow: "hidden", width: '100%', margin: 'auto' }}>
                <Header isNoAvailableAgency={isNoAvailableAgency} setOpenSetupAgency={setOpenSetupAgency} />

                <Box style={{ margin: 'auto' }}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={12} sm={12} sx={{ pt: 0 }}>
                            <RecordsTabComponent breakpoints={breakpoints} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {openSetupAgency &&
                <SetupAgencyDialog url={url} breakpoints={breakpoints}
                    openSetupAgency={openSetupAgency} setOpenSetupAgency={setOpenSetupAgency}
                />
            }
        </>
    )
}

export default EIDRecordListScreen