import {useState} from "react";
import EIDService from "../services/api/eID";
import {useSnackbar} from "notistack";

const useCSV = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isUploadingCSV, setUploadingCSV] = useState(false);
    const [eidDetails, setEidDetails] = useState(null);
    const [isUpdatingEID, setUpdatingEID] = useState(false);
    const [isFetchingDetails, setFetchingDetails] = useState(true);

    const uploadCSV = (file) => {
        setUploadingCSV(true);

        EIDService.uploadCSV(file).then((res) => {
            console.log("uploadCSV data xxxx", res);

            let saved_csvrecords = res.data.data.saved;
            let stringifyCSVRecords = JSON.stringify(saved_csvrecords);

            let error_csvrecords = res.data.data.error;
            let stringifyCSVErrorRecords = JSON.stringify(error_csvrecords);

            if (res.data.success) {
                setUploadingCSV(false);

                localStorage.setItem("savedCSVRecords", stringifyCSVRecords);
                localStorage.setItem("savedCSVErrorRecords", stringifyCSVErrorRecords);

                enqueueSnackbar("Successfully uploaded csv", { 
                    variant: "success",
                    autoHideDuration: 3000
                });

                window.location.href="/csv-records";  
            }

        }).catch((error) => { 
            console.log("uploadCSV error xxxx", error);

            let invalid_csv_format = "Internal Server Error";

            if (error.response.statusText == invalid_csv_format) {
                enqueueSnackbar("Invalid CSV Format", { 
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else {
                enqueueSnackbar(error.response.data.error_message, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

            setUploadingCSV(false);
        })
    }

    const vieweIDDetails = (uuid) => {
        setFetchingDetails(true);

        EIDService.vieweIDDetails(uuid).then((res) => {
            console.log("vieweIDDetails data xxxx", res);

            let eid_details = res.data.data;

            if (res.data.success) {  
                setEidDetails(eid_details);
                setFetchingDetails(false);
            }

        }).catch((error) => { 
            console.log("vieweIDDetails error xxxx", error);
            setFetchingDetails(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const updateEIDDetails = (uuid, idNo, firstName, middleName, lastName, suffix, emailAddress, formattedDob, pob, address, gender, bloodType, maritalStatus, position, contact_person, contact_person_no, photoFile, signatureFile) => {
        setUpdatingEID(true);

        EIDService.updateEIDDetails(uuid, idNo, firstName, middleName, lastName, suffix, emailAddress, formattedDob, pob, address, gender, bloodType, maritalStatus, position, contact_person, contact_person_no, photoFile, signatureFile).then((res) => {
            console.log("updateEIDDetails data xxxx", res);
            
            if (res.data.success) { 
                setUpdatingEID(false);
                enqueueSnackbar("Successfully updated eID details", { 
                    variant: "success",
                    autoHideDuration: 3000
                });

                window.location.href = "/view-record?id=" + uuid;
            }

        }).catch((error) => { 
            console.log("updateEIDDetails error xxxx", error);
            setUpdatingEID(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    return {isUploadingCSV, isUpdatingEID, isFetchingDetails, eidDetails, uploadCSV, vieweIDDetails, updateEIDDetails}

}

export default useCSV