import {Dialog, DialogContent, Typography } from "@mui/material";
import QrCode2Icon from '@mui/icons-material/QrCode2';

const PreRegistrationSuccessConfirmationDialog = (props) => {

    const {breakpoints, openSuccessConfirmation, setOpenSuccessConfirmation, data} = props;

    const handleCloseDialog = () => {
        setOpenSuccessConfirmation(false);
        window.location.href = "/";
    }


    return(
        <Dialog open={openSuccessConfirmation} onClose={(e) => handleCloseDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogContent sx={{textAlign:'center', padding: "30px 30px 8px"}}>
                <Typography variant="h4" sx={{color:'#3333cf', fontWeight: 'bold', fontSize: 42}}>
                    THANK YOU
                </Typography>

                <br/>

                <Typography sx={{fontSize: 16, fontWeight: 'bold'}}>
                    Please proceed to eID Admin for Biometric capturing
                </Typography>

                <br/>

                <img alt="" src={data?.qrcode} style={{height: 180, width: 180}} />

                <Typography  sx={{fontSize: 14, padding: 1}}>
                    {data?.reference_no}
                </Typography>

                <br />

                <Typography  sx={{fontSize: 14, padding: 1}}>
                    {data?.message}
                </Typography>
            </DialogContent>
        </Dialog>
    )
}

export default PreRegistrationSuccessConfirmationDialog