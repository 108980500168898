import {useEffect, useState } from "react";
import {Box, Grid, MenuItem, TextField, Select, FormControl, Typography, Button} from "@mui/material";
import useWidth from "../../hooks/useWidth";
import {useSnackbar} from "notistack";
import PreRegistrationSuccessConfirmationDialog from "../dialogs/pre-reg-success-dialog";
import usePreRegister from "../../hooks/usePreRegister";
import {ThreeDots, RotatingLines} from 'react-loader-spinner';
import ExpiredAccessKeyDialog from "../dialogs/expired-access-key.dialog";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const blood_types = [
    {name: 'A+'},
    {name: 'A-'},
    {name: 'B+'},
    {name: 'B-'},
    {name: 'AB+'},
    {name: 'AB-'},
    {name: 'O+'},
    {name: 'O-'}
]

const marital_status = [
    {name: 'SINGLE'},
    {name: 'MARRIED'},
    {name: 'DIVORCED'},
    {name: 'SEPARATED'},
    {name: 'WIDOWED'},
]

const gender_options = [
    {name: 'MALE'},
    {name: 'FEMALE'}
]

const PreRegistrationFormComponent = () => {

    const breakpoints = useWidth();
    const {enqueueSnackbar} = useSnackbar();
    const {isValidatingAccessKey, isPreRegistering, isValidAccessKey, validateAccessKey, preRegisterEID, data} = usePreRegister();
    
    const [emailAddress, setEmailAddress] = useState("");
    const [idNo, setIdNo] = useState("");
    const [suffix, setSuffix] = useState("");
    const [gender, setGender] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [bloodType, setBloodType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [pob, setPob] = useState("");
    const [position, setPosition] = useState("");
    const [contact_person, setContactPerson] = useState("");
    const [contact_person_no, setContactPersonNo] = useState("");
    const [openSuccessConfirmation, setOpenSuccessConfirmation] = useState(false);
    const [accessKey, setAccessKey] = useState(null);
    const [openExpiredAccessKeyDialog, setOpenExpiredAccessKeyDialog] = useState(false);
    const [isValidEmail, setValidEmail] = useState(true);


    useEffect(() => {
        const apiAccessKey = (new URLSearchParams(window.location.search)).get("access_key");

        if (apiAccessKey) {
            setAccessKey(apiAccessKey);
            validateAccessKey(apiAccessKey, setOpenExpiredAccessKeyDialog);

        } else {
            window.location.href = "/";
        }
    }, [])

    
    const handleChangeGender = (e) => {
        setGender(e.target.value);
    }

    const handleChangeBloodType = (e) => {
        setBloodType(e.target.value);
    }

    const handleChangeMaritalStatus = (e) => {
        setMaritalStatus(e.target.value);
    }

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/
        return re.test(email);
    }

    const handleTextField = (e, name) => {
        if (name == "emailAddress") {
            let email = e.target.value;

            setEmailAddress(email);

            if (email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }

        } else if (name == "idNo") {
            setIdNo(e.target.value);

        }  else if (name == "firstName") {
            const selectedfirstName = e.target.value;
            setFirstName(selectedfirstName.toUpperCase());

        }  else if (name == "middleName") {
            const selectedmiddleName = e.target.value;
            setMiddleName(selectedmiddleName.toUpperCase());

        } else if (name == "suffix") {
            const selectedsuffix = e.target.value;
            setSuffix(selectedsuffix.toUpperCase());

        } else if (name == "lastName") {
            const selectedlastName = e.target.value;
            setLastName(selectedlastName.toUpperCase());

        } else if (name == "address") {
            const selectedaddress = e.target.value;
            setAddress(selectedaddress.toUpperCase());

        } else if (name == "pob") {
            const selectedpob = e.target.value;
            setPob(selectedpob.toUpperCase());

        } else if (name == "position") {
            const selectedposition = e.target.value;
            setPosition(selectedposition.toUpperCase());

        } else if (name == "contactPerson") {
            const selectedcontactpeerson = e.target.value;
            setContactPerson(selectedcontactpeerson.toUpperCase());

        } else if (name == "contactPersonNo") {
            const selectedcontactpeersonno = e.target.value;
            setContactPersonNo(selectedcontactpeersonno);

        }
    }


    const formattedDob = dob ? dayjs(dob).format('YYYY-MM-DD') : '';

    const handlePreRegisterEID = (e) => {
        if (emailAddress && idNo && firstName && lastName && dob && pob && address && gender && maritalStatus && bloodType && position && contact_person && contact_person_no && isValidEmail) {
            let payload = {
                email_address: emailAddress,
                id_no: idNo,
                firstname: firstName,
                lastname: lastName,
                middlename: middleName,
                suffix: suffix,
                dob: formattedDob,
                address: address,
                gender: gender,
                blood_type: bloodType,
                marital_status: maritalStatus,
                pob: pob,
                position: position,
                contact_person: contact_person,
                contact_person_no: contact_person_no
            }
            
            preRegisterEID(payload, accessKey, setOpenSuccessConfirmation);

        } else if (!emailAddress) {
            enqueueSnackbar("Email Address is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!idNo) {
            enqueueSnackbar("ID No is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        }  else if (!position) {
            enqueueSnackbar("Position is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!firstName) {
            enqueueSnackbar("First Name is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!lastName) {
            enqueueSnackbar("Last Name is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!dob) {
            enqueueSnackbar("Date of Birth is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!pob) {
            enqueueSnackbar("Place of Birth is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!address) {
            enqueueSnackbar("Address is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!gender) {
            enqueueSnackbar("Gender is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!maritalStatus) {
            enqueueSnackbar("Marital Status is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!bloodType) {
            enqueueSnackbar("Blood Type is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        }  else if (!contact_person) {
            enqueueSnackbar("Contact Person is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        }  else if (!contact_person_no) {
            enqueueSnackbar("Contact Person No is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!isValidEmail) {
            enqueueSnackbar("Invalid email address format", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }

    }

    const handleCloseDialog = () => {
        setOpenExpiredAccessKeyDialog(false);
    }

    function PageLoader () {
        return (
            <div 
                style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minWidth: "100% !important",
                maxWidth: "100% !important",
                borderRadius: "8px",
                cursor: "default",
                flexGrow: 0,
                height: (breakpoints == "xs" || breakpoints == "sm") ? "75vh" : "80vh",
                justifyContent: "center",
                margin: "auto",
                transition: "border-color .15s linear"
                }}
            >
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "65"}
                    visible={true}
                />
            </div>
        )
    }


    if (isValidatingAccessKey) return <PageLoader />

   
    return (
        <>
            {(!isValidAccessKey && openExpiredAccessKeyDialog) ? 
                <> 
                    <ExpiredAccessKeyDialog breakpoints={breakpoints} handleCloseDialog={handleCloseDialog}
                        openExpiredAccessKeyDialog={openExpiredAccessKeyDialog} setOpenExpiredAccessKeyDialog={setOpenExpiredAccessKeyDialog}
                    />
                </>
            
            :
                (isValidAccessKey) ?
                    <Box>
                        <Typography variant="h6" sx={{textAlign: "center", fontWeight: "bold", mb: "14px", mt: "-12px"}}>
                            Next|IX Electronic ID
                        </Typography>

                        <Box sx={{border: "1px solid #3333cf", borderRadius: 2, width: (breakpoints == "xs" || breakpoints == "sm") ? "90%" : "70%", margin: "auto"}}>
                            <form noValidate autoComplete="off">
                                <Grid container sx={{ margin: 'auto', padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} direction="row">
                                            <Grid item xs={12} sm={12}>
                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Email Address<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField 
                                                            placeholder="Email Address"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            type="email"
                                                            defaultValue={emailAddress}
                                                            onChange={(e) => handleTextField(e, "emailAddress")}
                                                            inputProps={{style: {fontSize: 16}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                            helperText={
                                                                <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -10px"}}>
                                                                    {(!isValidEmail) ? "Email Address is invalid" : ""}
                                                                </p>
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>ID No<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField 
                                                            placeholder="ID No"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            defaultValue={idNo}
                                                            onChange={(e) => handleTextField(e, "idNo")}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                            inputProps={{style: {fontSize: 16}}}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Position<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField 
                                                            placeholder="Position"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            defaultValue={position}
                                                            onChange={(e) => handleTextField(e, "position")}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>First Name<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField 
                                                            placeholder="First Name"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            defaultValue={firstName}
                                                            onChange={(e) => handleTextField(e, "firstName")}
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Last Name<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            placeholder="Last Name"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            defaultValue={lastName}
                                                            onChange={(e) => handleTextField(e, "lastName")}
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Middle Name :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            placeholder="Middle Name"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            defaultValue={middleName}
                                                            onChange={(e) => handleTextField(e, "middleName")}
                                                            required
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                            <Typography sx={{fontWeight: 'bold'}}>Suffix :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            placeholder="Suffix"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            defaultValue={suffix}
                                                            onChange={(e) => handleTextField(e, "suffix")}
                                                            required
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Date of Birth<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                                <DatePicker
                                                                    value={dob}
                                                                    required
                                                                    views={['year', 'month', 'day']}
                                                                    inputProps={{style: {fontSize: 16}}}
                                                                    sx={{fontSize: 16,
                                                                        "& .MuiInputLabel-root": {color: '#1f4380'},
                                                                        "& .MuiOutlinedInput-root": {
                                                                            "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                        }
                                                                    }}
                                                                    onChange={(newValue) => {
                                                                        setDob(newValue);
                                                                    }}
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Place of Birth<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            placeholder="Place of Birth"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            defaultValue={pob}
                                                            onChange={(e) => handleTextField(e, "pob")}
                                                            required
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid> 

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Address<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            placeholder="Address"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            defaultValue={address}
                                                            onChange={(e) => handleTextField(e, "address")}
                                                            required
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                    
                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Gender<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={gender}
                                                                onChange={(e)=>handleChangeGender(e)}
                                                                inputProps={{
                                                                    style: {textTransform: 'uppercase', fontSize: 16},
                                                                    autoCapitalize: 'characters' // capitalizes all characters in input
                                                                }}
                                                                sx={{fontSize: 16,
                                                                    "& .MuiOutlinedInput-notchedOutline" : {borderColor: "#3333cf4f !important"},
                                                                }}
                                                                displayEmpty
                                                                renderValue={(gender) => {
                                                                    if (gender.length === 0) {
                                                                        return <span style={{color: "rgb(0 0 0 / 27%)", textTransform: 'uppercase'}}>
                                                                            Gender
                                                                        </span>;
                                                                        
                                                                    } else {
                                                                        return gender;
                                                                    }
                                                                }} 
                                                            >
                                                                {gender_options.map((genderx)=>{
                                                                    return (
                                                                        <MenuItem value={genderx.name} sx={{textTransform: 'uppercase'}}>
                                                                            {genderx.name}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Blood Type<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={bloodType}
                                                                onChange={(e)=>handleChangeBloodType(e)}
                                                                inputProps={{
                                                                    style: {textTransform: 'uppercase',color: '#1f4380', fontSize: 16},
                                                                    autoCapitalize: 'characters' 
                                                                }}
                                                                sx={{fontSize: 16,
                                                                    "& .MuiOutlinedInput-notchedOutline" : {borderColor: "#3333cf4f !important"}, 
                                                                }}
                                                                displayEmpty
                                                                renderValue={(bloodType) => {
                                                                    if (bloodType.length === 0) {
                                                                        return <span style={{color: "rgb(0 0 0 / 27%)", textTransform: 'uppercase'}}>
                                                                            Blood Type
                                                                        </span>;
                                                                        
                                                                    } else {
                                                                        return bloodType;
                                                                    }
                                                                }}
                                                            >
                                                                {blood_types.map((blood)=>{
                                                                    return (
                                                                        <MenuItem value={blood.name} sx={{textTransform: 'uppercase'}}>
                                                                            {blood.name}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Marital Status<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={maritalStatus}
                                                                onChange={(e)=>handleChangeMaritalStatus(e)}
                                                                inputProps={{
                                                                    style: {textTransform: 'uppercase', fontSize: 16},
                                                                    autoCapitalize: 'characters' // capitalizes all characters in input
                                                                }}
                                                                sx={{fontSize: 16,
                                                                    "& .MuiOutlinedInput-notchedOutline" : {borderColor: "#3333cf4f !important"},
                                                                }}
                                                                displayEmpty
                                                                renderValue={(maritalStatus) => {
                                                                    if (maritalStatus.length === 0) {
                                                                        return <span style={{color: "rgb(0 0 0 / 27%)", textTransform: 'uppercase'}}>
                                                                            Marital Status
                                                                        </span>;

                                                                    } else {
                                                                        return maritalStatus;
                                                                    }
                                                                }}
                                                            >
                                                                {marital_status.map((status)=>{
                                                                    return (
                                                                        <MenuItem value={status.name} sx={{textTransform: 'uppercase'}}>
                                                                            {status.name}
                                                                        </MenuItem>
                                                                    )
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Contact Person<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12} >
                                                        <TextField 
                                                            placeholder="Contact Person"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            defaultValue={contact_person}
                                                            onChange={(e) => handleTextField(e, "contactPerson")}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                        
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid container sx={{pt: 1}}>
                                                    <Grid item xs={12} sm={12} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                        <Typography sx={{fontWeight: 'bold'}}>Contact Perso No<b style={{color: 'red'}}>*</b> :</Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={12}>
                                                        <TextField 
                                                            placeholder="Contact Person No"
                                                            fullWidth 
                                                            variant="outlined" 
                                                            multiline 
                                                            autoComplete="off"
                                                            required
                                                            defaultValue={contact_person_no}
                                                            onChange={(e) => handleTextField(e, "contactPersonNo")}
                                                            InputLabelProps={{style: {color: '#1f4380'}}}
                                                            sx={{fontSize: 16,
                                                                "& .MuiOutlinedInput-root": {
                                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                                }
                                                            }}
                                                            inputProps={{
                                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                                            }}
                                                        
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>   
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{mt: "10px", mb: "15px", justifyContent: 'flex-end', textAlign: 'end', pb: 3, p: 1, pr: (breakpoints == "xs" || breakpoints == "sm") ? {} : "14px"}}>
                                    <Button variant="contained" 
                                        sx={{width: (isPreRegistering) ? {} : 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none', fontWeigth: 550,
                                            '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                                        }}
                                        startIcon={isPreRegistering && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                                        onClick={(e) => handlePreRegisterEID(e)}
                                    >
                                        {(isPreRegistering) ? "Submitting" : "Submit"}
                                    </Button>
                                </Grid>
                            </form>
                        </Box>
                    </Box>

            :
                null
            }

            {openSuccessConfirmation &&
                <PreRegistrationSuccessConfirmationDialog breakpoints={breakpoints} data={data}
                    openSuccessConfirmation={openSuccessConfirmation} setOpenSuccessConfirmation={setOpenSuccessConfirmation}
                />
            }
        </>
    )
}

export default PreRegistrationFormComponent