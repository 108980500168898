import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import useWidth from "../hooks/useWidth";
import useAgency from "../hooks/useAgency";
import UploadCSVComponent from "../components/csv/upload-csv.component";
import SetupAgencyDialog from "../components/dialogs/setup-agency.dialog";
import {clearLocalStorage} from "../utils/clearLocalStorageUtil";

function Header () {
    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                       Upload CSV
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const UploadCSVScreen = () => {

    const url = window.location.pathname;

    const breakpoints = useWidth();
    const {agencyInformation, retrieveAgencyInformation} = useAgency();

    const [openSetupAgency, setOpenSetupAgency] = useState(false);
    const [isNoAvailableAgency, setNoAvailableAgency] = useState(false);

    useEffect(() => {
        clearLocalStorage();
        retrieveAgencyInformation();

        if (agencyInformation) {
            let agencyInfoState = isEmptyObject(agencyInformation);

            if (agencyInfoState == true) {
                setOpenSetupAgency(true);
                setNoAvailableAgency(true);
            }
        }

    }, [JSON.stringify(agencyInformation)])

    const isEmptyObject = (data) => {
        if (typeof data !== 'object' || data === null) {
            return false; 
        }
      
        return Object.keys(data).length === 0;
    }

    
    return (
        <>
            <Box sx={{overflow: "hidden", width: breakpoints == 'xs' || breakpoints == 's' ? "100%" : "95%", margin: "auto"}}>
                <Header />

                <Box style={{flexGrow: 1, margin: "auto"}}>
                    <Grid container spacing={2} direction="row" sx={{padding: 2}}>
                        <Grid item xs={12} sm={12} sx={{pt: 0}}>
                            <UploadCSVComponent breakpoints={breakpoints} isNoAvailableAgency={isNoAvailableAgency} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {openSetupAgency &&
                <SetupAgencyDialog url={url} breakpoints={breakpoints}
                    openSetupAgency={openSetupAgency} setOpenSetupAgency={setOpenSetupAgency}
                />
            }
        </>
    )
}

export default UploadCSVScreen