import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = () => {

    const navigate = useNavigate();

    return (
        <Button style={{color: "black", fontSize: 16, textTransform: "none", fontWeight: 600}}
            startIcon={
                <ArrowBackIosIcon style={{width: 20, height: 20, color: "#3333cf"}} />
            } 
            onClick={() => navigate(-1)}
        >
            Back
        </Button>
    )
}

export default BackButton