import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost,  HttpPut, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_service.subscriptionKey;

const retrieveSignatories = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_SIGNATORIES, query_params, subscriptionKey);

    return result;

}

const deleteSignatory = (document_id) => {

    let query_params = {
        document_id: document_id
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpDelete(routes.DELETE_SIGNATORY, query_params, subscriptionKey);

    return result;

}


const setAsDefaultSignatory = (payload) => {

    let result = HttpPut(routes.SET_AS_DEFAULT_SIGNATORY, payload);

    return result;

}

const addSignatory = (formData) => {

    let payload = formData;

    console.log("addSignatory payload xxxx", payload);

    let result = HttpPost(routes.ADD_SIGNATORY, formData);

    return result;
}


const updateSignatory = (formData) => {

    let payload = formData;

    console.log("updateSignatory payload xxxx", payload);

    let result = HttpPut(routes.UPDATE_SIGNATORY, formData);

    return result;
}



const EIDSignatoriesService = {
    retrieveSignatories,
    deleteSignatory,
    setAsDefaultSignatory,
    addSignatory: addSignatory,
    updateSignatory: updateSignatory
}

export default EIDSignatoriesService;