import React from "react";
import {Skeleton, Grid, Box, Card, CardContent} from "@mui/material";

const SelectSignatorySkeletonComponent = (props) => {

    const {breakpoints, index} = props;

    return (
        <>
            <Card key={index} sx={{borderRadius: 2, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", height: "auto"}}
            >
                <CardContent sx={{textAlign: "center", padding: 0}}>
                    <Grid container sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? "1px !important" : {}, display: "flex", mt: "16px !important", margin: "auto"}}>
                        <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 15 : 25} width="100%" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                    </Grid>

                    <Box sx={{mt: 1}}>
                        <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 150 : 200} 
                            width="100%" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} 
                        />
                    </Box>

                    <Box sx={{mt: 1}}>
                        <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 15 : 25} width="100%" style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default SelectSignatorySkeletonComponent