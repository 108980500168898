import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost, HttpPut} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_service.subscriptionKey;

 
const retrieveAgencyInformtaion = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_AGENCY_INFORMATION, query_params, subscriptionKey);

    return result;

}

const updateAgencyInformation = (payload) => {

    console.log("update user information payload :", payload);

    let result = HttpPut(routes.UPDATE_AGENCY_INFORMATION, payload);

    return result;
}

const createAgency = (formData) => {

    let payload = formData;

    console.log("createAgency payload xxxx", payload);

    let result = HttpPost(routes.CREATE_AGENCY_INFORMATION, payload);

    return result;
}


const EIDAgencyService = {
    retrieveAgencyInformtaion,
    updateAgencyInformation,
    createAgency: createAgency
}

export default EIDAgencyService;