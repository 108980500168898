import React, {useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import CSVRecordListComponent from '../components/csv/csv-record-list.component';
import SendAllAppBindingDialog from '../components/dialogs/send-all-app-binding-dialog';
import useWidth from "../hooks/useWidth";
import ErrorResponseListScreen from './ErrorResponseListScreen';

function Header (props) {
    const {breakpoints, csvList} = props;
    const [openSendAllAppBinding, setOpenSendAllAppBinding] = useState(false);
    

    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={6}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                       CSV Records
                    </Typography>
                </Grid>
                <Grid item xs={6} sx={{textAlign: 'end', ml : -4}}>
                    <Button variant="contained" 
                        sx={{
                            width: 180, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none",
                            '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                        }}
                        onClick={(e) => (csvList.length > 0) ? setOpenSendAllAppBinding(true) : null}
                    >
                        Send App Binding
                    </Button>
                </Grid>
            </Grid>

            {openSendAllAppBinding &&
                <SendAllAppBindingDialog csvList={csvList} openSendAllAppBinding={openSendAllAppBinding} 
                    setOpenSendAllAppBinding={setOpenSendAllAppBinding} breakpoints={breakpoints}
                />
            }

        </Box>
    )
}


const RecordsScreen = () => {

    const {breakpoints} = useWidth();

    const [csvList, setCSVList] = useState([]);
    const [csvErrorList, setCSVErrorList] = useState([]);

    useEffect(() => {
        const storedCSVRecords = localStorage.getItem("savedCSVRecords");
        const storedArray = JSON.parse(storedCSVRecords);

        setCSVList(storedArray);
    }, [])

    useEffect(() => {
        const storedCSVErrorRecords = localStorage.getItem("savedCSVErrorRecords");
        const storedArray = JSON.parse(storedCSVErrorRecords);

        setCSVErrorList(storedArray);
    }, [])


    return (
        <Box sx={{overflow: "hidden", width: breakpoints == 'xs' || breakpoints == 's' ? "100%" :'95%', margin:'auto'}}>

            <Header breakpoints={breakpoints} csvList={csvList} />

            <Box style={{flexGrow: 1, margin:'auto'}}>
                <Grid container spacing={2} direction="row" sx={{padding: 2}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        <CSVRecordListComponent breakpoints={breakpoints} recordList={csvList} />
                    </Grid>

                    {csvErrorList?.length > 0 &&
                        <Grid item xs={12} sm={12} sx={{mt: 5}}>
                            <ErrorResponseListScreen breakpoints={breakpoints} 
                                csvErrorList={csvErrorList} />
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default RecordsScreen