import { Grid, Button, Typography } from "@mui/material"
import DrawerHeader from "../../layout/drawer-header"
import AuthService from "../../services/auth";
import nextixLogo from "../../icons/nextix.png"
import ProfileService from "../../services/api/user";
import { useEffect, useState } from "react";

const BannerComponent = (props) => {

    const [status, setStatus] = useState(null);


    const { breakpoints } = props;

    // const login = () => {
    //     AuthService.login().then((res) => {

    //         console.log("res here", res)

    //         if(res){

    //             ProfileService.viewUser().then((res) => {
    //                 if(res.data.success){
    //                     window.location.href = "/upload-csv";
    //                 }
    
    //             }).catch((error) => {
    //                 window.location.href = "/forbidden";
    //             })
    //         }

           
    //     }).catch((error) => {
    //         console.log("login error xxxx", error);
    //     })
    // }


    const login = () => {
        AuthService.login().then((res) => {
            if(res){
                viewProfile();
            }
        }).catch((error) => {
            console.log("login error xxxx", error);
        })
    }

    const viewProfile = () => {
        ProfileService.viewUser().then((res)=>{
            console.log('get profile res', res) 
            if(res.data.success){
                window.location.href="/upload-csv"; 
            }   
        }).catch((error) => {
            console.log('get profile error', error)
            setStatus(error.response.status)
            window.location.href="/forbidden"; 
            
        })   
    }

//   useEffect(()=>{

//     if(status === 403){
    // window.location.href="/forbidden"; 
//     }else{
    // window.location.href="/upload-csv"; 
         
//     }
      
//   },[status])


    return (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}
            sx={{
                paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : {},
                paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? "20px" : {},
                alignSelf: "start", textAlign: "left", margin: 0, position: "absolute",
                top: "50%", transform: "translate(0, -50%)"
            }}
        >
            <img alt="" src={nextixLogo} style={{ height: breakpoints == 'xs' || breakpoints == 'sm' ? 80 : 90, paddingLeft: 0, pb: 0 }} />

            {(breakpoints == 'xs' || breakpoints == 'sm') ?
                null
                :
                <DrawerHeader />
            }

            <Typography sx={{
                textAlign: 'justify', maxWidth: (breakpoints == 'xs' || breakpoints == 'sm') ? '100%' : 400,
                color: '#000', textJustify: 'inter-word', whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word', ml: (breakpoints == 'xs' || breakpoints == 'sm') ? '0px' : 1,
            }}
            >
                Next|IX Electronic ID simplifies identity verification and grants
                secure access to a wide range of digital application features.
            </Typography>

            <DrawerHeader />

            <Button variant="contained" onClick={login}
                sx={{
                    ml: (breakpoints == 'xs' || breakpoints == 'sm') ? 0 : 1, width: 120,
                    backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none',
                    mb: (breakpoints == 'xs' || breakpoints == 'sm') ? 1 : 2,
                    ":hover": { backgroundColor: '#b5b5b5' }
                }}
            >
                Sign In
            </Button>
        </Grid>
    )
}

export default BannerComponent