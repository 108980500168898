import {useState} from "react";
import {useSnackbar} from "notistack";
import EIDConfigService from "../services/api/config";

const useConfig = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState("");
    const [updatingConfig, setUpdatingConfig] = useState(false);
    const [updatedConfig, setUpdatedConfig] = useState(false);

    const retrieveConfig = () => {
        setLoading(true);

        EIDConfigService.retrieveConfigEID().then((res) => {
            console.log("retrieveConfig data xxxx", res);

            setLoading(false);

            if (res.data.success) {
                setConfig(res.data.data);
            }

        }).catch((error) => {
            console.log("retrieveConfig error xxxx", error);
            setLoading(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    const updateConfig = (name, id_type, expiry_duration, expiry_unit) => {
        setUpdatingConfig(true);
            
        EIDConfigService.updateConfigEID(name, id_type, expiry_duration, expiry_unit).then((res) => {
    
            console.log("updateConfig data xxxx", res);
    
            setUpdatingConfig(false);
    
            if(res.data.success){
                setUpdatedConfig(true);
                enqueueSnackbar('Successfully updated config', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
    
        }).catch((error) => {
            console.log("updateConfig error xxxx", error);
            setUpdatingConfig(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    return {
        loading, retrieveConfig, config, updateConfig, updatingConfig, updatedConfig
    }
      
}

export default useConfig