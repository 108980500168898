import {useState} from "react";
import {useSnackbar} from "notistack";
import PreRegistrationService from "../services/api/pre-registration";

const usePreRegister = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isValidatingAccessKey, setValidatingAccessKey] = useState(true);
    const [isPreRegistering, setPreRegistering] = useState(false);
    const [isValidAccessKey, setValidAccessKey] = useState(false);
    const [data, setData] = useState("");


    const validateAccessKey = (apiAccessKey, setOpenExpiredAccessKeyDialog) => {
        setValidatingAccessKey(true); 

        PreRegistrationService.validateAccessKey(apiAccessKey).then((res) => {
            console.log("validateAccessKey data xxxx", res);

            if (res.data.success) {
                setValidatingAccessKey(false);
                setValidAccessKey(true);
            }

        }).catch((error) => {
            console.log("validateAccessKey error xxxx", error);

            setValidAccessKey(false);
            setValidatingAccessKey(false);
            setOpenExpiredAccessKeyDialog(true);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }
    
    const preRegisterEID = (payload, apiAccessKey, setOpenSuccessConfirmation) => {
        setPreRegistering(true); 

        PreRegistrationService.preRegisterEID(payload, apiAccessKey).then((res) => {
            console.log("preRegisterEID data xxxx", res);

            setPreRegistering(false);
            setOpenSuccessConfirmation(true);

            if (res.data.success) {
                setData(res.data.data);
            }


        }).catch((error) => {
            console.log("preRegisterEID error xxxx", error);

            let errorMsg = error.response.data.error_message;

            enqueueSnackbar(errorMsg, { 
                variant: "error",
                autoHideDuration: 3000
            });

            setPreRegistering(false);
        })
    }

    

    return {isValidatingAccessKey, isPreRegistering, isValidAccessKey, validateAccessKey, preRegisterEID, data}
}

export default usePreRegister