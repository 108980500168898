import React from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NextixLogo from "../../icons/nextix.png";

const SetupAgencyDialog = (props) => {

    const {url, breakpoints, openSetupAgency, setOpenSetupAgency} = props;

    const handleCloseConfirmationDialog = (e) => {
        setOpenSetupAgency(false);
        window.location.href = "/create-agency";
    }

    const CustomDialogTitle = (props) => {
        const {children, onClose, ...other} = props;

        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}

                {onClose ? 
                    (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{position: "absolute", right: 8, top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) 
                : 
                    null
                }
          </DialogTitle>
        )
    }

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openSetupAgency}
            onClose={(e) => handleCloseConfirmationDialog(e)}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseConfirmationDialog(e)}>
                <img alt="" src={NextixLogo} style={{width: 65, marginLeft: (breakpoints == "xs" || breakpoints == "sm") ? "12px" : "21px"}} /> 
            </CustomDialogTitle>

            <DialogContent dividers sx={{borderBottom: "none", pt: 0, borderTop: "none", mt: "-10px", padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px 28px 24px" : "10px 38px 24px"}}>
                {(url == "/upload-csv") ? 
                    "To upload CSV, agency is required. Kindly setup your agency." 
                :
                    (url == "/records") ?
                        "To create eID, agency is required. Kindly setup your agency."
                : 
                    null
                }
            </DialogContent>

            <DialogActions sx={{justifyContent: "end", padding: "0px 24px 18px"}}>
                <Button variant="contained"
                    sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? "60%" : "25%", backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, 
                        '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                    }} 
                    onClick={(e) => window.location.href = "/create-agency"}
                >
                    Setup Agency
                </Button>     
            </DialogActions>
        </Dialog> 
    )
}

export default SetupAgencyDialog