import { useState } from "react";
import { useSnackbar } from "notistack";
import EIDService from "../services/api/eID";
import {blobToBase64} from "../utils/blobToBase64Util";
import environment from "../environment/environment";

const useEID = () => {

  const { enqueueSnackbar } = useSnackbar();

  const url = window.location.pathname;

  const [loading, setLoading] = useState(false);
  const [eIDRecordList, seteIDRecordList] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [loadingRecordCreation, setLoadingRecordCreation] = useState(false);
  const [generatingEID, setGeneratingEID] = useState(false);
  const [sendingAppBinding, setSendingAppBinding] = useState(false);
  const [appBinding, setSendAppBinding] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [isDeleted, setDeleted] = useState(false);
  const [isUpdatingStatus, setUpdatingStatus] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [loadingApprovalEID, setLoadingApprovalEID] = useState(false)
  const [loadingDisapproveEID, setLoadingDisapproveEID] = useState(false)
  const [isDownloading, setDownloading] = useState(false);
  const [isDownloaded, setDownloaded] = useState(false);
  const [sending, setSending] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isOpeningPDFViewer, setOpeningPDFViewer] = useState(false);


  var limit = 10;


  const retrieveeIDRecordsList = (page) => {
    setLoading(true);

    EIDService.retrieveeIDRecordsList(page, limit).then((res) => {
      console.log("retrieveeIDRecordsList data xxxx", res);

      setLoading(false);

      if (res.data.success) {
        seteIDRecordList(res.data.data.records);

        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.data.total_data / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((error) => {
      console.log("retrieveeIDRecordsList error xxxx", error);
      setLoading(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })


  }


  const filterByStatus = (status, page) => {

    setLoading(true);

    EIDService.filterByStatus(status, page, limit).then((res) => {

      console.log("filterByStatus data xxxx", res);

      setLoading(false);

      if (res.data.success) {
        seteIDRecordList(res.data.data.records);

        if (res.data.data.length === 0) {
          setPagination(0)
        } else {
          var last_page = Math.ceil(res.data.data.total_data / limit); //set it depending on ui return - 18
          console.log("last_page", last_page);
          setPagination(last_page)
        }
      }

    }).catch((error) => {

      console.log("filterByStatus error xxxx", error);
      setLoading(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })

  }

  const createEIDRecord = (formData) => {
    setLoadingRecordCreation(true);

    EIDService.createEIDRecord(formData).then((res) => {
      console.log("createEIDRecord data xxxx", res);

      let uuid = res.data.data.uuid;

      if (res.data.success) {
        setLoadingRecordCreation(false);
        enqueueSnackbar("Successfully created eID record", {
          variant: 'success',
          autoHideDuration: 3000
        });

        window.location.href = "/view-record?id=" + uuid;
      }

    }).catch((error) => {
      console.log("createEIDRecord error xxxx", error);
      setLoadingRecordCreation(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }

  const generateEID = (uuid, signatory_id, setOpenSelectSignatory) => {

    setGeneratingEID(true);

    EIDService.generateEID(uuid, signatory_id).then((res) => {
      console.log("generate eID data xxxx", res);

      setGeneratingEID(false);

      if (res.data.success) {
        enqueueSnackbar("Successfully generated eID", {
          variant: 'success',
          autoHideDuration: 3000
        });

        setOpenSelectSignatory(false);
        window.location.reload();
      }

    }).catch((error) => {
      console.log("generate eID error xxxx", error);
      setGeneratingEID(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }



  const sendAppBinding = (uuid) => {

    setSendingAppBinding(true);

    EIDService.sendAppBinding(uuid).then((res) => {
      console.log("sendAppBinding data xxxx", res);

      setSendingAppBinding(false);

      if (res.data.success) {
        setSendAppBinding(true);
      }

    }).catch((error) => {
      console.log("sendAppBinding error xxxx", error);
      setSendingAppBinding(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }

  const sendAllAppBinding = (uuid, isLastUser, setOpenSendAllAppBinding) => {
      setSendingAppBinding(true);

      EIDService.sendAppBinding(uuid).then((res) => {
        console.log("sendAllAppBinding data xxxx", res);

        if (isLastUser) {
          setSendingAppBinding(false);
          enqueueSnackbar("Successfully send App Binding Instruction to all", { 
            variant: "success",
            autoHideDuration: 3000
          });

          setOpenSendAllAppBinding(false);
          window.location.href = "/records";
        }

      }).catch((error) => { 
        console.log("sendAllAppBinding error xxxx", error);
        setSendingAppBinding(false);

        enqueueSnackbar(error.response.data.error_message, { 
          variant: "error",
          autoHideDuration: 3000
        });

        setOpenSendAllAppBinding(false);
      })
    }



  const deleteEID = (uuid) => {

    setDeleting(true);

    EIDService.deleteEID(uuid).then((res) => {
      console.log("delete eID data xxxx", res);

      setDeleting(false);

      if (res.data.success) {
        enqueueSnackbar("Successfully deleted eID application", {
          variant: "success",
          autoHideDuration: 3000
        });
        setDeleted(true);
      }

    }).catch((error) => {
      console.log("delete eID error xxxx", error);
      setDeleting(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }

  const approveEID = (uuid, setOpenApproveApplication) => {
    setLoadingApprovalEID(true)

    EIDService.approveEID(uuid).then((res) => {
      console.log("approveEID data xxxx", res);

      if (res.data.success) {
        setLoadingApprovalEID(false);
        setOpenApproveApplication(false)
        window.location.reload();

        enqueueSnackbar("Successfully approved eID application", {
          variant: "success",
          autoHideDuration: 3000
        });
      }
    }).catch((error) => {
      console.log("approveEID error xxxx", error);
      setLoadingApprovalEID(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }

  const revokeSuspendApplication = (uuid, status) => {
    setUpdatingStatus(true);
    EIDService.revokeSuspendApplication(uuid, status).then((res) => {
      console.log("revokeSuspendApplication data xxxx", res);

      setUpdatingStatus(false);

      if (res.data.success) {
        enqueueSnackbar('Successfully updated status', {
          variant: 'success',
          autoHideDuration: 3000
        });
        setUpdated(true);
      }

    }).catch((error) => {
      console.log("revokeSuspendApplication error xxxx", error);
      setUpdatingStatus(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }

  const disapproveEID = (payload) => {
    setLoadingDisapproveEID(true);

    EIDService.disapproveEID(payload).then((res) => {
      console.log('disapprove EID data xxxx', res);

      setLoadingDisapproveEID(false)
      window.location.reload();
      enqueueSnackbar("Successfully rejected eID application", {
        variant: "success",
        autoHideDuration: 3000
      });

    }).catch((error) => {
      console.log('disapprove EID error xxxx', error);
      setLoadingDisapproveEID(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }


  const donwloadEID = (uuid, handleCloseDownload, filename) => {

    setDownloading(true);

    EIDService.donwloadEID(uuid).then((res) => {
      console.log("downloadEID data xxxx", res);

      setDownloading(false);

      let blob = res.data;
      let domain = environment.msal_url.url;
      let pdfUrl = domain + "/view/pdf?document=" + filename;

      if (blob) {
        blobToBase64(blob).then(res => {
          localStorage.setItem("pdfUrl", pdfUrl);
          localStorage.setItem("eidPDFBase64Data", res);
        });
      }


      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();

      handleCloseDownload();

    }).catch((error) => {
      console.log("download eID error xxxx", error);
      setDownloading(false);

      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }


  const sendToEWallet = (uuid) => {
    setSending(true);

    EIDService.sendEWallet(uuid).then((res) => {
      console.log("send eID to e wallet res", res);

      setSending(false);

      if (res.data.success) {
        enqueueSnackbar(res.data.data, {
          variant: "success",
          autoHideDuration: 3000
        });
      }

    }).catch((error) => {
      console.log("send eID to e wallet error", error);
      setSending(false);
      enqueueSnackbar(error.response.data.error_message, {
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }


  const retrieveActivityLogs = (uuid) => {

    setLoading(true);

    EIDService.retrieveActivityLogs(uuid).then((res) => {

      setLoading(false);

      if (res.data.success) {
        setLogs(res.data.data)
      }

    }).catch((error) => {
      setLoading(false);

      if (error.response.data.error_message) {
        enqueueSnackbar(error.response.data.error_message, {
          variant: "error",
          autoHideDuration: 3000
        });

      } else {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          autoHideDuration: 3000
        });
      }

    })
  }

  const viewPdfCopy = (uuid, pdfName) => {
    setOpeningPDFViewer(true);

    EIDService.donwloadEID(uuid).then((res) => {
      console.log("viewPdfCopy data xxxx", res);

      let blob = res.data;
      let domain = environment.msal_url.url;
      let pdfUrl = domain + "/view/pdf?document=" + pdfName;

      console.log("domain xxxx", domain);

      if (blob) {
        blobToBase64(blob).then(res => {
          localStorage.setItem("eidPDFBase64Data", res);
          localStorage.setItem("pdfUrl", pdfUrl);

          setOpeningPDFViewer(false);

          if (res) {
            window.open(pdfUrl, '_blank');
          }
        });
      }

    }).catch((error) => {
      console.log("viewPdfCopy error xxxx", error);
      setOpeningPDFViewer(false);

      enqueueSnackbar(error.response.data.error_message, { 
        variant: "error",
        autoHideDuration: 3000
      });
    })
  }




  return {
    loading, setLoading, pagination, eIDRecordList, retrieveeIDRecordsList, filterByStatus, seteIDRecordList, setPagination,
    createEIDRecord, loadingRecordCreation, generateEID, generatingEID, sendAppBinding, sendingAppBinding, appBinding,
    isDeleting, isDeleted, deleteEID, approveEID, loadingApprovalEID, disapproveEID, loadingDisapproveEID, revokeSuspendApplication,
    isUpdatingStatus, updated, donwloadEID, isDownloaded, isDownloading, sendToEWallet, sending, retrieveActivityLogs, logs,
    isOpeningPDFViewer, viewPdfCopy, sendAllAppBinding
  }

}

export default useEID