import { Grid } from "@mui/material"
import SignatoryListComponent from "./signatory-list.component"



const SignatoryComponent = (props) => {

    const { signatories } = props;

return(

    <Grid container spacing={2}>
        {signatories.map((value)=>{
            return(
                <Grid item xs={12} sm={6} md={4} >
                    <SignatoryListComponent signatory={value} />
                </Grid>
            )}
        )}
    </Grid>
)

}

export default SignatoryComponent