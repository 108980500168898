import { Box, Button, Grid, Typography } from "@mui/material"
import useWidth from "../hooks/useWidth";
import SignatoryComponent from "../components/signatory/signatory.component"
import useSignatories from "../hooks/useSignatories";
import { useEffect } from "react";
import SignatoryListSkeletonLoader from "../components/skeletons/signatorires-skeleton-loader.component";


const Header = (props) => {

    const { signatories } = props;

    return (
        <Box sx={{ padding:"10px 20px 0px 20px", width: "100%", borderRadius: 0}}>
                <Grid container sx={{display:'flex', alignItems:'center',}}>
                    <Grid item xs={6}>
                        <Typography variant="h6" sx={{fontWeight: "bold", textAlign: "start"}}>
                            Signatory List
                        </Typography>
                    </Grid>

                    {(signatories?.length === 0) ?
                        <Grid item xs={6} sx={{textAlign: 'end'}}>
                            <Button variant="contained" sx={{backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                                onClick={(e) => window.location.href="/create-signatory"}
                            >
                                CREATE SIGNATORY
                            </Button>
                        </Grid> 

                    :
                        (signatories?.length < 3 && signatories?.length !== 0) ?
                            <Grid item xs={6} sx={{textAlign: 'end'}}>
                                <Button variant="contained" sx={{backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                                    onClick={(e) => window.location.href="/create-signatory"}
                                >
                                    ADD SIGNATORY
                                </Button>
                            </Grid> 

                    :

                        null
                    }
                </Grid>
            </Box>
    )
}


const SignatoryScreen = () => {

    const breakpoints = useWidth();

    const { retrieveSignatories, signatories, loading } = useSignatories();

    useEffect(() => {
        retrieveSignatories();

        localStorage.removeItem("signatoryRecord");
    },[])


    return (

        <Box sx={{overflow: "hidden", position: 'relative', width: breakpoints == 'xs' || breakpoints == 's' ? "100%" :'95%', margin:'auto'}}>

        <Header signatories={signatories} />

        <Box style={{flexGrow: 1, margin:'auto'}}>
            <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                {loading ? (
                    <SignatoryListSkeletonLoader breakpoints={breakpoints} />
                ) : (
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        <SignatoryComponent  signatories={signatories}  />
                    </Grid>
                )}
            </Grid>
           
            {!loading && signatories?.length === 0 && (
                <Grid container spacing={2} sx={{ justifyContent: 'center', alignSelf: 'center' }}>
                    <Typography style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: 600, fontSize: "14px", marginTop: 50, marginBottom: 30, textAlign: 'center' }}>
                        No signatory available to display yet.
                    </Typography>
                </Grid>
            )}
        </Box>

    </Box>
    )
}

export default SignatoryScreen