import {useState} from "react";
import {useSnackbar} from "notistack";
import EIDPublicService from "../services/api/eID-public";

const useEIDPublic = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [isFetchingData, setFetchingData] = useState(false);
    const [record, setRecord] = useState("");
    const [isUpdatingRecord, setUpdatingRecord] = useState(false);
    const [updatedRecord, setUpdatedRecord] = useState(false);
    const [errorHere, setErrorHere] = useState(false);
    
    const retrieveApplicationRecord = (handleOpenSuccessConfirmation) => {
        setFetchingData(true);

        EIDPublicService.retrieveApplicationRecord().then((res) => {
            console.log("retrieveApplicationRecord data xxxx", res);

            if (res.data.success) {
                setRecord(res.data.data);
                setFetchingData(false);
            }

        }).catch((error) => {
            console.log("retrieveApplicationRecord error xxxx", error);
            setFetchingData(false);
            handleOpenSuccessConfirmation();

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const updateApplicationRecord = (formData, accessToken, setOpenSuccessConfirmation) => {
        setUpdatingRecord(true);
            
        EIDPublicService.updateApplicationRecord(formData, accessToken).then((res) => {
            console.log("updateApplicationRecord data xxxx", res);
    
            if (res.data.success) {
                setUpdatingRecord(false);
                setOpenSuccessConfirmation(true);
                setUpdatedRecord(true);
            }
    
        }).catch((error) => {
            console.log("updateApplicationRecord error xxxx", error);

            setErrorHere(true);
            setUpdatingRecord(false);
            
            enqueueSnackbar(error.response.data.error_message, { 
                variant: 'error',
                autoHideDuration: 3000
            });
        })
    }


    return {
        isFetchingData, retrieveApplicationRecord, record, isUpdatingRecord, updatedRecord, updateApplicationRecord, errorHere
    }
      
}

export default useEIDPublic