import {Box, Button} from "@mui/material"
import ovcodeLogo from "../icons/ovcodelogo.png"
import useWidth from "../hooks/useWidth";
import BannerComponent from "../components/banner/banner.component";

function LandingScreen () {

    const breakpoints = useWidth();

    return(

        <Box style={{width: "100vw", height: "100vh"}} class="content">

            <Box sx={{margin: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : '0px 50px 0px 60px'}}>
                <BannerComponent breakpoints={breakpoints} />
            </Box>

            <Box sx={{alignSelf: 'center', justifyContent: 'center', textAlign: breakpoints == 'xs' || breakpoints == 'sm' ? 'center' : 'right',padding: breakpoints == 'xs' || breakpoints == 'sm' ? '0.105em 0' : '1.125em 0', position: 'fixed', width: '100%', bottom: 0, ml: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' :'-20px'}}>
                <Button sx={{textShadow: "3px 3px 3px rgb(0 0 0 / 43%)", textTransform: 'none', fontSize: 15, fontWeight: 500, color: 'black', fontWeight: 500}} endIcon={ <img alt="" src={ovcodeLogo} style={{height: 28}} />}>
                    Powered by
                </Button>
            </Box> 

        </Box>
    
    )
}

export default LandingScreen