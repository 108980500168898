import React, {useState, useEffect} from "react";
import {Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useEID from "../../hooks/useEID";
import {ThreeDots} from "react-loader-spinner";
import {useSnackbar} from "notistack";
import SelectSignatoryComponent from "../signatory/e-signature/select-signatory.component";
import SelectSignatorySkeletonComponent from "../skeletons/select-signatory-skeleton.component";

const SelectSignatoryDialog = (props) => {

    const {breakpoints, openSelectSignatory, setOpenSelectSignatory, recordId, signatories} = props;

    const {enqueueSnackbar} = useSnackbar()
    const {generateEID, generatingEID} = useEID();

    const [signatoryId, setSignatoryId] = useState(null);
    const [isFetchingSignatories, setFetchingSignatories] = useState(true);


    useEffect(() => {
        setTimeout(() => {
            setFetchingSignatories(false);
        }, 3000);
    }, [])


    const handleCloseDialog = (e) => {
        setOpenSelectSignatory(false);
    }

    const sendSignatoryIDToParent = (child) => {
        setSignatoryId(child);
    }

    const handleSubmit = () => {
        let uuid = recordId;
        let signatory_id = signatoryId;

        console.log("signatoryId xxxx", signatory_id);

        if (!signatory_id) {
            enqueueSnackbar("To generate eID, please select signatory", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else {
            generateEID(uuid, signatory_id, setOpenSelectSignatory);
        }
    }

    return (
        <Dialog open={openSelectSignatory} onClose={(e) => (generatingEID) ? null : handleCloseDialog(e)} maxWidth={"md"} fullWidth={"md"} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold", padding: (breakpoints == "xs" || breakpoints == "sm") ? "16px" : "16px 24px"}}>
                Select Signatory
            </DialogTitle>

            <Divider/>
            
            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "20px 16px" : "20px 48px 12px 48px"}}>
                <Grid>
                    <Typography>
                        To generate eID, please select signatory:
                    </Typography>
                </Grid>

                <Grid container spacing={2} sx={{mt: 1}}>
                    {(isFetchingSignatories) ?
                        [0, 1, 2].map((value, index) => {
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                                    <SelectSignatorySkeletonComponent breakpoints={breakpoints} />
                                </Grid>
                            )}
                        )

                    :
                    
                        <SelectSignatoryComponent breakpoints={breakpoints} 
                            signatories={signatories} sendSignatoryIDToParent={sendSignatoryIDToParent}
                        />
                    }
                </Grid>
            </DialogContent>
     
            <DialogActions sx={{padding: 6, paddingTop: 3, paddingBottom: 3, paddingRight: 3}}>
                <Button variant="outlined" sx={{width: 120, borderRadius: 10, textTransform: "none", fontSize: 14, borderColor: "#3333cf", color: "#3333cf", '&:hover': {borderColor: "#3333cf", color: "#3333cf"}}}
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: (generatingEID) ? "auto" : 120, backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, 
                    '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => (generatingEID) ? null : handleSubmit(e)}
                    startIcon={generatingEID && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(generatingEID) ? "Submitting" : "Submit"} 
                </Button>
            </DialogActions> 
        </Dialog>
    )
}

export default SelectSignatoryDialog