import React, {useState, useEffect} from "react";
import {Box} from "@mui/material";
import SignatoryTabs from "./e-signature/signatory-tabs.component";
import EditSignatorySkeletonComponent from "../skeletons/edit-signatory-skeleton.component";

const CreateSignatoryComponent = (props) => {

    const {breakpoints, signatories, url, signatoryRecord} = props;

    const [isFetchingSignatory, setFetchingSignatory] = useState(true);

    useEffect(() => {
        if (url == "/edit-signatory") {
            setTimeout(() => {
                setFetchingSignatory(false);
            }, 3000);

        } else {
            setFetchingSignatory(false);
        }

    }, [])

    return (
        <Box>
            {(isFetchingSignatory) ?
                <EditSignatorySkeletonComponent breakpoints={breakpoints} />
            :
                <SignatoryTabs breakpoints={breakpoints} 
                    signatories={signatories} url={url} signatoryRecord={signatoryRecord} 
                />
            }
        </Box>
    ) 
}

export default CreateSignatoryComponent