import React, {useState, useEffect} from "react";
import {TextField, Button, Box, Grid, FormControl, Select, MenuItem, Typography, Card, CardContent, CardMedia} from "@mui/material";
import DefaultPhotoIcon from "../../icons/account3.png";
import UpdateESignatureDialog from "../dialogs/update-esignature-dialog";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useCSV from "../../hooks/useCSV";
import {ThreeDots} from "react-loader-spinner";
import {useSnackbar} from "notistack";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ImageCropperDialog from "../dialogs/image-cropper.dialog";

const blood_types = [
    {name: 'A+'},
    {name: 'A-'},
    {name: 'B+'},
    {name: 'B-'},
    {name: 'AB+'},
    {name: 'AB-'},
    {name: 'O+'},
    {name: 'O-'}
]

const marital_status = [
    {name: 'SINGLE'},
    {name: 'MARRIED'},
    {name: 'DIVORCED'},
    {name: 'SEPARATED'},
    {name: 'WIDOWED'},
]

const gender_options = [
    {name: 'MALE'},
    {name: 'FEMALE'}
]

const EditRecordComponent = (props) => {

    const {breakpoints, eid, uuid} = props;

    const {isUpdatingEID, updateEIDDetails} = useCSV();
    const {enqueueSnackbar} = useSnackbar();

    const [idNo, setIDNo] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [suffix, setSuffix] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [bloodType, setBloodType] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [pob, setPob] = useState("");
    const [contact_person, setContactPerson] = useState("");
    const [contact_person_no, setContactPersonNo] = useState("");
    const [position, setPosition] = useState("");
    const [photoPreview, setPhotoPreview] = useState(null);
    const [isUpdatingPhoto, setUpdatingPhoto] = useState(false);
    const [photoFile, setPhotoFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [openUpdateESignature, setOpenUpdateESignature] = useState(false);
    const [signatureFile, setSignatureFile] = useState(null);

    const [openImageEditor, setOpenImageEditor] = useState(false);
    const [userImg, setUserImg] = useState(null);
    const [isValidEmail, setValidEmail] = useState(true);

    useEffect(() => {
        if (eid) {
            setIDNo(eid?.id_no);
            setPosition(eid?.position);
            setFirstName(eid?.firstname);
            setMiddleName(eid?.middlename);
            setLastName(eid?.lastname);
            setSuffix(eid?.suffix);
            setEmailAddress(eid?.email_address);
            setDob(dayjs(eid?.dob));
            setPob(eid?.pob);
            setAddress(eid?.address);
            setGender(eid?.gender);
            setBloodType(eid?.blood_type);
            setMaritalStatus(eid?.marital_status);
            setContactPerson(eid?.contact_person);
            setContactPersonNo(eid?.contact_person_no);
            setPhotoPreview(eid?.photo);
            setSignaturePreview(eid?.e_signature);
        }
    }, [eid])


    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/
        return re.test(email);
    }

    const handleTextField = (e, name) => {
        if (name == "emailAddress") {
            let email = e.target.value;

            setEmailAddress(email);

            if (email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }

        } else if (name == "idNo") {
            setIDNo(e.target.value);

        }  else if (name == "firstName") {
            const selectedfirstName = e.target.value;
            setFirstName(selectedfirstName.toUpperCase());

        }  else if (name == "middleName") {
            const selectedmiddleName = e.target.value;
            setMiddleName(selectedmiddleName.toUpperCase());

        } else if (name == "suffix") {
            const selectedsuffix = e.target.value;
            setSuffix(selectedsuffix.toUpperCase());

        } else if (name == "lastName") {
            const selectedlastName = e.target.value;
            setLastName(selectedlastName.toUpperCase());

        } else if (name == "address") {
            const selectedaddress = e.target.value;
            setAddress(selectedaddress.toUpperCase());

        } else if (name == "pob") {
            const selectedpob = e.target.value;
            setPob(selectedpob.toUpperCase());

        } else if (name == "gender") {
            const selectedgender = e.target.value;
            setGender(selectedgender.toUpperCase());

        } else if (name == "bloodType") {
            const selectedbloodType = e.target.value;
            setBloodType(selectedbloodType.toUpperCase());

        } else if (name == "maritalStatus") {
            const selectedmaritalStatus = e.target.value;
            setMaritalStatus(selectedmaritalStatus.toUpperCase());

        } else if (name == "contactPerson") {
            const selectedContactPerson = e.target.value;
            setContactPerson(selectedContactPerson.toUpperCase());

        } else if (name == "contactPersonNo") {
            const selectedcontatcPersonNo = e.target.value;
            setContactPersonNo(selectedcontatcPersonNo);

        } else if (name == "position") {
            const selectedPosition = e.target.value;
            setPosition(selectedPosition.toUpperCase());

        }
    }

    const validatePhoto = (e) => {
        var fileName = document.getElementById("upload-photo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            setUpdatingPhoto(true);

            const imageFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setUserImg(e.target.result);
            }

            reader.readAsDataURL(imageFile);

            setOpenImageEditor(true);
 
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleResetFile = () => {
        setUpdatingPhoto(false);
        setPhotoFile(null);
        setPhotoPreview(null);
    }


    useEffect(() => {

        if(photoPreview) {
            const fetchData = async () => {
                try {
                    const photoResponse = await fetch(photoPreview);
                    const photoBlob = await photoResponse.blob();
                    const tempPhotoFile = new File([photoBlob], 'temp_photo.png', { type: 'image/png' });
                    setPhotoFile(tempPhotoFile);
                } catch (error) {
                    console.error('Error fetching photo:', error);
                }
            };
        
            fetchData();
        } else {
            setPhotoFile(new Blob([new ArrayBuffer(0)], { type: 'application/octet-stream' }));
        }

    },[photoPreview])


    useEffect(() => {

        if (signaturePreview) {
            const fetchData = async () => {
                try {
                    const signatureResponse = await fetch(signaturePreview);
                    const signatureBlob = await signatureResponse.blob();
                    const tempSignatureFile = new File([signatureBlob], 'temp_signature.png', { type: 'image/png' });
                    setSignatureFile(tempSignatureFile);
                } catch (error) {
                    console.error('Error fetching signature:', error);
                }
            };

            fetchData();
        } else {
            setSignatureFile(new Blob([new ArrayBuffer(0)], { type: 'application/octet-stream' }));
        }

    },[signaturePreview])


    const formattedDob = dob ? dayjs(dob).format('YYYY-MM-DD') : '';


    const handleUpdateEIDDetails = async () => {
        try {
            await updateEIDDetails(uuid, idNo, firstName, middleName, lastName, suffix, emailAddress, formattedDob, pob, address, gender, bloodType, maritalStatus, position, contact_person, contact_person_no, photoFile, signatureFile );
        } catch (error) {
            console.error('Error updating EID details:', error);
        }
    };

    const handleEmailNotValid = () => {
        enqueueSnackbar("Invalid email address format", { 
            variant: "error",
            autoHideDuration: 3000
        });
    }
      
    function BackButton () {
        return <Button startIcon={
            <ArrowBackIosIcon style={{width: 20, height: 20, color: "#3333cf"}} />} 
                style={{color: "black", fontSize: 16, textTransform: "none", fontWeight: 600}}
                onClick={(e) => window.location.href = "/view-record?id=" + uuid}
            >
                Back
            </Button>
    }

    
    return (
        <>
            <Box sx={{border: "0.1px solid #3333cf", borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 3, pt: 1.5}}>
                <BackButton />

                <form noValidate autoComplete="off">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Email Address :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{style: {fontSize: 16}}}
                                            sx={{fontSize: 16,
                                                "& .MuiInputLabel-root": {color: '#1f4380'},
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                }
                                            }}
                                            defaultValue={emailAddress}
                                            onChange={(e) => handleTextField(e, "emailAddress")}
                                            helperText={
                                                <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -10px"}}>
                                                    {(!isValidEmail) ? "Email Address is invalid" : ""}
                                                </p>
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            ID No :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            sx={{fontSize: 16,
                                                "& .MuiInputLabel-root": {color: '#1f4380'},
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                }
                                            }}
                                            defaultValue={idNo}
                                            onChange={(e) => handleTextField(e, "idNo")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Position :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: "characters"}}
                                            sx={{fontSize: 16,
                                                "& .MuiInputLabel-root": {color: '#1f4380'},
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                }
                                            }}
                                            defaultValue={position}
                                            onChange={(e) => handleTextField(e, "position")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            First Name :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                              }}
                                              InputLabelProps={{style: {color: '#1f4380'}}}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                },
                                                fontSize: 16
                                              }}
                                            defaultValue={firstName}
                                            onChange={(e) => handleTextField(e, "firstName")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Middle Name :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                              }}
                                              InputLabelProps={{style: {color: '#1f4380'}}}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                },
                                                fontSize: 16
                                              }}
                                            defaultValue={middleName}
                                            onChange={(e) => handleTextField(e, "middleName")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Last Name :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                              }}
                                              InputLabelProps={{style: {color: '#1f4380'}}}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                },
                                                fontSize: 16
                                              }}
                                            defaultValue={lastName}
                                            onChange={(e) => handleTextField(e, "lastName")}
                                        />
                                    </Grid>
                                </Grid>


                                
                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Suffix :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                              }}
                                              InputLabelProps={{style: {color: '#1f4380'}}}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                },
                                                fontSize: 16
                                              }}
                                            defaultValue={suffix}
                                            onChange={(e) => handleTextField(e, "suffix")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Date of Birth :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    value={dob}
                                                    required
                                                    views={['year', 'month', 'day']}
                                                    inputProps={{style: {fontSize: 16}}}
                                                    sx={{
                                                        '& .MuiInputLabel-root': { color: '#1f4380' },
                                                        '& .MuiOutlinedInput-root': {
                                                        '& > fieldset': { borderColor: '#3333cf4f !important' },
                                                        },
                                                        fontSize: 16,
                                                    }}
                                                    onChange={(newValue) => {
                                                        setDob(newValue);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Place of Birth :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                              }}
                                              InputLabelProps={{style: {color: '#1f4380'}}}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                },
                                                fontSize: 16
                                              }}
                                            defaultValue={pob}
                                            onChange={(e) => handleTextField(e, "pob")}
                                        />
                                    </Grid>
                                </Grid> 

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Address :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {textTransform: 'uppercase', fontSize: 16},
                                                autoCapitalize: 'characters' // capitalizes all characters in input
                                              }}
                                              InputLabelProps={{style: {color: '#1f4380'}}}
                                              sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                },
                                                fontSize: 16
                                              }}
                                            defaultValue={address}
                                            onChange={(e) => handleTextField(e, "address")}
                                        />
                                    </Grid>
                                </Grid>
                                
                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Gender :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                inputProps={{
                                                    style: {textTransform: 'uppercase', fontSize: 16},
                                                    autoCapitalize: 'characters' // capitalizes all characters in input
                                                  }}
                                                sx={{fontSize: 16,
                                                    "& .MuiOutlinedInput-notchedOutline" : { borderColor: "#3333cf4f !important"}
                                                }}
                                                value={gender}
                                                onChange={(e) => handleTextField(e, "gender")}
                                            >
                                                {gender_options.map((gender) => {
                                                    return (
                                                        <MenuItem value={gender.name}>
                                                            {gender.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Blood Type :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                inputProps={{
                                                    style: {textTransform: 'uppercase', fontSize: 16},
                                                    autoCapitalize: 'characters' // capitalizes all characters in input
                                                  }}
                                                sx={{fontSize: 16, 
                                                    "& .MuiOutlinedInput-notchedOutline": {borderColor: "#3333cf4f !important"},
                                                    }}
                                                value={bloodType}
                                                onChange={(e) => handleTextField(e, "bloodType")}
                                            >
                                                {blood_types.map((blood) => {
                                                    return (
                                                        <MenuItem value={blood.name}>
                                                            {blood.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Marital Status :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                inputProps={{
                                                    style: {textTransform: 'uppercase', fontSize: 16},
                                                    autoCapitalize: 'characters' // capitalizes all characters in input
                                                  }}
                                                sx={{fontSize: 16,
                                                    "& .MuiOutlinedInput-notchedOutline": {borderColor: "#3333cf4f !important"},
                                                    }}
                                                value={maritalStatus}
                                                onChange={(e) => handleTextField(e, "maritalStatus")}
                                            >
                                                {marital_status.map((status) => {
                                                    return (
                                                        <MenuItem value={status.name}>
                                                            {status.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Contact Person :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{style: {textTransform: "uppercase", fontSize: 16}, autoCapitalize: "characters"}}
                                            sx={{fontSize: 16,
                                                "& .MuiInputLabel-root": {color: '#1f4380'},
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                }
                                            }}
                                            defaultValue={contact_person}
                                            onChange={(e) => handleTextField(e, "contactPerson")}
                                          
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Contact Person No :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField 
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{style: {fontSize: 16}}}
                                            sx={{fontSize: 16,
                                                "& .MuiInputLabel-root": {color: '#1f4380'},
                                                "& .MuiOutlinedInput-root": {
                                                    "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                }
                                            }}
                                            defaultValue={contact_person_no}
                                            onChange={(e) => handleTextField(e, "contactPersonNo")}
                                           
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={5} sx={{mt: "5px"}}>
                                <Grid container spacing={3} sx={{alignItems: 'center', justifyContent:  'center' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
                                            <Grid item xs={12} sx={{textAlign: "center"}}>
                                                <Typography variant="subtitle2" style={{display: "none", mb: "10px", fontSize: 16, fontWeight: 550}} className="required-field">
                                                    Photo
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Card sx={{border: 1, borderColor: "#3333cf4f", marginBottom: 2, marginTop: 0, boxShadow: 'none'}}>
                                                        {photoPreview ?
                                                            <CardContent sx={{textAlign:'center'}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    src={(photoPreview) ? photoPreview : DefaultPhotoIcon}
                                                                    style={{width: '100%', height : 125, objectFit: 'contain', backgroundColor: 'rgb(0 0 0 / 8%)'}}
                                                                />
                                                            </CardContent>
                                                        :
                                                            <CardContent sx={{textAlign:'center', height: 165}}>
                                                                <Typography sx={{pt: "45px"}}>
                                                                    No available photo
                                                                </Typography>
                                                            </CardContent>
                                                        }
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: "center", textAlign: "center"}}>
                                                <Grid container sx={{justifyContent: "center"}}>
                                                    {isUpdatingPhoto ?
                                                        <>
                                                            <Grid item xs={6} sm={6}>
                                                                <Button variant="outlined" onClick={(e) => handleResetFile(e)}
                                                                    sx={{ml: "25px", width: 'auto',fontWeight: 550, borderRadius: 10, textTransform: 'none', color: "#3333cf", borderColor: "#3333cf",
                                                                        '&:hover': {color: "#3333cf", borderColor: "#3333cf"}
                                                                    }} 
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    :
                                                        <Grid item xs={12} sm={12} >
                                                            <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, textTransform: 'none', borderColor: "#168401", color: "#168401", '&:hover': {color: "#168401", borderColor: "#168401"}}}>
                                                                <input 
                                                                    type="file" 
                                                                    accept="image/*" 
                                                                    id="upload-photo"
                                                                    onChange= {(e) => validatePhoto(e)}
                                                                    hidden
                                                                />

                                                                <label style={{cursor: "pointer", fontWeight: 550}} htmlFor="upload-photo">
                                                                    Browse Photo 
                                                                </label>
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container sx={{alignItems: 'center', justifyContent:  'center'}}>
                                            <Grid item xs={12} sx={{textAlign: "center"}}>
                                                <Typography variant="subtitle2" style={{display: "none", mb: "10px", fontSize: 16, fontWeight: 550}} className="required-field">
                                                    e-Signature
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Card sx={{border: 1, borderColor: "#3333cf4f", marginBottom: 2, marginTop: 0, boxShadow: 'none'}}>
                                                        {signaturePreview ? 
                                                            <CardContent sx={{textAlign:'center'}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    src={signaturePreview}
                                                                    style={{width: '100%', height : 125, objectFit: 'contain'}}
                                                                />
                                                            </CardContent>
                                                        :
                                                            <CardContent sx={{textAlign: 'center', height: 165}}>
                                                                <Typography sx={{pt: "45px"}}>
                                                                    No available signature
                                                                </Typography>
                                                            </CardContent>
                                                        }
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Grid container sx={{justifyContent: 'center'}}>
                                                    <Grid item xs={12} sm={12} >
                                                        <Button variant="outlined" sx={{fontWeight: 550, width: 'auto', borderRadius: 10, boxShadow: 'none',  textTransform: 'none', borderColor: "#168401", color: "#168401", '&:hover': {color: "#168401", borderColor: "#168401"}}}
                                                            onClick={(e) => setOpenUpdateESignature(true)}
                                                        >
                                                            {(signaturePreview) ? "Change Signature" : "Create Signature"}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>   
                    </Grid>

                    <Grid container direction="row" sx={{justifyContent: "flex-end", textAlign: "end", pb: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 3, pt: 3, mt: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                        <Button variant="outlined" sx={{width: 120, borderRadius: 10, boxShadow: "none", fontWeigth: 550, color: "#3333cf", borderColor: "#3333cf", '&:hover': {color: "#3333cf", borderColor: "#3333cf"}}}
                            onClick={(e) => window.location.href = "/view-record?id=" + uuid}
                        >
                            CANCEL
                        </Button>

                        &nbsp; &nbsp;   
                
                        <Button variant="contained" sx={{width: (isUpdatingEID) ? {} : 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none", fontWeigth: 550, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                            startIcon={isUpdatingEID && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                            onClick={(e) => (isValidEmail) ? handleUpdateEIDDetails() : handleEmailNotValid()}
                        >
                            {(isUpdatingEID) ? "UPDATING" : "UPDATE"}
                        </Button>
                    </Grid>
                </form>
            </Box>

            {openUpdateESignature &&
                <UpdateESignatureDialog breakpoints={breakpoints}
                    openUpdateESignature={openUpdateESignature} setOpenUpdateESignature={setOpenUpdateESignature}
                    setSignaturePreview={setSignaturePreview} setSignatureFile={setSignatureFile}
                />
            }

            {openImageEditor &&
                <ImageCropperDialog breakpoints={breakpoints} 
                    openImageEditor={openImageEditor} setOpenImageEditor={setOpenImageEditor}
                    userImg={userImg} setPhotoPreview={setPhotoPreview} setPhotoFile={setPhotoFile}
                />
            }
        </>
    )
}

export default EditRecordComponent