import React from "react";
import {Dialog, DialogTitle, DialogContent, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NextixLogo from "../../icons/nextix.png";

const SuccessConfirmationDialog = (props) => {

    const {breakpoints, openSuccessConfirmation, setOpenSuccessConfirmation} = props;

    const handleCloseConfirmationDialog = (e) => {
        setOpenSuccessConfirmation(false);
        window.location.href = "/";
    }

    const CustomDialogTitle = (props) => {
        const {children, onClose, ...other} = props;

        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}

                {onClose ? 
                    (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{position: "absolute", right: 8, top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) 
                : 
                    null
                }
          </DialogTitle>
        )
    }

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openSuccessConfirmation}
            onClose={(e) => handleCloseConfirmationDialog(e)}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseConfirmationDialog(e)}>
                <img alt="" src={NextixLogo} style={{width: 65, marginLeft: (breakpoints == "xs" || breakpoints == "sm") ? "12px" : "21px"}} /> 
            </CustomDialogTitle>

            <DialogContent dividers sx={{pt: 0, borderTop: "none", mt: "-10px", padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px 28px 24px" : "0px 38px 24px"}}>
                Your information has been successfully updated, 
                and we are currently reviewing the details you provided. 
                During this time, please ensure that you regularly check your 
                email for any further updates. Thank you.
            </DialogContent>
        </Dialog> 
    )
}

export default SuccessConfirmationDialog