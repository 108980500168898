import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {CustomHttpPostParams, CustomHttpGetParams, HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost, HttpPut, HttpDelete} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_service.subscriptionKey;

 
const retrievePreRegistrationLink = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_PRE_REGISTRATION_LINK, query_params, subscriptionKey);

    return result;

}


const generateRegistrationLink = (expiration_days) => {

    let query_params = {
        expiration_days: expiration_days,
    }

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.REGENERATE_PRE_REGISTRATION_LINK, query_params, subscriptionKey);

    return result;

}


const resetRegistrationLink = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;
    
    let result = HttpDelete(routes.DELETE_PRE_REGISTRATION_LINK, query_params, subscriptionKey);
    
    return result;
}

const validateAccessKey = (apiAccessKey) => {

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = CustomHttpGetParams(routes.VALIDATE_ACCESS_KEY, apiAccessKey, subscriptionKey);

    return result;
}

const preRegisterEID = (payload, apiAccessKey) => {

    console.log("preRegisterEID payload xxxx", payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = CustomHttpPostParams(routes.PRE_REGISTER_EID, payload, apiAccessKey, subscriptionKey);

    return result;
}


const PreRegistrationService = {
    retrievePreRegistrationLink,
    generateRegistrationLink,
    resetRegistrationLink,
    validateAccessKey: validateAccessKey,
    preRegisterEID: preRegisterEID
}

export default PreRegistrationService;