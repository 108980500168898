import React, {useEffect} from "react";
import {Box, Grid} from "@mui/material";
import useWidth from "../hooks/useWidth";
import useCSV from "../hooks/useCSV";
import EditRecordComponent from "../components/csv/edit-record.component";
import EditRecordSkeletonComponent from "../components/skeletons/edit-record-skeleton.component";

const EditRecordScreen = () => {

    const breakpoints = useWidth();
    const {isFetchingDetails, eidDetails, vieweIDDetails} = useCSV();

    const uuid = (new URLSearchParams(window.location.search)).get("id");

    useEffect(() => {
        vieweIDDetails(uuid);
    }, [])


    return (
        <Box sx={{overflow: "hidden", width: breakpoints == 'xs' || breakpoints == 's' ? "100%" : "95%", margin: "auto", mt: (breakpoints == "xs" || breakpoints == "sm") ? {} : {}}}>
            <Box style={{flexGrow: 1, margin: "auto"}}>
                <Grid container spacing={2} direction="row" sx={{padding: 2}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        {(isFetchingDetails) ? 
                            <EditRecordSkeletonComponent />

                        :
                            <EditRecordComponent breakpoints={breakpoints} uuid={uuid}
                                eid={eidDetails}
                            />
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default EditRecordScreen