import React from "react";
import {Skeleton, Grid, Box} from "@mui/material";

const EditSignatorySkeletonComponent = (props) => {

    const {breakpoints} = props;

    return (
        <Box sx={{mt: 4}}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{marginBottom: (breakpoints == "xs" || breakpoints == "sm") ? "16px !important" : "24px !important"}}>
                <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 20 : 30} width={(breakpoints == "xs" || breakpoints == "sm") ? "50%" : "40%"} style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />  
            </Grid>

            <Grid item xs={12} sx={{width: "93%", margin: "auto"}}>
                <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 250 : 280} width="100%" style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
            </Grid>

            <Grid container xs={12} sm={12} md={12} lg={12} xl={12} sx={{display: "flex", width: "93%", mt: (breakpoints == "xs" || breakpoints == "sm") ? "16px !important" : "32px !important", margin: "auto"}}>
                <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 20 : 30} width={(breakpoints == "xs" || breakpoints == "sm") ? "50%" : "40%"} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
            </Grid>

            <Grid container sx={{display: "flex", width: "93%", mt: (breakpoints == "xs" || breakpoints == "sm") ? "8px !important" : "16px !important", margin: "auto"}}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 20 : 30} width={(breakpoints == "xs" || breakpoints == "sm") ? "100%" : "80%"} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                </Grid>

                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{ml: "auto", mt: (breakpoints == "xs" || breakpoints == "sm") ? 1 : {}, mb: 5}}>
                    <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 20 : 30} width="100%" style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default EditSignatorySkeletonComponent