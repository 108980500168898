import {Paper, Table, TableBody, TableContainer, TableRow, TableHead, TableCell, Grid} from "@mui/material";
import ErrorRecordRowComponent from "./error-records-row.component";

function TableHeader () {
    const tablecell = [ 
        {label : 'ID No.'},
        {label : 'Last Name'},
        {label : 'First Name'},
        {label : 'Middle Name'},
        {label : 'Error Message'},
    ]

    return (
        <TableHead>
            <TableRow>
                {tablecell.map((value) => {
                    return(
                        <TableCell sx={{fontWeight: 700, color: 'white', backgroundColor: "#3333cf"}}>{value.label}</TableCell>
                        
                    )
                })}
            </TableRow>
        </TableHead>
    )
}


const ErrorRecordsComponent = (props) => {

    const {csvErrorList} = props;

    return (
        <Grid item xs={12} >
        <TableContainer component={Paper} sx={{border: "1px solid #3333cf", borderRadius: 2,  width: "100%"}}>
            <Table stickyHeader aria-label="sticky table">
                <TableHeader/>
                <TableBody> 
                    {csvErrorList.map((list, index)=>{
                        return(
                            <ErrorRecordRowComponent list={list} index={index}/>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        </Grid>
    )
}
export default ErrorRecordsComponent