import React, {useState, useEffect} from "react";
import {Grid, Box, Card, CardContent, CardMedia, Typography, Checkbox, FormGroup, FormControlLabel} from "@mui/material";

const SelectSignatoryComponent = (props) => {

    const {breakpoints, signatories, sendSignatoryIDToParent} = props;

    const [isSignatoryDefault, setSignatoryDefault] = useState(null);
    const [selectedSignatory, setSelectedSignatory] = useState(null);
    const [signatoryList, setSignatoryList] = useState(null);

    useEffect(() => {
        const storedSignatories = localStorage.getItem("savedSignatoriesList");
        const parsedSignatories = JSON.parse(storedSignatories);
        
        // signatories props
        let defaultSignatory;
        let defaultSignatoryIndex;

        if (signatories) {
            defaultSignatory = signatories.find(signatory => signatory.is_default);
            defaultSignatoryIndex = signatories.findIndex(signatory => signatory.is_default);
        }

        // stored signatories in local storage
        let defaultSignatoryLocalStorage;
        let defaultSignatoryIndexLocalStorage;

        if (parsedSignatories) {
            defaultSignatoryLocalStorage = parsedSignatories.find(signatory => signatory.is_default);
            defaultSignatoryIndexLocalStorage = parsedSignatories.findIndex(signatory => signatory.is_default);
        }

        if (signatories.length > 0) {
            setSignatoryDefault(defaultSignatory.is_default);
            setSelectedSignatory(defaultSignatoryIndex);
            sendSignatoryIDToParent(defaultSignatory.id);
            setSignatoryList(signatories);

        } else if (parsedSignatories.length > 0){
            setSignatoryDefault(defaultSignatoryLocalStorage.is_default);
            setSelectedSignatory(defaultSignatoryIndexLocalStorage);
            sendSignatoryIDToParent(defaultSignatoryLocalStorage.id);
            setSignatoryList(parsedSignatories);
        }
    }, [signatories]);

    const handleCheckboxChange = (e, index, signatory) => {
        setSignatoryDefault(e.target.checked);
        setSelectedSignatory(index);

        let isChecked = e.target.checked;

        if (isChecked == true) {
            sendSignatoryIDToParent(signatory.id);

        } else {
            sendSignatoryIDToParent(null);
        }
    }

    return (
        <>
            {signatoryList?.map((signatory, index) => {
                return (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>      
                        <Card sx={{borderRadius: 2, padding: (breakpoints == "xs" || breakpoints == "sm") ? 1 : 0,
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", height: "auto"}}
                        >
                            <CardContent sx={{textAlign: "center", padding: 0}}>
                                <Grid container sx={{ml: (breakpoints == "xs" || breakpoints == "sm") ? "1px !important" : {}, display: "flex", width: "93%", mt: "16px !important", margin: "auto"}}>
                                    <FormGroup>
                                        <FormControlLabel 
                                            control={<Checkbox size="small" 
                                            checked={isSignatoryDefault && selectedSignatory === index}
                                            value={index}
                                            key={index}
                                            onChange={(e) => handleCheckboxChange(e, index, signatory)} />}
                                            label={<Typography sx={{fontSize: 14}}>Set as Signatory</Typography>} 
                                        />
                                    </FormGroup>
                                </Grid>

                                <CardMedia
                                    sx={{width: "100%", height: 200, objectFit: "contain", backgroundColor: "rgb(0 0 0 / 8%)", backgroundSize: "contain"}}
                                    image={signatory?.signature_url}
                                />

                                <Box sx={{mt: 1}}>
                                    <Typography sx={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 14 : 16, fontWeight: 500}}>
                                        {signatory?.name}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                )
            })}
        </>
    )
}

export default SelectSignatoryComponent