import React, {useEffect, useState} from "react";
import {Typography, BottomNavigation, BottomNavigationAction, Toolbar} from "@mui/material";
import {Box} from "@mui/system";
import {useNavigate} from "react-router-dom";
import HomeIcon from "../icons/home-black.png";
import EIDIcon from "../icons/id-black.png";
import HomeIconHover from "../icons/home-green.png";
import EIDIconHover from "../icons/id-green.png";
import RegLinkIcon from "../icons/hyperlink-black.png";
import RegLinkIconHover from "../icons/hyperlink-green.png";

const TopNavigationComponent = ({breakpoints}) => {

    const url = window.location.pathname;
    const [value, setValue] = useState(0);
  
    const navigate = useNavigate();

    useEffect(() => {
        if (url == "/create-record" || url == "/upload-csv" || url == "/csv-records" || url == "/profile" || url == "/view-agency-information" ||
            url == "/update-agency-information" || url == "/config-eid" || url == "/update-config-eid") 
        {
            setValue(0);

        } else if (url == "/records") {
            setValue(1);

        } else if(url == "/registration-link") {
            setValue(2);
        }
    }, [])
   
    const handleTabChange = (event, newValue) => {
        setValue(newValue);

        let route;
      
        switch (newValue) {
            case 0:
                route = "/upload-csv";
                break;
    
            case 1:
                route = "/records";
                break;
            
            case 2:
                route =  "/registration-link";
                break;
        
            default:
                route = "/upload-csv";
        }

        navigate(route);
    }


    return (
        <Box sx={{width: "100%", position: "fixed", top: 12, zIndex: 1, boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)",
            // zIndex: (theme) => theme.zIndex.drawer - 1
            }}
        >
            <Toolbar />
            
            <BottomNavigation showLabels value={value} onChange={handleTabChange}
                sx={{mt: "-4px", boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}
            >
                <BottomNavigationAction 
                    label={
                        <Typography sx={{fontSize: "0.75rem", color: (value == 0) ? "#168401" : "#171e2a"}}> 
                            Home
                        </Typography>
                    }
                    sx={{backgroundColor: (value == 0) ? "#0178f212" : {}}}
                    icon={(value == 0) ?
                        <img alt="" src={HomeIconHover} style={{height: 25}} />
                    : 
                        <img alt="" src={HomeIcon} style={{height: 25}} />
                    }  
                />

                <BottomNavigationAction
                    label={
                        <Typography sx={{fontSize: "0.75rem", color: (value == 1) ? "#168401" : "#171e2a"}}> 
                            eID
                        </Typography>
                    }
                    sx={{backgroundColor: (value == 1) ? "#0178f212" : {}}}
                    icon={(value == 1) ?
                        <img alt=""  src={EIDIconHover} style={{height: 25}} />
                    :
                        <img alt=""  src={EIDIcon} style={{height: 25}} />
                    }
                />

                <BottomNavigationAction 
                    label={
                        <Typography sx={{fontSize: "0.75rem", color: (value == 2) ? "#168401" : "#171e2a"}}> 
                            Registration Link
                        </Typography>
                    }
                    sx={{backgroundColor: (value == 2) ? "#0178f212" : {}}}
                    icon={(value == 2) ?
                        <img alt=""  src={RegLinkIconHover} style={{height: 25}} />
                    :
                        <img alt=""  src={RegLinkIcon} style={{height: 25}} />
                    }
                />  
            </BottomNavigation>
        </Box>
    )  
}
  
export default TopNavigationComponent