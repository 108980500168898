import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useEID from "../../hooks/useEID";
import { useEffect, useState } from "react";
import {ThreeDots} from 'react-loader-spinner';


const DownloadEIDDialog = (props) => {

    const url = window.location.pathname;

    const {breakpoints, openDownload, handleCloseDownload, record, setOpenDownload} = props;

    const {donwloadEID, isDownloading } = useEID();

    const [fileName, setFilename] = useState("");

    useEffect(() =>{
        setFilename(record?.lastname + "_" + record?.firstname);
    },[])

    const handleCloseDownloadDialog = (e) => {
        if (url == "/view-record") {
            setOpenDownload(false);

        } else {
            handleCloseDownload();
        }
    }

    const handleDownloadEID = () => {

        let filename = fileName;

        console.log("filenamexxx", filename)
        donwloadEID(record?.uuid, handleCloseDownload, filename);
      
    }

    console.log('get record list in dialog', record)

    return(
        <Dialog open={openDownload} onClose={(e) => handleCloseDownloadDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Download eID Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography>
                    Are you sure you want to download <b>{record?.firstname}&nbsp;{record?.lastname}&nbsp;{record?.suffix}'s</b> eID Digital Copy?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{textTransform: "none", width: "auto", borderRadius: 10, borderColor: "#3333cf", color: "#3333cf", '&:hover': {borderColor: "#3333cf", color: "#3333cf"}}} 
                    onClick={(e) => handleCloseDownloadDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: "none", width: 'auto', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                   onClick={(e) => handleDownloadEID(e)}
                   startIcon={isDownloading && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    { isDownloading ? "Downloading" : "Download" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DownloadEIDDialog