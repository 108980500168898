import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Grid,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import RecordsListComponent from "./record-list.component";
import useEID from "../../hooks/useEID";
import RecordListSkeleton from "../skeletons/record-list-skeleton-loader.component";

function RecordsTableHeader() {
    const recordsTableHeader = [
        { label: "ID No." },
        { label: "Last Name" },
        { label: "First Name" },
        { label: "Middle Name" },
        { label: "Birthdate" },
        { label: "Status" },
        { label: "Actions" },
    ];

    return (
        <TableHead>
            <TableRow>
                {recordsTableHeader.map((value, index) => (
                    <TableCell
                        key={index}
                        sx={{
                            fontWeight: 700,
                            backgroundColor: "#3333cf",
                            color: "white",
                        }}
                    >
                        {value.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function RecordsComponent(props) {
    const { chosenTab } = props;
    const { loading, setLoading, retrieveeIDRecordsList, eIDRecordList, pagination, filterByStatus } = useEID();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    const handleChangePagination = (event, page) => {
        setPage(page);
        updateLocalStorage(page); // Store the current page in localStorage
        loadRecords(page); // Load records based on the current page
        updateURL(page); // Update the URL with the page number
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const storedPage = urlParams.get("page");
        const initialPage = storedPage ? parseInt(storedPage) : 1;
        setPage(initialPage);
        loadRecords(initialPage);
        updateURL(initialPage);

        const handlePopstate = () => {
            const updatedUrlParams = new URLSearchParams(window.location.search);
            const updatedPage = updatedUrlParams.get("page");
            setPage(parseInt(updatedPage));
        };

        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, [chosenTab]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const storedPage = urlParams.get("page");
        const initialPage = storedPage ? parseInt(storedPage) : 1;
        setPage(initialPage);
        loadRecords(initialPage);
        updateURL(initialPage);
    }, []);

    const updateLocalStorage = (currentPage) => {
        localStorage.setItem("currentPage", currentPage.toString()); // Store the current page in localStorage
    };

    const updateURL = (currentPage) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("page", currentPage);
        const newURL = `${window.location.pathname}?${urlParams.toString()}`;
        window.history.replaceState({ path: newURL }, "", newURL);
    };

    const loadRecords = (currentPage) => {
        setLoading(true);
        if (chosenTab === "PENDING FOR APPROVAL") {
            geteIDFilterByStatus("Pending for Approval", currentPage);
        } else if (chosenTab === "NEW") {
            geteIDFilterByStatus("New/Pending", currentPage);
        } else if (chosenTab === "COMPLETED") {
            geteIDFilterByStatus("Completed/Revoked/Suspended", currentPage);
        } else if (chosenTab === "APPROVE") {
            geteIDFilterByStatus("Approved", currentPage);
        } else if (chosenTab === "AUTHENTICATION") {
            geteIDFilterByStatus("Authentication", currentPage);
        } else if (chosenTab === "REJECTED") {
            geteIDFilterByStatus("Reject", currentPage);
        } else {
            retrieveeIDRecordsList(currentPage);
        }
    };

    const geteIDFilterByStatus = (status, currentPage) => {
        filterByStatus(status, currentPage);
    };



    return (
        <Box>
            <Grid container sx={{ padding: 3 }}>
                <TableContainer component={Paper} sx={{ border: "1px solid #3333cf4f", borderRadius: 2, width: "100%" }}>
                    <Table stickyHeader aria-label="sticky table">
                        <RecordsTableHeader />

                        {loading ? (
                            <TableBody>
                                <RecordListSkeleton />
                            </TableBody>
                        ) : (
                            <TableBody>
                                {eIDRecordList?.map((record, index) => (
                                    <RecordsListComponent record={record} key={index} setLoading={setLoading} page={page} />
                                ))}
                            </TableBody>
                        )}
                    </Table>

                    <Grid container spacing={2} sx={{ justifyContent: "center", alignSelf: "center" }}>
                        {!loading && eIDRecordList.length === 0 && (
                            <Typography
                                style={{
                                    color: "rgba(0, 0, 0, 0.54)",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    marginTop: 50,
                                    marginBottom: 30,
                                    textAlign: "center",
                                }}
                            >
                                {chosenTab === ""
                                    ? "No eID record(s) available to display yet."
                                    : chosenTab === "NEW"
                                        ? "No eID record(s) on New/Pending to display yet."
                                        : chosenTab === "PENDING FOR APPROVAL"
                                            ? "No eID record(s) on Pending for Approval to display yet."
                                            : chosenTab === "APPROVE"
                                                ? "No eID record(s) on Approve to display yet."
                                                : chosenTab === "AUTHENTICATION"
                                                    ? "No eID record(s) on Authentication to display yet."
                                                    : chosenTab === "COMPLETED"
                                                        ? "No eID record(s) on Completed to display yet."
                                                        : chosenTab === "REJECTED"
                                                            ? "No eID record(s) on Rejected to display yet."
                                                            : null}
                            </Typography>
                        )}
                    </Grid>

                    {!loading && eIDRecordList.length !== 0 && (
                        <Pagination
                            count={pagination}
                            page={page}
                            onChange={handleChangePagination}
                            style={{ margin: "auto", width: "fit-content", paddingTop: 30, paddingBottom: 30 }}
                        />
                    )}
                </TableContainer>
            </Grid>
        </Box>
    );
}

export default RecordsComponent;
