import React, {useState, useEffect} from "react";
import {Button, Grid, Typography, TextField, Box, Checkbox, FormGroup, FormControlLabel} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import { useSnackbar} from "notistack";
import AddImgIcon from "../../../icons/add-img.png";
import EditSignatoryCropperDialog from "./edit-signatory-cropper.dialog";

const EditSignatoryByUploadComponent = (props) => {

    const {breakpoints, url} = props;

    const {enqueueSnackbar} = useSnackbar();

    const [signatureFile, setSignatureFile] = useState(null);
    const [signatoryName, setSignatoryName] = useState("");
    const [signatoryPosition, setSignatoryPosition] = useState("");
    const [isSigNameRequired, setSigNameRequired] = useState(false);
    const [isSigPosRequired, setSigPosRequired] = useState(false);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [openSigEditor, setOpenSigEditor] = useState(false);
    const [isSignatoryDefault, setSignatoryDefault] = useState(null);
    const [signatoryRecord, setSignatoryRecord] = useState({});

    const documentId = new URLSearchParams(window.location.search).get("id");


    useEffect(() => {
        const storedSignatoryRecord = localStorage.getItem("signatoryRecord");
        const parsedSignatoryRecord = JSON.parse(storedSignatoryRecord);

        setSignatoryRecord(parsedSignatoryRecord);
    }, [])

    useEffect(() => {
        if (signatoryRecord) {
            setSignatoryName(signatoryRecord.name);
            setSignatoryDefault(signatoryRecord.is_default);
            setSignatureFile(signatoryRecord.signature_url);
            setSignatoryPosition(signatoryRecord.position);
        }
    }, [signatoryRecord])


    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();

        if (mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            setSignatureFile(file);
            setSignaturePreview(URL.createObjectURL(file));

        } else {
            enqueueSnackbar("Image file only, please choose an image file", {
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleTextFieldChange = (e, name) => {

        if (name == "signatoryName") {
            let signatory_name = e.target.value;

            setSignatoryName(signatory_name);
            setSigNameRequired(false);

        } else if (name == "signatoryPosition") {
            let signatory_position = e.target.value;

            setSignatoryPosition(signatory_position);
            setSigPosRequired(false);
        }

    }

    const handleClearFile = () => {
        if (signaturePreview) {
            setSignatureFile(null);
            setSignaturePreview(null);

        } else {
            setSignatureFile(null);
        }
    }

    const handleCheckboxChange = (e) => {
        setSignatoryDefault(e.target.checked);
    }

    const handleUploadSignature = (e) => {
        if (signatoryName && signatureFile) {
            setOpenSigEditor(true);

        } else if (!signatureFile) {
            enqueueSnackbar("Signature File is required", {
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!signatoryName) {
            setSigNameRequired(true);

        } else if (!signatoryPosition) {
            setSigPosRequired(true);
        }

    }


    return (
        <Box sx={{ mt: 1.5 }}>
            <Grid item xs={12} sx={{ width: "93%", margin: "auto" }}>
                <FileUploader
                    fileOrFiles={signatureFile}
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    label="Select an image file to upload or drag and drop it here"
                    children={
                        <>
                            {(signatureFile) ?
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 2.2px #3333cf",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "300px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear"
                                    }}
                                >
                                    <img alt="" src={(signaturePreview) ? signaturePreview : signatureFile}
                                        style={{
                                            height: (breakpoints == 'xs' || breakpoints == 'sm') ? 150 : 175,
                                            width: (breakpoints == 'xs' || breakpoints == 'sm') ? 210 : "auto",
                                            objectFit: (breakpoints == 'xs' || breakpoints == 'sm') ? "contain" : {},
                                            padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px 10px 0px 10px" : "10px",
                                            backgroundColor: "rgb(0, 0, 0, 8%)",
                                        }}
                                    />
                                </div>
                            :
                            
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 2.2px #3333cf",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "300px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear",
                                    }}
                                >
                                    <Grid>
                                        <Grid sx={{ textAlign: "center" }}>
                                            <img alt="" src={AddImgIcon} style={{ width: 50, height: 50, objectFit: "contain" }} />
                                        </Grid>

                                        <Typography variant="subtitle1" style={{ color: "#3333cf", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 16 }}>
                                            Select an image file to upload
                                        </Typography>

                                        <Typography variant="subtitle2" style={{ textAlign: "center", color: "rgb(0 0 0 / 33%)", fontWeight: 500, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 14 }}>
                                            or drag and drop it here
                                        </Typography>
                                    </Grid>
                                </div>
                            }
                        </>
                    }
                />
            </Grid>

            <Grid container sx={{ display: "flex", width: "93%", mt: "16px !important", margin: "auto" }}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox size="small" checked={isSignatoryDefault} onChange={(e) => handleCheckboxChange(e)} />}
                        label={<Typography sx={{ fontSize: 14 }}>Set Signatory as Default</Typography>}
                    />
                </FormGroup>
            </Grid>

            <Grid container sx={{ display: "flex", width: "93%", mt: "-2px !important", margin: "auto" }} spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{pl: "0px !important"}}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        placeholder="Signatory Name"
                        value={signatoryName}
                        onChange={(e) => handleTextFieldChange(e, "signatoryName")}
                        inputProps={{ style: { padding: "10px", fontSize: 14 } }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#3333cf !important" }
                            }
                        }}
                        helperText={
                            <p style={{ fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -14px" }}>
                                {(isSigNameRequired) ? "Signatory Name is required" : ""}
                            </p>
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? "0px !important" : {}}}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        autoComplete="off"
                        placeholder="Signatory Position"
                        value={signatoryPosition}
                        onChange={(e) => handleTextFieldChange(e, "signatoryPosition")}
                        inputProps={{ style: { padding: "10px", fontSize: 14 } }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#3333cf !important" }
                            }
                        }}
                        helperText={
                            <p style={{ fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -14px" }}>
                                {(isSigPosRequired) ? "Signatory Position is required" : ""}
                            </p>
                        }
                    />
                </Grid>

                <Grid item xs={12}  sx={{ textAlign: "end", mt: (breakpoints == "xs" || breakpoints == "sm") ? 3 : "4px", mb: 5 }}>
                    {(signatureFile) &&
                        <>
                            <Button variant="outlined"
                                sx={{ width: 120, borderColor: "#3333cf", color: "#3333cf", borderRadius: 10, boxShadow: "none",
                                '&:hover': {color: "#3333cf", borderColor: "#3333cf"}}}
                                onClick={(e) => handleClearFile(e)}
                            >
                                CLEAR
                            </Button>

                            {(breakpoints == "xs" || breakpoints == "sm") ?
                                <> <br /> <br /> </>
                                :
                                <> &nbsp; &nbsp; </>
                            }
                        </>
                    }

                    <Button variant="contained"
                        sx={{
                            width: 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none",
                            '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                        }}
                        onClick={(e) => handleUploadSignature(e)}
                    >
                        UPLOAD
                    </Button>
                </Grid>
            </Grid>

            {openSigEditor &&
                <EditSignatoryCropperDialog breakpoints={breakpoints}
                    openSigEditor={openSigEditor} setOpenSigEditor={setOpenSigEditor}
                    signatureFile={signatureFile} setSignatureFile={setSignatureFile}
                    signatoryName={signatoryName} isSignatoryDefault={isSignatoryDefault}
                    url={url} documentId={documentId} signatoryPosition={signatoryPosition}
                />
            }
        </Box>
    )
}

export default EditSignatoryByUploadComponent