import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost, HttpPut} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_service.subscriptionKey;

 
const retrieveConfigEID = () => {

    let query_params = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.RETRIEVE_CONFIG_EID, query_params, subscriptionKey);

    return result;

}


const updateConfigEID = (payload) => {

    console.log("update config payload :", payload);

    let result = HttpPut(routes.UPDATE_CONFIG_EID, payload);

    return result;
}



const EIDConfigService = {
    retrieveConfigEID,
    updateConfigEID
}

export default EIDConfigService;