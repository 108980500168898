import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import RecordsComponent from "./records.component";



function useRecordsTab(props) {

  const [records_tab, setRecordsTab] = useState(null);

  function setupTabs() {

    var tabs_default = [
      { name: 'All', component: <RecordsComponent chosenTab={""} /> },
      { name: 'New/Pending', component: <RecordsComponent chosenTab={"NEW"} /> },
      { name: 'Pending for Approval', component: <RecordsComponent chosenTab={"PENDING FOR APPROVAL"} /> },
      { name: 'Approved', component: <RecordsComponent chosenTab={"APPROVE"} /> },
      { name: 'Authentication', component: <RecordsComponent chosenTab={"AUTHENTICATION"} /> },
      { name: 'Completed', component: <RecordsComponent chosenTab={"COMPLETED"} /> },
      { name: 'Rejected', component: <RecordsComponent chosenTab={"REJECTED"} /> }
    ]

    setRecordsTab(tabs_default);
  }

  useEffect(() => { setupTabs(); }, [])

  return records_tab;

}


function RecordsTabComponent(props) {

  const { breakpoints } = props;

  const tabs = useRecordsTab();

  const [value, setValue] = useState(0);

  function TabPanel(props) {

    const { children, value, index, ...other } = props;


    return (
      <div
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Box sx={{ width: '100%', padding: breakpoints == 'xs' || breakpoints == 'sm' ? 0 : 3, pt: 1 }}>
      <Box>
        <CustomTabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ marginTop: 2 }}
          indicatorColor="transparent"
          centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })
          }
        </CustomTabs>
        {tabs?.map((tab, index) => {
          return (
            <TabPanel value={value} index={index} style={{ padding: "0px" }}>
              {tab.component}
            </TabPanel>
          )
        })
        }
      </Box>
    </Box>
  )
}

export default RecordsTabComponent;