import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useEID from "../../hooks/useEID";
import { useEffect } from "react";
import {ThreeDots} from 'react-loader-spinner';


const DeleteEIDDialog = (props) => {

    const {openDelete, handleCloseDelete, record, setLoading, setIsDeleted} = props;

    const { isDeleting, isDeleted, deleteEID } = useEID();

    const handleCloseDeleteDialog = (e) => {
        handleCloseDelete();
    }

    const handleDeleteEID = () => {
        deleteEID(record?.uuid);
        setIsDeleted(true);
    };
      

    // useEffect(() => {
    //     if (isDeleted) {
    //         handleCloseDeleteDialog();
    //         // window.location.reload();
    //         setLoading(true);
    //         setTimeout(() => {
    //             setLoading(false);
    //         }, 10000); // 10 seconds in milliseconds
    //     }
    // }, [isDeleted])

    useEffect(() => {
        if (isDeleted) {
            handleCloseDeleteDialog();
            setLoading(false);
        }
    }, [isDeleted, setLoading]);
      


    return(
        <Dialog open={openDelete} onClose={handleCloseDeleteDialog} handleCloseDelete={handleCloseDelete} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Delete eID Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography>
                    Are you sure you want to delete <b>{record?.firstname}&nbsp;{record?.lastname}&nbsp;{record?.suffix}</b> ?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, textTransform: "none", borderColor: "#3333cf", color: "#3333cf", '&:hover': {borderColor: "#3333cf", color: "#3333cf"}}} 
                    onClick={(e) => handleCloseDeleteDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: "none", width: 'auto', backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none',
                    '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => handleDeleteEID(e)}
                    startIcon={isDeleting && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {isDeleting ? "Deleting" : "Delete"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteEIDDialog