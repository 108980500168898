import React, { useEffect, useState } from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useEID from "../../hooks/useEID";
import {ThreeDots} from 'react-loader-spinner';

const RevokeApplicationDialog = (props) => {

    const {breakpoints, openRevokeApplication, setOpenRevokeApplication, revoked, activated, record} = props;

    const { revokeSuspendApplication, isUpdatingStatus, updated } = useEID();

    const [status , setStatus] = useState("");

    const handleCloseDialog = () => {
        setOpenRevokeApplication(false);
    }

    useEffect(() => {
        if(revoked){
            setStatus("Revoked");
        }else if(activated){
            setStatus("Completed");
        }else{
            setStatus("Suspended");
        }
    },[])

    const handleUpdateStatus = () => {

        let payload = {
            uuid: record?.uuid,
            status: status ,
        }

        console.log("payloadxxx", payload);

        revokeSuspendApplication(payload);     
    }

    useEffect(() => {
        if (updated) {
            handleCloseDialog();
            window.location.reload();
        }
    }, [updated])

    return (
        <Dialog open={openRevokeApplication} onClose={handleCloseDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                {revoked ? 'Revoke Application' : activated ? 'Activate Application' : 'Suspend Application' }
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? 3 : 6, pt: 2}}>
                <Typography sx={{textAlign:'center'}}>
                    You’re about to {revoked ? 'revoke' : activated ? 'activate' : 'suspend'} this eID application.
                    Please confirm.
                </Typography>
            </DialogContent>
            
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button autoFocus variant="outlined" onClick={(e) => handleCloseDialog()} sx={{borderRadius: 10, color: "#3333cf", borderColor: "#3333cf", textTransform: "none", fontSize: 14, "&:hover": {backgroundColor: "#3333cf", color: "#fff"}}}>
                    Cancel
                </Button>

                <Button variant="contained" autoFocus sx={{backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, "&:hover": {backgroundColor: "#b5b5b5", color: "white"}}} 
                    onClick={(e) => handleUpdateStatus(e)}
                    startIcon={isUpdatingStatus && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isUpdatingStatus && revoked) ? "Revoking" : (isUpdatingStatus && activated) ? "Activating" : (isUpdatingStatus && !revoked && !activated) ? "Suspending" : "Confirm" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RevokeApplicationDialog