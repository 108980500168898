import {Box, Button, Toolbar, CssBaseline, IconButton} from "@mui/material"
import useWidth from "../hooks/useWidth";
import { useState } from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import NEIDLogo from "../icons/nextix.png";
import PDFViewerComponent from "../components/csv/pdf-viewer.component";
import CloseIcon from "@mui/icons-material/Close";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));


const PDFViewerScreen = () => {

    const breakpoints = useWidth();

    const [open] = useState(true);

    const handleCloseWindow = (e) => {
        window.close();
    }

    const handleLogoClick = (e) => {
        window.location.href="/";
        localStorage.removeItem("eidPDFBase64Data");
    }

    return(

        <Box sx={{width: "70%", margin: "auto"}}>
            <CssBaseline />

            <AppBar position="fixed" open={open} sx={{backgroundColor: "#fff", height: 64, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)'}}>
                <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {},
                    paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}
                >
                    <Button variant="text" disableElevation sx={{textTransform: "none", cursor: "pointer", 
                        ...((breakpoints == 'xs' || breakpoints == 'sm') && {display: "visible"}), 
                        color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                    >
                        <img alt="" src={NEIDLogo} style={{height: 35}} 
                            onClick={(e) => handleLogoClick(e)}
                        />
                    </Button>

                    <Box sx={{flexGrow: 1}} />

                    <IconButton onClick={(e) => handleCloseWindow(e)}>
                        <CloseIcon />
                    </IconButton> 
                </Toolbar>
            </AppBar>

            <Box sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? "auto" : "0px 50px 0px 60px", mt: "70px"}}>
                <Box>
                    <PDFViewerComponent breakpoints={breakpoints} />
                </Box>
            </Box>
        </Box>
    )
}

export default PDFViewerScreen