import React, {useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import {useSnackbar} from "notistack";
import CSVIcon from "../../icons/csv-blue.png";
import AddCSVIcon from "../../icons/add-csv-blue.png";
import useCSV from "../../hooks/useCSV";
import {ThreeDots} from "react-loader-spinner";

const UploadCSVComponent = (props) => {

    const {breakpoints, isNoAvailableAgency} = props;

    const {enqueueSnackbar} = useSnackbar();
    const {isUploadingCSV, uploadCSV} = useCSV();

    const [csvFile, setCSVFile] = useState(null);
    const [filename, setFilename] = useState("");

    const handleChange = (file) => {
        const fileName = file.name;
        const fileExt = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();
        const acceptedExtensions = ["csv"];
      
        if (acceptedExtensions.includes(fileExt)) {
            setFilename(fileName);
            setCSVFile(file);

        } else {
            setFilename("");
            enqueueSnackbar("File type not supported", {
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }
      
    const handleClearFile = () => {
        setCSVFile(null);
        setFilename("");
    }

    const handleUploadCSV = (e) => {
        if (csvFile) {
            let formData = new FormData();
              
            formData.append("file", csvFile);
            uploadCSV(formData);
        }
    }


    return (
        <>
            <Grid item xs={12} sx={{width: "93%", margin: "auto"}}>
                <FileUploader
                    fileOrFiles={csvFile}
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    label="Select a CSV file to upload or drag and drop it here"
                    children={
                        <>
                            {(csvFile) ? 
                                <div 
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 3.2px #3333cf",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "330px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear"
                                    }} 
                                >
                                    <Grid style={{display: "block"}}>
                                        <Grid sx={{textAlign: "center"}}>
                                            <img alt="" src={CSVIcon} style={{width: 55, height: 55, objectFit: "contain"}} />
                                        </Grid>
                                    
                                        <Typography variant="subtitle2" sx={{color: "#3333cf", fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 14}}>
                                            {filename}
                                        </Typography>
                                    </Grid>
                                </div>
                            :
                                <div
                                    style={{
                                        position: "relative",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100% !important",
                                        maxWidth: "100% !important",
                                        border: "dashed 3.2px #3333cf",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                        flexGrow: 0,
                                        height: "330px",
                                        justifyContent: "center",
                                        margin: "auto",
                                        transition: "border-color .15s linear",
                                    }}
                                >
                                    <Grid>
                                        <Grid sx={{textAlign: "center"}}>
                                            <img alt="" src={AddCSVIcon} style={{width: 50, height: 50, objectFit: "contain"}} />
                                        </Grid>

                                        <Typography variant="subtitle1" style={{color: "#3333cf", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 16}}>
                                            Select a CSV file to upload
                                        </Typography>

                                        <Typography variant="subtitle2" style={{textAlign: "center", color: "rgb(0 0 0 / 33%)", fontWeight: 500, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? 12 : 14}}>
                                            or drag and drop it here
                                        </Typography>
                                    </Grid>
                                </div>
                            }
                        </>
                    }
                />
            </Grid>

            <Grid item xs={12} sm={12} sx={{textAlign: "end", mt: 5, mr: (breakpoints == "xs" || breakpoints == "sm") ? "15px" : "35px"}}>
                {csvFile &&
                    <>
                        <Button variant="outlined" 
                            sx={{borderColor: "#3333cf", width: (breakpoints == "xs" || breakpoints == "sm") ? {} : 120, color: "#3333cf", borderRadius: 10, boxShadow: "none",
                                '&:hover': {color: "#3333cf", borderColor: "#3333cf"}
                            }}
                            onClick={(e) => handleClearFile(e)}
                        >
                            CLEAR
                        </Button> 

                        &nbsp; &nbsp;
                    </>
                }

                <Button variant="contained" 
                    sx={{
                        width: ((breakpoints == "xs" || breakpoints == "sm") || isUploadingCSV) ? {} : 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none",
                        '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                    }}
                    startIcon={isUploadingCSV && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    onClick={(e) => (isNoAvailableAgency) ? null : handleUploadCSV(e)}
                >
                    {(isUploadingCSV) ? "UPLOADING" : "UPLOAD"}
                </Button>
            </Grid>
        </>
    )
}

export default UploadCSVComponent