
import {Box, Grid, Card, CardContent, Typography, TextField, Button, FormControl, Select, MenuItem, CardActions} from "@mui/material";
import {useEffect, useState } from "react";
import {useNavigate} from "react-router";
import useConfig from "../../hooks/useConfig";
import {ThreeDots} from 'react-loader-spinner';
import ConfigSkeletonComponent from "../skeletons/config-skeleton-loader.component";
import useWidth from "../../hooks/useWidth";


const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
          borderColor: "#3333cf4f",
        },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3333cf4f",
                borderWidth: "thin",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#3333cf4f",
                borderWidth: "thin",
            },
        };

function Header () {
    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        Update Config eID
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const UpdateConfigEIDComponent = () => {

    const navigate = useNavigate();

    const breakpoints = useWidth();

    const { loading, retrieveConfig, config, updateConfig, updatingConfig, updatedConfig } = useConfig();

    const [EIDname, setEIDName] = useState(config?.name);
    const [EIDid_type, setEIDIdType] = useState(config?.id_type);
    const [EIDexpiry_duration, setEIDExpirationDuration] = useState(config?.expiry_duration);
    const [EIDexpiry_unit, setEIDExpiryUnit] = useState(config?.expiry_unit);

    const handleNameChange = (e) => {
        setEIDName(e.target.value);
    }

    const handleIdTypeChange = (e) => {
        setEIDIdType(e.target.value);
    }

    const handleExpirationDurationChange = (e) => {
        const value = parseInt(e.target.value);
        if (!isNaN(value)) {
            setEIDExpirationDuration(value);
        }
    }

    const handleChangeExpiryUnit = (e) => {
        setEIDExpiryUnit(e.target.value);
    }


    useEffect(() => { 
        retrieveConfig();

        if (config) {
            setEIDName(config?.name);
            setEIDIdType(config?.id_type);
            setEIDExpirationDuration(config?.expiry_duration);
            setEIDExpiryUnit(config?.expiry_unit);
        }
        
    }, [JSON.stringify(config)])


    const handleUpdateConfig = () => {
        let payload = {
            name: EIDname,
            expiry_duration: EIDexpiry_duration,
            expiry_unit: EIDexpiry_unit
        }

        console.log("payloadxxx", payload);

        updateConfig(payload);     
    } 

    useEffect(() => {
        if (updatedConfig) {
            window.location.href="/config-eid";
        }
    }, [updatedConfig])

    const expiry_units = [
        {name: 'Day(s)' , value: 'd'},
        {name: 'Month(s)' , value: 'm'},
        {name: 'Year(s)' , value: 'y'}
    ]


    return (

        <Box sx={{overflow: "hidden", position: 'relative', width: breakpoints == 'xs' || breakpoints == 's' ? '100%' : '95%', margin:'auto'}}>

            <Header />

            <Box style={{flexGrow: 1, margin:'auto'}}>
                <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        {loading ?
                            <ConfigSkeletonComponent />
                        :
                            <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 'sm' ? "auto" : 400 }}>
                                <CardContent>
                                    <Grid container direction="row" sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                        <Grid item xs={12} sx={{mt: 4}}>
                                            <Grid container spacing={1} sx={{alignItems: 'center'}}>

                                                <Grid item xs={12} sm={3}>
                                                    <Typography sx={{fontWeight: 600}}>
                                                        Name:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <TextField
                                                        fullWidth 
                                                        variant="outlined" 
                                                        value={EIDname}
                                                        multiline 
                                                        autoComplete="off"
                                                        onChange={handleNameChange}
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                            },
                                                            fontSize: 16
                                                        }}
                                                    />
                                                </Grid> 
                                                        
                                                <Grid item xs={12} sm={3}>
                                                    <Typography sx={{fontWeight: 600}}>
                                                        ID Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <TextField 
                                                        fullWidth 
                                                        variant="outlined" 
                                                        value={EIDid_type}
                                                        multiline
                                                        autoComplete="off" 
                                                        InputProps={{
                                                            readOnly: true,
                                                            disableUnderline: true,
                                                        }}
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                            },
                                                            fontSize: 16
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={3}>
                                                    <Typography sx={{fontWeight: 600}}>
                                                        Expiry Duration:
                                                    </Typography>
                                                </Grid> 
                                                <Grid item xs={12} sm={9}>
                                                    <TextField 
                                                        fullWidth 
                                                        variant="outlined" 
                                                        defaultValue={EIDexpiry_duration}
                                                        multiline 
                                                        autoComplete="off" 
                                                        type="number"
                                                        onChange={handleExpirationDurationChange}
                                                        sx={{
                                                            "& .MuiOutlinedInput-root": {
                                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                            },
                                                            fontSize: 16
                                                        }}
                                                    />
                                                </Grid> 


                                                <Grid item xs={12} sm={3}>
                                                    <Typography sx={{fontWeight: 600}}>
                                                        Expiry Unit:
                                                    </Typography>
                                                </Grid> 
                                                <Grid item xs={12} sm={9}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={EIDexpiry_unit}
                                                            onChange={handleChangeExpiryUnit}
                                                            sx={selectFieldStyles}
                                                        >
                                                            {expiry_units.map((expiry) => (
                                                                <MenuItem key={expiry.value} value={expiry.value}>
                                                                    {expiry.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid> 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{justifyContent: "end", marginRight: (breakpoints == "xs") ? {} : "32px"}}>
                                    <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, boxShadow: "none", borderColor: "#3333cf", color : "#3333cf", "&:hover": {color: "#3333cf", borderColor: "#3333cf"}}}
                                    onClick={() => navigate(-1)}>
                                        CANCEL
                                    </Button>
                                    <Button variant="contained" sx={{width: 'auto', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', ":hover": {backgroundColor: '#b5b5b5' } }}
                                        onClick={(e) => handleUpdateConfig(e)}
                                        startIcon={updatingConfig && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                                    >  
                                     {updatingConfig ? "Updating" : "Update"}
                                    </Button>
                                </CardActions>
                            </Card>  
                        }
                    </Grid>
                </Grid>
            </Box>

        </Box>
        
    )
}

export default UpdateConfigEIDComponent