
import {Grid, Card, CardContent, Typography, IconButton, TextField, Button} from "@mui/material";
import {Link} from "react-router-dom";
import EditIcon from "../../icons/updateHighlight.png";

const ViewAgencyInformationComponent = (props) => {

    const {breakpoints, agencyInformation} = props;

    const agency_information = [
        {label: 'Agency Name', value: agencyInformation?.name},
        {label: 'Description', value: agencyInformation?.description},
        {label: 'Website', value: agencyInformation?.website},
    ]




    return (
      
          
        <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 'sm' ? 'auto' : 380 }}>
            <CardContent >
                <Grid container sx={{textAlign:'center', margin: 'auto', justifyContent: 'center', alignSelf: 'center'}}>
                    <Grid item xs={12} sm={12} sx={{textAlign: 'end'}}>
                        <Button variant="contained" sx={{width: 'auto', backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                            component={Link} to='/update-agency-information'>
                            Edit
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12} sx={{margin: 'auto'}} >
                        <Grid container sx={{justifyContent: 'center', alignSelf: 'center'}}>

                            <img alt="" src={agencyInformation?.logo} style={{height: 70}} />
                                
                            <Grid item xs={12} sm={12} sx={{textAlign: 'start !important', pt: 4}}>
                                {agency_information.map((agency) => {
                                    return (
                                        <Grid container spacing={2} sx={{marginBottom: 2, alignItems:'center'}}>
                                            <Grid item xs={12} sm={4} >
                                                <Typography sx={{fontWeight: 600}}>
                                                    {agency.label}:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={8}>
                                                <TextField 
                                                    variant="outlined"
                                                    value={agency.value}  
                                                    placeholder="N/A" 
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16,
                                                        width: "100%", 
                                                        textAlign: "justify"
                                                    }}
                                                    InputProps={{
                                                        readOnly: true,
                                                        disableUnderline: true,
                                                        style: {fontSize: 14, padding: 1    }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid> 
                                    )
                                })}
                                </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>  
        
       
    )
}

export default ViewAgencyInformationComponent