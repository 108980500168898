import {Box, Button, Grid, IconButton, TextField, Tooltip, Typography} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import RegenerateLinkDialog from "../dialogs/regenerate-link-dialog";
import {format} from 'date-fns';
import copy from "copy-to-clipboard"; 
import {useSnackbar} from "notistack";
import ResetRegistrationLinkDialog from "../dialogs/reset-pre-registratin-link-dialog";
import {ThreeDots} from 'react-loader-spinner';
import useWidth from "../../hooks/useWidth";

function RegistrationLinkComponent (props) {

    const { data , loading} = props;

    const breakpoints = useWidth();
    const {enqueueSnackbar} = useSnackbar();

    const [openRegenerate, setOpenRegenerate] = useState(false);
    const [openReset, setOpenReset] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenRegenerate = () => {
        setOpenRegenerate(true);
    };

    const handleCloseRegenerate = () => {
        setOpenRegenerate(false);
    };

    const handleOpenReset = () => {
        setOpenReset(true);
    };

    const handleCloseReset = () => {
        setOpenReset(false);
    };

    const copyToClipboard = (copyText) => {
        copy(copyText);

        enqueueSnackbar("Successfully copied registration link to clipboard", { 
            variant: "success",
            autoHideDuration: 3000
        });
    }

    const downloadImage = () => {

        setIsLoading(true);

        const imageUrl = data?.qrcode; 
        fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pre-registration-link.jpg');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setIsLoading(false);
        });
    };

    return(
        
        <Box sx={{p: breakpoints == 'xs' || breakpoints == 's' ? 1 : 4, margin: 'auto'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}  sx={{alignSelf: "center"}}>
                    {!loading && data ?
                        <Grid item xs={12} sx={{alignSelf: 'center', textAlign: 'start'}}></Grid>
                    :
                        <Grid item xs={12} sx={{alignSelf: 'center', textAlign: 'start'}}>
                            <Typography sx={{color: 'orange', fontSize: 16, fontStyle: 'italic'}} >
                                No pre-registration link found. To generate a new registration link, please click the 'Regenerate' button.
                            </Typography>
                        </Grid>
                    }

                    <br/>

                    <Grid container>
                        <Grid item xs={11} sx={{alignSelf: 'center', textAlign: 'start'}}>
                            <Grid container>
                                <Grid item xs={12} sm={6} sx={{alignSelf: 'center', textAlign: 'start'}}>
                                    <Typography >
                                        Registration Link
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} sx={{alignSelf: 'center', textAlign: breakpoints == 'xs' || breakpoints == 's' ? 'start' : 'end'}}>
                                    <Button sx={{textTransform: "none", fontSize: 14, color: '#3333cf'}}
                                    onClick={(e) => handleOpenRegenerate(e)} >
                                        Regenerate
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11} sm={11} sx={{alignSelf: 'center', textAlign: 'start'}}>
                            {!loading && data ?
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={data?.link}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                        },
                                        fontSize: 16
                                    }} 
                                />
                            :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                        },
                                        fontSize: 16
                                    }}  
                                />
                            }
                        </Grid>
                        <Grid item xs={1} sm={1} sx={{alignSelf: 'center', justifyContent: 'center', alignItems: 'center'}}>
                            {!loading && data &&
                                <Tooltip title="Copy" placement="top" arrow>
                                    <IconButton sx={{textTransform: "none"}}
                                    onClick={(e) => copyToClipboard(data?.link)}>
                                        <ContentCopyIcon sx={{width: 32, height: 32, color: "#3333cf"}} />
                                    </IconButton>
                                </Tooltip> 
                            }
                        </Grid>
                    </Grid>
                    
                </Grid>

                <Grid item xs={12}  sx={{alignSelf: "center"}}>
                    <Grid container>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            {!loading && data &&
                                <img alt="" src={data?.qrcode} style={{height: 150 , width: 150}} />
                            }
                        </Grid>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            {!loading && data &&
                                <Button sx={{textTransform: "none", fontSize: 14, paddingLeft: 0, color: '#3333cf'}}
                                    onClick={(e) => downloadImage(e)}
                                    startIcon={isLoading && <ThreeDots height="20" width="20" radius="9" color="#3333cf" ariaLabel="three-dots-loading" visible={true} />}
                                    >
                                        {isLoading ? "Downloading" : "Download"}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}  sx={{alignSelf: "center"}}>
                    <Grid container>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            Expiration 
                        </Grid>
                        <Grid item xs={11}  sx={{alignSelf: "center"}}>
                            {!loading && data ?
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    value={format(new Date(data?.expiration), 'PPpp')}
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                        },
                                        fontSize: 16
                                    }} 
                                />
                            :
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    autoComplete="off"
                                    InputProps={{
                                        readOnly: true,
                                        disableUnderline: true,
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                        },
                                        fontSize: 16
                                    }}  
                                />
                            }
                        </Grid>
                    </Grid>
                </Grid>


                 <Grid item xs={12}  sx={{alignSelf: "center", textAlign: 'end', justifyContent: 'flex-end'}}>
                    {!loading && data &&
                        <Button variant="contained" 
                            sx={{backgroundColor: 'red', textTransform: 'none', borderRadius: 10, boxShadow: 'none', fontWeigth: 550,  ":hover": {backgroundColor: '#b5b5b5' }}}
                            onClick={(e) => handleOpenReset(e)}
                        >
                            RESET
                        </Button>
                    }
                </Grid>

            </Grid>  

            {openRegenerate &&
                <RegenerateLinkDialog openRegenerate={openRegenerate} handleClose={handleCloseRegenerate} handleCloseRegenerate={handleCloseRegenerate} />
            }          

            {openReset &&
                <ResetRegistrationLinkDialog openReset={openReset} handleClose={handleCloseReset} handleCloseReset={handleCloseReset} />
            }

        </Box>


    )
}

export default RegistrationLinkComponent