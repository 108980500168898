import {useState} from "react";
import {useSnackbar} from "notistack";
import PreRegistrationService from "../services/api/pre-registration";

const usePreRegistration = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [registration, setRegistration] = useState("");
    const [generating, setGenerating] = useState(false);
    const [reseting, setReseting] = useState(false);
    const [reseted, setReset] = useState(false);

    const retrievePreRegistrationLink = () => {
        setLoading(true);

        PreRegistrationService.retrievePreRegistrationLink().then((res) => {
            console.log("retrievePreRegistrationLink data xxxx", res);

            setLoading(false);

            if (res.data.success) {
                setRegistration(res.data.data); 
            }

        }).catch((error) => {
            console.log("retrievePreRegistrationLink error xxxx", error);
            setLoading(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })

    }


    const regenerateRegistrationLink = (expiration_days) => {
        setGenerating(true);

        PreRegistrationService.generateRegistrationLink(expiration_days).then((res) => {
            console.log("regenerateRegistrationLink data xxxx", res);

            setGenerating(false);

            if (res.data.success) {
                enqueueSnackbar("Successfully regenerated registration link", { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                window.location.reload();
            }

        }).catch((error) => {
            console.log("regenerateRegistrationLink error xxxx", error);
            setGenerating(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })

    }


    const resetRegistrationLink = () => {
        setReseting(true);

        PreRegistrationService.resetRegistrationLink().then((res) => {

            console.log("resetRegistrationLink data xxxx", res);


            setReseting(false);

            if (res.data.success){
                setReset(true);
                enqueueSnackbar(res.data.data.message, { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }

        }).catch((error) => {
            console.log("resetRegistrationLink error xxxx", error)
            setReseting(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }
   

    return {
        loading, retrievePreRegistrationLink, registration, regenerateRegistrationLink , generating,
        resetRegistrationLink, reseting, reseted
    }
      
}

export default usePreRegistration