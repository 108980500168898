import React, {useState, useEffect} from "react";
import {styled, useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";
import MuiDrawer from '@mui/material/Drawer';
import {Tooltip, Typography, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, List} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {tooltipClasses} from '@mui/material/Tooltip';
import HomeIcon from "../icons/home-black.png";
import HomeIconHover from "../icons/home-green.png";
import EIDIcon from "../icons/id-black.png";
import EIDIconHover from "../icons/id-green.png";
import logo from "../icons/nextix.png"
import useWidth from "../hooks/useWidth";
import RegLinkIcon from "../icons/hyperlink-black.png";
import RegLinkIconHover from "../icons/hyperlink-green.png";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} arrow classes={{popper: className}} placement="top" />

    ))(({theme}) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#168401",
            color: "#fff",
            boxShadow: theme.shadows[1],
            fontSize: 11,
            fontWeight: "bold",
            padding: 7,
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#168401",
        },
    }
));
  

const Sidebar = (props) => {

    const {open, handleDrawerClose} = props;

    const url = window.location.pathname;

    const breakpoints = useWidth()
    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (url == "/create-record" || url == "/upload-csv" || url == "/csv-records" || url == "/profile" || url == "/view-agency-information" ||
            url == "/update-agency-information" || url == "/config-eid" || url == "/update-config-eid") 
        {
            setSelectedIndex(0);

        } else if (url == "/records") {
            setSelectedIndex(1);

        } else if(url == "/registration-link") {
            setSelectedIndex(2);
        }
    }, [url])
    

    const handleListMenuClick = (e, index) => {
        setSelectedIndex(index);

        let route;
        
        switch (index) {
            case 0:
                route = '/upload-csv';
                break;

            case 1:
                route = '/records';
                break;

            case 2:
                route = '/registration-link';
                break;
        
            default:
                route = '/upload-csv';
        }
        
        navigate(route);
    }


    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <Button variant="text" disableElevation sx={{textTransform: "none", mr: "auto", ...(!open && {display: "none"}),
                    color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                >
                    <img alt="" src={logo} style={{cursor: "default", height: 25}} />
                </Button>

                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ?  null : <MenuIcon sx={{color: "#000000c4"}} />}
                </IconButton>
            </DrawerHeader>

            <List>
                {["Home","eID", "Registration Link"].map((text, index) => (
                    <LightTooltip arrow sx={{display: (open) ? "none" : "visible"}}
                        title={
                            (index == 0) ? "Home" 
                        :
                            (index == 1) ? "eID"
                        :
                            (index == 2) ? "Registration Link"
                        :
                            null
                        }
                    > 
                        <ListItem key={index} disablePadding sx={{display: "block"}}>
                            <ListItemButton sx={{minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5}}
                                selected={
                                    (index == 0) ? selectedIndex === 0 
                                : 
                                    (index == 1) ? selectedIndex === 1
                                :
                                    (index == 2) ? selectedIndex === 2
                                :
                                    null
                                }

                                onClick={(e) => handleListMenuClick(e, index)}
                            >
                                <ListItemIcon sx={{minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center"}}>
                                    {(selectedIndex === 0 && index == 0) ? 
                                        <img alt="" src={HomeIconHover} style={{width: 19, height: 19}} />
                                    : 
                                        (selectedIndex === 1 && index == 1) ?
                                            <img alt="" src={EIDIconHover} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 1 && index == 0 ) ?
                                            <img alt="" src={HomeIcon} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 1 && index == 2 ) ?
                                            <img alt="" src={RegLinkIcon} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 0 && index == 1 ) ?
                                            <img alt="" src={EIDIcon} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 0 && index == 2 ) ?
                                            <img alt="" src={RegLinkIcon} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 2 && index == 2) ?
                                            <img alt="" src={RegLinkIconHover} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 2 && index == 0 ) ?
                                            <img alt="" src={HomeIcon} style={{width: 19, height: 19}} />
                                    :
                                        (selectedIndex === 2 && index == 1 ) ?
                                        <img alt="" src={EIDIcon} style={{width: 19, height: 19}} />
                                    :
                                       null
                                    }
                                </ListItemIcon>

                                <ListItemText sx={{opacity: open ? 1 : 0}} 
                                    primary={
                                        <Typography sx={{fontSize: 16, color: 
                                            (selectedIndex === 0 && index === 0) ? "#168401" 
                                        :
                                            (selectedIndex === 1 && index === 1) ? "#168401"
                                        :
                                            (selectedIndex === 2 && index === 2) ? "#168401"
                                        :
                                            "#171e2a"
                                        }}>
                                            {text}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    </LightTooltip>
                ))}
            </List>
        </Drawer> 
    )
}

export default Sidebar