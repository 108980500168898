import {useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useEID from "../../hooks/useEID";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";

const SendAppBindingDialog = (props) => {

    const {enqueueSnackbar} = useSnackbar();

    const {openAppBinding, handleCloseAppBinding, record, resend , all, isResend} = props;

    const {sendAppBinding, sendingAppBinding, appBinding} = useEID();

    const handleClose = (e) => {
        handleCloseAppBinding();
    }

    const handleSendAppBinding = (e) => {

        let payload ={
            uuid : record?.uuid
        }

        sendAppBinding(payload);
    }

    useEffect(() => {
        if (appBinding) {
            if(resend){
                enqueueSnackbar("Successfully resend App Binding Instruction", { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
                window.location.reload();
            }else{
                enqueueSnackbar("Successfully send App Binding Instruction", { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
                handleClose();
                window.location.reload();
            }
        }
    }, [appBinding])


    return(
        <Dialog open={openAppBinding} onClose={handleClose} handleCloseAppBinding={handleCloseAppBinding} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                {(isResend) ?
                    'Resend App Binding Confirmation'
                :
                    'Send App Binding Confirmation'
                }
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: "20px 24px 32px"}}>
                {all ?
                    <Typography>
                        Are you sure you want to send app binding to <b>all</b>?
                    </Typography>
                :
                    (isResend) ?
                    <Typography>
                        Are you sure you want to resend app binding to <b>{record?.firstname}&nbsp;{record?.lastname}&nbsp;{record?.suffix}</b>?
                    </Typography>
                :
                    <Typography>
                        Are you sure you want to send app binding to <b>{record?.firstname}&nbsp;{record?.lastname}&nbsp;{record?.suffix}</b>?
                    </Typography>
                }
                
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{textTransform: "none", width: "auto", borderRadius: 10, color: "#3333cf", borderColor: "#3333cf", '&:hover': {borderColor: "#3333cf", color: "#3333cf"}}} 
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                {(isResend) ?
                    <Button variant="contained" sx={{textTransform: "none", width: "auto", backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none','&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                        onClick={(e) => handleSendAppBinding(e)}
                        startIcon={sendingAppBinding && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    >
                
                        {(sendingAppBinding) ? "Resending" : "Resend"}
                    </Button>
                :
                    <Button variant="contained" sx={{textTransform: "none", width: "auto", backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none','&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                        onClick={(e) => handleSendAppBinding(e)}
                        startIcon={sendingAppBinding && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                    >
            
                        {(sendingAppBinding) ? "Sending" : "Send"}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default SendAppBindingDialog