import {TableCell, TableRow} from "@mui/material";

const ErrorRecordRowComponent = (props) => {

    const {list} = props;

    return (
        <>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                <TableCell component="th" scope="row" sx={{color: "#3333cf"}}>
                    {list.id_no}
                </TableCell>
                <TableCell>
                    {list.lastname} &nbsp; {list.suffix}
                </TableCell>
                <TableCell>
                    {list.firstname}
                </TableCell>
                <TableCell>
                    {list.middlename}
                </TableCell>
                <TableCell sx={{color: 'red'}}>
                    {list.error_message}
                </TableCell>
            </TableRow>
        </>
    )
}

export default ErrorRecordRowComponent