import React from "react";
import { Menu, MenuItem, Typography } from '@mui/material';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ProfileIcon from "../icons/profile1.png";
import AgencyIcon from "../icons/contact-book.png";
import SignoutIcon from "../icons/logout.png";
import ConfigIcon from "../icons/settings.png";
import SignatoryIcon from "../icons/signatory.png";
import AuthService from "../services/auth";

const AccountPopper = (props) => {

    const { anchorEl, handleCloseMenu } = props;

    const navigate = useNavigate();

    const handleProfile = (e) => {
        navigate("/profile");
        handleCloseMenu();
    }

    const handleAgency = () => {
        navigate("/view-agency-information");
        handleCloseMenu();
    }

    const handleConfig = () => {
        navigate("/config-eid");
        handleCloseMenu();
    }

    const handleSignatory = () => {
        navigate("/signatory");
        handleCloseMenu();
    }

    const handleSignOut = () => {
        AuthService.logout().then((res) => {
            window.location.href = "/"
        });

        handleCloseMenu();
    }

    return (
        <Menu sx={{ top: "35px" }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
        >
            <MenuItem onClick={(e) => handleProfile(e)} sx={{ paddingRight: "25px" }}>
                <img alt="" src={ProfileIcon} style={{ width: 18, height: 18, marginLeft: 5 }} />

                <Typography sx={{ ml: "8px", fontSize: 14 }}>
                    Profile
                </Typography>
            </MenuItem>

            <MenuItem onClick={(e) => handleAgency(e)} sx={{ paddingRight: "25px" }}>
                <img alt="" src={AgencyIcon} style={{ width: 18, height: 18, marginLeft: 5 }} />

                <Typography sx={{ ml: "10px", fontSize: 14 }}>
                    Agency
                </Typography>
            </MenuItem>

            <MenuItem onClick={(e) => handleConfig(e)} sx={{ paddingRight: "25px" }}>
                <img alt="" src={ConfigIcon} style={{ width: 18, height: 18, marginLeft: 5 }} />

                <Typography sx={{ ml: "10px", fontSize: 14 }}>
                    Config
                </Typography>
            </MenuItem>

            <MenuItem onClick={(e) => handleSignatory(e)} sx={{ paddingRight: "25px" }}>
                <img alt="" src={SignatoryIcon} style={{ width: 18, height: 18, marginLeft: 5 }} />

                <Typography sx={{ ml: "10px", fontSize: 14 }}>
                    Signatory
                </Typography>
            </MenuItem>

            <MenuItem onClick={handleSignOut} sx={{ paddingRight: "25px" }}>
                <img alt="" src={SignoutIcon} style={{ width: 22, height: 22, marginLeft: 5 }} />

                <Typography sx={{ ml: "5px", fontSize: 14 }}>
                    Sign Out
                </Typography>
            </MenuItem>

        </Menu>
    )
}

export default AccountPopper