import React, {useState, useEffect} from "react";
import useWidth from "../hooks/useWidth";
import {Box, Grid, Typography, Button} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CreateSignatoryComponent from "../components/signatory/create-signatory.component";

const EditSignatoryScreen = () => {

    const url = window.location.pathname;

    const breakpoints = useWidth();

    const [signatoryRecord, setSignatoryRecord] = useState([]);

    const signatoryId = new URLSearchParams(window.location.search).get("id");

    useEffect(() => {
        const storedSignatoryRecord = localStorage.getItem("signatoryRecord");
        const parsedSignatoryRecord = JSON.parse(storedSignatoryRecord);

        setSignatoryRecord(parsedSignatoryRecord);
    }, [])

    useEffect(() => {
        if (!signatoryId) {
            window.location.href = "/";
        }
    }, [])


    const handleClickBack = (e) => {
        window.location.href = "/signatory";
    }


    function Header ({breakpoints}) {
        return (
            <Grid sx={{padding: "10px 20px 0px 20px", width: "100%", borderRadius: 0}}>
                <Button onClick={(e) => handleClickBack(e)} startIcon={
                    <ArrowBackIosIcon style={{width: 20, height: 20, color: "#3333cf"}} />} 
                        style={{color: "black", fontSize: 16, textTransform: "none", fontWeight: 600}}
                    >

                    <Typography variant="h6" sx={{fontWeight: "bold", textAlign: "start", fontSize: 16}}>
                        Edit Signatory
                    </Typography>
                </Button>
            </Grid>
        )
    }

    return (
        <Box sx={{overflow: "hidden", width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "95%", margin: "auto"}}>
            <Header breakpoints={breakpoints} />

            <CreateSignatoryComponent url={url} breakpoints={breakpoints} signatoryRecord={signatoryRecord} />
        </Box>
    ) 
}

export default EditSignatoryScreen