import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import {ThreeDots} from 'react-loader-spinner';

function ApproveApplicationDialog(props){

    const {breakpoints, openApproveApplication, setOpenApproveApplication, approveEID, loadingApprovalEID, eid} = props;

    const handleCloseDialog = () => {
        setOpenApproveApplication(false);
    }

    const handleApproveEID = () => {

        approveEID(eid?.uuid, setOpenApproveApplication)
        
    }


    return(
        <Dialog open={openApproveApplication} onClose={handleCloseDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold"}}>
                Approve Application
            </DialogTitle>
            <Divider/>

            <DialogContent sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 6, paddingTop: 2}}>
                <Typography sx={{textAlign:'center'}}>
                    You’re about to approve this eID application.
                    Please Confirm.
                </Typography>
            </DialogContent>
            
            
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button variant="outlined" onClick={handleCloseDialog} 
                    sx={{borderRadius: 10, borderColor: "#3333cf", color: "#3333cf", textTransform: "none", fontSize: 14, '&:hover': {borderColor: "#3333cf", color: "#3333cf"}}}
                >
                    Cancel
                </Button>

                <Button onClick={handleApproveEID} variant="contained" autoFocus sx={{backgroundColor: "#168401", borderRadius: 10, textTransform: "none", fontSize: 14, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}} 
                startIcon={loadingApprovalEID && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {loadingApprovalEID ? "Approving" : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ApproveApplicationDialog