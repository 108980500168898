import {useState} from "react";
import {useSnackbar} from "notistack";
import EIDAgencyService from "../services/api/agency";

const useAgency = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [agencyInformation, setAgencyInformation] = useState(null);
    const [updatingAgencyInfo, setUpdatingAgencyInfo] = useState(false);
    const [agencyInfoUpdated, setAgencyInfoUpdated] = useState(false);
    const [isCreatingAgency, setCreatingAgency] = useState(false);

    const retrieveAgencyInformation = () => {
        setLoading(true);

        EIDAgencyService.retrieveAgencyInformtaion().then((res) => {
            console.log("retrieveAgencyInformation data xxxx", res);

            if (res.data.success) {
                setAgencyInformation(res.data.data);
                setLoading(false);
            }

        }).catch((error) => {
            console.log("retrieveAgencyInformation error xxxx", error);
            setLoading(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }

    const updateAgencyInformation = (logo, name, description, website) => {
        setUpdatingAgencyInfo(true);
            
        EIDAgencyService.updateAgencyInformation(logo, name, description, website).then((res) => {
    
            console.log("updateAgencyInformation data xxxx", res);
    
            setUpdatingAgencyInfo(false);
    
            if(res.data.success){
                setAgencyInfoUpdated(true);
                enqueueSnackbar('Successfully updated agency information', { 
                    variant: 'success',
                    autoHideDuration: 3000
                });
            }
    
        }).catch((error) => {
            console.log("updateAgencyInformation error xxxx", error);
            setUpdatingAgencyInfo(false);
            if(error.response.data.error_message){
                enqueueSnackbar(error.response.data.error_message, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, { 
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
            
        })
    }

    const createAgency = (formData) => {
        setCreatingAgency(true);

        EIDAgencyService.createAgency(formData).then((res) => {
            console.log("createAgency data xxxx", res);
    
            if (res.data.success) {
                setCreatingAgency(false);
                enqueueSnackbar("Successfully created agency", { 
                    variant: 'success',
                    autoHideDuration: 3000
                });

                window.location.href = "/view-agency-information"
            }
    
        }).catch((error) => {
            console.log("createAgency error xxxx", error);
            setCreatingAgency(false);

            enqueueSnackbar(error.response.data.error_message, { 
                variant: "error",
                autoHideDuration: 3000
            });
        })
    }


    return {
        loading, agencyInformation, updatingAgencyInfo, agencyInfoUpdated, isCreatingAgency,
        retrieveAgencyInformation, updateAgencyInformation, createAgency
    }
      
}

export default useAgency