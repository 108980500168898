import {TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import {useState} from "react";
import {ThreeDots} from "react-loader-spinner";

const RejectApplicationDialog = (props) => {

    const {breakpoints, openRejectApplication, setOpenRejectApplication, disapproveEID, eid, loadingDisapproveEID} = props;
    
    const [rejectReason, setRejectReason] = useState("");
    const [isReasonRequired, setReasonRequired] = useState(false);

    const handleCloseDialog = () => {
        setOpenRejectApplication(false);
    }

    const handleDisapproveEID = () => {
        if (rejectReason) {
            let payload = {
                uuid: eid?.uuid,
                reject_reason: rejectReason,
            }

            disapproveEID(payload);

        } else {
            setReasonRequired(true);
        }
    }

    const handleChangeDisapproveReason = (event) => {
        setRejectReason(event.target.value);
        setReasonRequired(false);
    }

    return(
        <Dialog open={openRejectApplication} onClose={handleCloseDialog} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle sx={{fontWeight: "bold"}}>
                Reject Application
            </DialogTitle>
            <Divider/>

            <DialogContent sx={{padding: (breakpoints == 'xs' || breakpoints == 'sm') ? 0 : 1, paddingTop: 2, margin: (breakpoints == 'xs' || breakpoints == 'sm') ? 2 : 5}}>
                <Typography sx={{textAlign:'center'}}>
                    You're about to reject this eID application. Please provide reason and confirm.
                </Typography>

                <br/>

                <TextField 
                    multiline 
                    fullWidth 
                    placeholder="Reason" 
                    id="fullWidth" 
                    onChange={handleChangeDisapproveReason} 
                    value={rejectReason}
                    sx={{fontSize: 16,
                        "& .MuiInputLabel-root": {color: '#1f4380'},
                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": {borderColor: "#3333cf4f !important"}
                        }
                    }}
                    helperText={
                        <p style={{fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 12 : 14, color: "#bf5a4c", margin: "2px 0px 0px -10px"}}>
                            {(isReasonRequired) ? "Reject reason is required" : ""}
                        </p>
                    }
                />
            </DialogContent>
            
            
            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button variant="outlined" onClick={(e) => handleCloseDialog()}
                    sx={{borderRadius: 10, borderColor: "#3333cf", color: "#3333cf", textTransform: "none", '&:hover': {borderColor: "#3333cf", color: "#3333cf"}}}
                >
                    Cancel
                </Button>

                <Button onClick={handleDisapproveEID} variant="contained" autoFocus sx={{backgroundColor: "#168401", borderRadius: 10, textTransform: "none",'&:hover': {backgroundColor: "#b5b5b5", color: "white"}}} 
                    startIcon={loadingDisapproveEID && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {loadingDisapproveEID ? "Rejecting" : "Confirm"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RejectApplicationDialog