
import {Grid, Card, CardContent, Typography, TextField, Box,CardActions, Button} from "@mui/material";
import { useEffect, useState } from "react";
import {useNavigate } from "react-router";
import useWidth from "../../hooks/useWidth";
import useAgency from "../../hooks/useAgency";
import {ThreeDots} from 'react-loader-spinner';
import AgencySkeletonComponent from "../skeletons/agency-skeleton-loader.component";
import {useSnackbar} from "notistack";

function Header () {
    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        Update Agency Information
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

const UpdateAgencyInformationComponent = () => {

    const breakpoints = useWidth();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const { loading, retrieveAgencyInformation, agencyInformation, updatingAgencyInfo, agencyInfoUpdated, updateAgencyInformation } = useAgency();

    // State
    const [agencyName, setAgencyName] = useState(agencyInformation?.name);
    const [description, setDescription] = useState(agencyInformation?.description);
    const [website, setWebsite] = useState(agencyInformation?.website);
    const [logoPreview, setLogoPreview] = useState(null);
    const [isChangingLogo, setChangingLogo] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [isAgencyNameRequired, setAgencyNameRequired] = useState(false);
    const [isDescriptionRequired, setDescriptionRequired] = useState(false);

    const handleNameChange = (e) => {
        setAgencyName(e.target.value);
        setAgencyNameRequired(false);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        setDescriptionRequired(false);  
    }

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    }


    const validateLogo = (e) => {
        var fileName = document.getElementById("upload-logo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            setChangingLogo(true);
            setLogoFile(e.target.files[0]);
            setLogoPreview(URL.createObjectURL(e.target.files[0]));
 
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleClearLogo = () => {
        setLogoPreview(null);
    }


    useEffect(() => {

        if(logoPreview) {
            const fetchData = async () => {
                try {
                    const logoResponse = await fetch(logoPreview);
                    const logoBlob = await logoResponse.blob();
                    const tempLogoFile = new File([logoBlob], 'temp_logo.png', { type: 'image/png' });
                    setLogoFile(tempLogoFile);
                } catch (error) {
                    console.error('Error fetching photo:', error);
                }
            };
        
            fetchData();
        } 

    },[logoPreview])

    const handleUpdateAgencyInfo = (e) => {
        let formData = new FormData();

        formData.append("logo", logoFile);
        formData.append("name", agencyName);
        formData.append("description", description);
        formData.append("website", website);

        if (!agencyName) {
            setAgencyNameRequired(true);

        } else if (!description) {
            setDescriptionRequired(true);

        } else {
            setAgencyNameRequired(false);
            setDescriptionRequired(false);
            
            updateAgencyInformation(formData);
        }
    }

    useEffect(() => { 
        retrieveAgencyInformation(); 

        if (agencyInformation) {
            setAgencyName(agencyInformation?.name);
            setDescription(agencyInformation?.description);
            setWebsite(agencyInformation?.website);
            setLogoPreview(agencyInformation?.logo);
        }
        
    }, [JSON.stringify(agencyInformation)])

    useEffect(() => {
        if (agencyInfoUpdated) {
            window.location.href="/view-agency-information";
        }
    }, [agencyInfoUpdated])




    return (
        <Box style={{flexGrow: 1, width: breakpoints == 'xs' || breakpoints == 'sm' ? '100%' :'95%', margin:'auto'}}>
           
            <Header />

            <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 2}}>
                <Grid item xs={12} sm={12} sx={{pt: 0}}>
                    {loading ?
                        <AgencySkeletonComponent />
                    :
                        <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',  height: breakpoints == 'xs' || breakpoints == 'sm' ? 'auto' : 520}}>
                            <CardContent>

                                <Grid container direction="row" sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                    <Grid item xs={12} sx={{alignSelf: 'center', margin: 'auto', textAlign: 'center'}}>
                                        <Grid container sx={{mb: 1, mt: 1, alignItems: 'center', justifyContent: breakpoints == 'xs' || breakpoints == 'sm' ? 'center' : 'flex-end'}}>
                                            <Grid item xs={12} sm={6} sx={{textAlign: breakpoints == 'xs' || breakpoints == 'sm' ? 'center' : 'end'}}> 
                                                <img alt="" src={logoPreview} style={{height: 80, objectFit: "contain"}} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} >
                                                <Grid container sx={{justifyContent: 'center'}}>
                                                    {isChangingLogo ?
                                                        <>
                                                            <Grid item xs={6} >
                                                                <Button variant="outlined" 
                                                                    sx={{color: "#3333cf", borderColor: "#3333cf", width: 'auto', borderRadius: 10, textTransform: 'none',
                                                                        '&:hover': {color: "#3333cf", borderColor: "#3333cf"}
                                                                    }} 
                                                                onClick={ (e) => [handleClearLogo(e), setChangingLogo(false)]}>
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    :
                                                        <Grid item xs={6} >
                                                            <Button variant="contained" 
                                                                sx={{width: 'auto', backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none',  textTransform: 'none',
                                                                    '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                                                                }}
                                                            >
                                                                <input 
                                                                    type="file" 
                                                                    accept="image/*" 
                                                                    id="upload-logo"
                                                                    onChange= {(e) => validateLogo(e)}
                                                                    hidden
                                                                />

                                                                <label style={{cursor: "pointer"}} htmlFor="upload-logo">
                                                                    Browse Logo 
                                                                </label>
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sx={{mt: 4}}>
                                        <Grid container spacing={2} sx={{marginBottom: 1, alignItems: 'center'}}>
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight: 600}}>
                                                    Agency Name:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    onChange={handleNameChange}  
                                                    autoComplete="off"
                                                    value={agencyName}
                                                    helperText={
                                                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                                            {(isAgencyNameRequired) && "Agency Name is required"}
                                                        </p>
                                                    }
                                                    sx={{"& .MuiOutlinedInput-root": {
                                                            "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                        }
                                                    }}
                                                />
                                            </Grid> 
                                        
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight: 600}}>
                                                    Description:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={8}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline
                                                    rows={3} 
                                                    onChange={handleDescriptionChange} 
                                                    autoComplete="off" 
                                                    value={description}
                                                    helperText={
                                                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                                            {(isDescriptionRequired) && "Description is required"}
                                                        </p>
                                                    }
                                                    sx={{"& .MuiOutlinedInput-root": {
                                                            "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight: 600}}>
                                                    Website:
                                                </Typography>
                                            </Grid> 

                                            <Grid item xs={12} sm={8}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    onChange={handleWebsiteChange}
                                                    autoComplete="off" 
                                                    value={website}
                                                    sx={{"& .MuiOutlinedInput-root": {
                                                            "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                        }   
                                                    }}
                                                />
                                            </Grid> 
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions sx={{justifyContent: "end", marginRight: (breakpoints == "xs") ? {} : "32px"}}>
                                <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, boxShadow: 'none', borderColor: "#3333cf", color : "#3333cf", "&:hover": {color: "#3333cf", borderColor: "#3333cf"}}}
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>

                                <Button variant="contained" sx={{width: 'auto', backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                                    onClick={(e) => handleUpdateAgencyInfo(e)}
                                    startIcon={updatingAgencyInfo && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                                >
                                    {updatingAgencyInfo ? "Updating" : "Update"}
                                </Button>
                            </CardActions>
                        </Card>
                    }
                </Grid>
            </Grid>
        </Box>
             
    )
}

export default UpdateAgencyInformationComponent