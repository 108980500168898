import {Skeleton, Card, CardContent} from "@mui/material";

const ViewProfileSkeletonComponent = (props) => {

    const {breakpoints} = props;

    return (
        <Card sx={{textAlign: "center", padding: (breakpoints == "xs" || breakpoints == "sm") ? 1 : 6, pl: 0, pr: 0, borderRadius: 2, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "95%", margin: "auto"}}>
            <CardContent>
                <Skeleton variant="circular" width={100} height={100} style={{margin: "auto", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            
               <Skeleton variant="text" height={30} width="45%" style={{margin: "auto", marginTop: "8px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
            </CardContent>
        </Card>
    )  
}

export default ViewProfileSkeletonComponent