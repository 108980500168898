import {ClickAwayListener, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper, Typography} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useState } from "react";
import DeleteSignatoryDialog from "../dialogs/delete-signatory-dialog";
import DefaultSignatoryDialog from "../dialogs/default-signatory.dialog";

const SignatoryPopper = (props) => {

    const {record, breakpoints} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openDefaultSignatory, setOpenDefaultSignatory] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleOpenDelete = (e, record) => {
        let isSignatoryDefault = record?.is_default;

        if (isSignatoryDefault == true) {
            setOpenDefaultSignatory(true);

        } else {
            setOpenDelete(true);
        }  
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleClickEdit = (e) => {
        let stringifySignatoryRecord = JSON.stringify(record);

        localStorage.setItem("signatoryRecord", stringifySignatoryRecord);
        window.location.href = "/edit-signatory?id=" + record?.id
    }


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <IconButton sx={{color: 'black'}}>
                <MoreHorizIcon aria-describedby={id} style={{cursor: "pointer"}} onClick={handleClick}  />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl}>
                <Paper style={{borderRadius: 2}}>
                    <ClickAwayListener onClickAway={handleClick}>
                        <MenuList>
                            <MenuItem style={{color: '#0178f2', fontSize: 13, fonWeight: 500}} onClick={(e) => handleClickEdit(e)}>
                                <ListItemIcon style={{padding: 0, minWidth: 30}} onClick={(e) => handleClickEdit(e)}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: '#0178f2'}}>
                                        <EditIcon style={{width: 16, height: 16}} />
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit" onClick={(e) => handleClickEdit(e)}>Edit</Typography>
                            </MenuItem>
                            <MenuItem   style={{color: 'red', fontSize: 13, fonWeight: 500}} onClick={(e) => handleOpenDelete(e, record)}>
                                <ListItemIcon style={{padding: 0, minWidth: 30}}>
                                    <IconButton style={{paddingTop: 6, paddingBottom: 6, color: 'red'}}>
                                        <DeleteIcon style={{width: 16, height: 16}}/>
                                    </IconButton>
                                </ListItemIcon>
                                <Typography variant="inherit">Delete</Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>

            {openDelete &&
                <DeleteSignatoryDialog openDelete={handleOpenDelete} handleClose={handleCloseDelete}
                handleCloseDelete={handleCloseDelete} record={record} />
            }

            {openDefaultSignatory &&
                <DefaultSignatoryDialog breakpoints={breakpoints} record={record}
                    openDefaultSignatory={openDefaultSignatory} setOpenDefaultSignatory={setOpenDefaultSignatory}
                />
            }
        </div>
    )
}

export default SignatoryPopper