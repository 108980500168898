import React from "react";
import {Page} from "react-pdf";

const PDFPageViewerComponent = ({numPages, page_number}) => {

    return (
        <>
            <Page pageNumber={page_number}></Page>
        </>
    )
}

export default PDFPageViewerComponent