import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost, HttpPublicGet} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_public_service.subscriptionKey;


const retrieveApplicationRecord = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('token');

    let parameters = null;

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPublicGet(routes.RETRIEVE_EID_APPLICATION_RECORD, parameters, accessToken, subscriptionKey);

    return result;
}

const updateApplicationRecord = (formData, accessToken) => {
    let parameters = null;

    let payload = formData;

    console.log("updateApplicationRecord payload xxxx", payload);

    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpPublicPost(routes.SUBMIT_EID_APPLICATION_RECORD, parameters, formData, accessToken, subscriptionKey);

    return result;
}


const EIDPublicService = {
    retrieveApplicationRecord,
    updateApplicationRecord
}

export default EIDPublicService;