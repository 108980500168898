//// LOCAL ////
// const website_URL = "http://localhost:3000"
// const changePassword_URL = "https://msovcode.b2clogin.com/msovcode.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=54d92333-55f6-45a8-b93a-3e1c0f8d4d34&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost%3A3000&scope=openid&response_type=id_token&prompt=login"

//// DEVELOPMENT INSTANCE ////
// const website_URL = "https://red-river-02d09f51e.3.azurestaticapps.net/"
// const website_URL = "http://localhost:3001"

// const changePassword_URL = "https://msovcode.b2clogin.com/msovcode.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ovcode_password_reset&client_id=54d92333-55f6-45a8-b93a-3e1c0f8d4d34&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fblack-bay-05335151e.3.azurestaticapps.net&scope=openid&response_type=id_token&prompt=login"
// const eIDServiceBaseURL = "https://cybergem.azure-api.net/eid"
// const eIDPublicServiceBaseURL = "https://cybergem.azure-api.net/eid/public"
// const subscriptionKey = "15d1d8cd2ce54fb688fe9ee25c6e7055"
// const clientId = '54d92333-55f6-45a8-b93a-3e1c0f8d4d34'
// const knownAuthorities = ["msovcode.b2clogin.com"]
// const authority = 'https://msovcode.b2clogin.com/tfp/msovcode.onmicrosoft.com/B2C_1_ovcode_admin'
// const scopes =  "https://msovcode.onmicrosoft.com/54d92333-55f6-45a8-b93a-3e1c0f8d4d34/Admin"

//// NEXTIX PRODUCTION INSTANCE ////
const website_URL = "https://eid.nextix.co"
const changePassword_URL = "https://nextixeid.b2clogin.com/nextixeid.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_nextix_eid_change_password&client_id=c3366665-74e6-4088-8f9c-57f5b43f5478&nonce=defaultNonce&redirect_uri=https%3A%2F%2Feid.nextix.co%2F&scope=openid&response_type=id_token&prompt=login"

const eIDServiceBaseURL = "https://cybergem-prod.azure-api.net/nextix/eid"
const eIDPublicServiceBaseURL = "https://cybergem-prod.azure-api.net/nextix/public/eid"

const subscriptionKey = "9ece98e3a4564e7f8d9cfcb1d2088392"

const clientId = 'c3366665-74e6-4088-8f9c-57f5b43f5478'
const knownAuthorities = ["nextixeid.b2clogin.com"]
const authority = 'https://nextixeid.b2clogin.com/tfp/nextixeid.onmicrosoft.com/B2C_1_eid'
const scopes =  "https://nextixeid.onmicrosoft.com/c3366665-74e6-4088-8f9c-57f5b43f5478/Admin"

export default {

    eID_service : {
        url: eIDServiceBaseURL,
        subscriptionKey : subscriptionKey,
    },

    eID_public_service : {
        url: eIDPublicServiceBaseURL,
        subscriptionKey : subscriptionKey,
    },

    msal_url : {
        url: website_URL,
    },

    msal_change_password_url : {
        url: changePassword_URL,
    },

    msal_authConfig:{
        clientId: clientId,
        knownAuthorities: knownAuthorities,
        authority: authority,
        postLogoutRedirectUri: website_URL,
    },
    b2c_scope:{
        scopes:[
            scopes
        ]
    }
};