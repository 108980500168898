import {Box, Button, Card, CardContent, CardHeader, CardMedia, Typography} from "@mui/material";
import SignatoryPopper from "./signatory-popper.component";
import useSignatories from "../../hooks/useSignatories";
import {ThreeDots} from 'react-loader-spinner';

const SignatoryPopperHeader = (props) => {

    const {signatory, breakpoints} = props;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SignatoryPopper record={signatory} breakpoints={breakpoints} />
        </Box>
    );
};




const SignatoryListComponent = (props) => {

    const { breakpoints, signatory } = props;

    const { setAsDefaultSignatory, settingAsDefault } = useSignatories();

    const handleSetAsDefault = () => {
        let payload = {
            document_id: signatory?.id,
        }

        setAsDefaultSignatory(payload);

    }


    return (


        <Card sx={{ borderRadius: 2, padding: breakpoints === 'xs' || breakpoints === 's' ? 1 : 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints === 'xs' || breakpoints === 'sm' ? 'auto' : 360 }}>
            <CardHeader
                sx={{ pt: 0.8, pb: 0 }}
                action={<SignatoryPopperHeader signatory={signatory} breakpoints={breakpoints} />}
            />

            <CardContent sx={{ textAlign: "center", pt: 0 }}>
                <CardMedia
                    sx={{ width: "100%", height: 200, objectFit: "contain", backgroundColor: 'rgb(0 0 0 / 8%)', backgroundSize: 'contain' }}
                    image={signatory?.signature_url}
                />

                <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                        {signatory?.name}
                    </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                        {signatory?.position}
                    </Typography>
                </Box>

                <Box sx={{ mt: 1 }}>
                    {signatory?.is_default ?
                        <Button variant="text" disabled sx={{ color: 'green', fontWeight: 600, '&.Mui-disabled': { color: 'green' }, }}>
                            Default
                        </Button>
                        :
                        <Button variant="contained"
                            onClick={(e) => handleSetAsDefault(e)}
                            sx={{ borderRadius: 10, boxShadow: "none", width: '100%', background: "#248bf1", ":hover": { background: "#248bf1" } }}
                            startIcon={settingAsDefault && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                        >
                            {settingAsDefault ? "Setting as Default" : "Set As Default"}
                        </Button>
                    }
                </Box>
            </CardContent>
        </Card>

    )

}

export default SignatoryListComponent