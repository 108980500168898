import {Box, Grid, MenuItem, TextField, Select, FormControl, Typography, Button, Card, CardContent, CardMedia} from "@mui/material"
import {useEffect, useState } from "react";
import useWidth from "../../hooks/useWidth";
import CreateSignatureDialogComponent from "./e-signature/create-signature.dialog";
import SuccessConfirmationDialog from "../dialogs/success-confirmation-dialog";
import useEIDPublic from "../../hooks/useEIDPublic";
import {ThreeDots, RotatingLines} from 'react-loader-spinner';
import ImageCropperDialog from "../dialogs/image-cropper.dialog";
import SignatureCropperDialog from "../dialogs/signature-cropper.dialog";
import DefaultPhotoIcon from "../../icons/photo-placeholder.png";
import DefaultSignatureIcon from "../../icons/signature-placeholder.png";
import {useSnackbar} from "notistack";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#3333cf4f",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3333cf4f",
        borderWidth: "thin",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3333cf4f",
        borderWidth: "thin",
    },
};

const blood_types = [
    {name: 'A+'},
    {name: 'A-'},
    {name: 'B+'},
    {name: 'B-'},
    {name: 'AB+'},
    {name: 'AB-'},
    {name: 'O+'},
    {name: 'O-'}
]

const marital_status = [
    {name: 'SINGLE'},
    {name: 'MARRIED'},
    {name: 'DIVORCED'},
    {name: 'SEPARATED'},
    {name: 'WIDOWED'},
]

const gender_options = [
    {name: 'MALE'},
    {name: 'FEMALE'}
]

const ApplicationFormComponent = () => {

    const accessToken = (new URLSearchParams(window.location.search)).get("token");

    const breakpoints = useWidth();
    const {enqueueSnackbar} = useSnackbar();

    const {isFetchingData, retrieveApplicationRecord, record, isUpdatingRecord, updateApplicationRecord} = useEIDPublic();
   
    // State
    const [photoPreview] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [gender, setGender] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [bloodType, setBloodType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [pob, setPob] = useState("");
    const [suffix, setSuffix] = useState("");
    const [openSuccessConfirmation, setOpenSuccessConfirmation] = useState(false)
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [signatureFile, setSignatureFile] = useState(null);
    const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
    const [position, setPosition] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonNo, setContactPersonNo] = useState("");

    const [openImageEditor, setOpenImageEditor] = useState(false);
    const [userImg, setUserImg] = useState(null);
    const [userPhotoPreview, setUserPhotoPreview] = useState(null);
    const [openSigEditor, setOpenSigEditor] = useState(false);
    const [userSigPreview, setUserSigPreview] = useState(null);

    const [userPhoto, setUserPhoto] = useState(null);
    const [userSignature, setUserSignature] = useState(null);
    const [tempUserPhoto, setTempUserPhoto] = useState(null);
    const [tempUserSignature, setTempUserSignature] = useState(null);

    useEffect(() => {
        if (!accessToken) {
            window.location.href = "/";
        }
    }, [])

    useEffect(() => { 
        retrieveApplicationRecord(handleOpenSuccessConfirmation); 

        if (record) {
            setFirstName(record?.firstname);
            setLastName(record?.lastname);
            setMiddleName(record?.middlename);
            setSuffix(record?.suffix);
            setDob(dayjs(record?.dob));
            setAddress(record?.address);
            setGender(record?.gender);
            setBloodType(record?.blood_type);
            setMaritalStatus(record?.marital_status);
            setPob(record?.pob);
            setUserPhoto(record?.photo);
            setUserSignature(record?.e_signature);
            setPosition(record?.position);
            setContactPerson(record?.contact_person);
            setContactPersonNo(record?.contact_person_no);
        }
        
    }, [JSON.stringify(record)])

    const handleOpenSuccessConfirmation = () => {
        setOpenSuccessConfirmation(true)
    }

    const handleOpenSignatureDialog = (e) => {
        setOpenSignatureDialog(true);
    }

    const handleChangeGender = (e) => {
        const selectedGender = e.target.value;
        setGender(selectedGender.toUpperCase());
    }

    const handleChangeBloodType = (e) => {
        const selectedBloosType = e.target.value;
        setBloodType(selectedBloosType.toUpperCase());
    }

    const handleChangeMaritalStatus = (e) => {
        const selectedStatus = e.target.value;
        setMaritalStatus(selectedStatus.toUpperCase());
    }

    const handleTextField = (e, name) => {
        const regex = /^[\d-]+$/;
    
        if (name == "firstName") {
            const FirstName = e.target.value;
            setFirstName(FirstName.toUpperCase());

        } else if (name == "middleName") {
            const MiddleName = e.target.value;
            setMiddleName(MiddleName.toUpperCase());

        } else if (name == "suffix") {
            const Suffix = e.target.value;
            setSuffix(Suffix.toUpperCase());

        } else if (name == "lastName") {
            const LastName = e.target.value;
            setLastName(LastName.toUpperCase());

        } else if (name == "address") {
            const Address = e.target.value;
            setAddress(Address.toUpperCase());

        } else if (name == "pob") {
            const POB = e.target.value;
            setPob(POB.toUpperCase());

        } else if (name == "position") {
            const selectedposition = e.target.value;
            setPosition(selectedposition.toUpperCase());

        }   else if (name == "contactPerson") {
            const selectedcontactPerson = e.target.value;
            setContactPerson(selectedcontactPerson.toUpperCase());

        }  else if (name == "contactPersonNo") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setContactPersonNo(e.target.value);
            }
        }
    }

    const validatePhoto = (e) => {
        setImageStateFromChild();

        var fileName = document.getElementById("upload-photo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        
            const imageFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setUserImg(e.target.result);
            }

            reader.readAsDataURL(imageFile);

            setOpenImageEditor(true);
 
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const setImageStateFromChild = (child) => {
        setUserImg(child);
    }

    const handleClearPhoto = () => {
        setPhotoFile(null);
        setUserPhotoPreview(null);
    }

    useEffect(() => {
        if (userPhoto) {
            const fetchData = async () => {
                try {
                    const photoResponse = await fetch(userPhoto);
                    const photoBlob = await photoResponse.blob();
                    const tempPhotoFile = new File([photoBlob], 'temp_photo.png', {type: 'image/png'});
                    
                    setTempUserPhoto(tempPhotoFile);

                } catch (error) {
                    console.error('error fetching user photo xxxx', error);
                }
            };
        
            fetchData();

        } else {
            setTempUserPhoto(new Blob([new ArrayBuffer(0)], {type: 'application/octet-stream'}));
        }
    }, [userPhoto])


    useEffect(() => {
        if (userSignature) {
            const fetchData = async () => {
                try {
                    const signatureResponse = await fetch(userSignature);
                    const signatureBlob = await signatureResponse.blob();
                    const tempSignatureFile = new File([signatureBlob], 'temp_signature.png', {type: 'image/png'});
                    
                    setTempUserSignature(tempSignatureFile);

                } catch (error) {
                    console.error('error fetching user signature xxxx', error);
                }
            };

            fetchData();

        } else {
            setTempUserSignature(new Blob([new ArrayBuffer(0)], {type: 'application/octet-stream'}));
        }

    }, [userSignature])

    const formattedDob = dob ? dayjs(dob).format('YYYY-MM-DD') : '';

    const handleUpdateInfo = () => {
        if (firstName && lastName && formattedDob && pob && address && gender && bloodType &&
            maritalStatus && position && contactPerson && contactPersonNo) {
            let formData = new FormData();

            formData.append("firstname", firstName);
            formData.append("lastname", lastName);
            formData.append("middlename", middleName);
            formData.append("suffix", suffix);
            formData.append("dob", formattedDob);
            formData.append("address", address);
            formData.append("gender", gender);
            formData.append("blood_type", bloodType);
            formData.append("marital_status", maritalStatus);
            formData.append("pob", pob);
            formData.append("photo", photoFile ? photoFile : tempUserPhoto);
            formData.append("signature", signatureFile ? signatureFile : tempUserSignature);
            formData.append('position', position);
            formData.append('contact_person', contactPerson);
            formData.append('contact_person_no', contactPersonNo);

            updateApplicationRecord(formData, accessToken, setOpenSuccessConfirmation);

        } else if (!firstName) {
            enqueueSnackbar("First Name is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!lastName) {
            enqueueSnackbar("Last Name is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!formattedDob) {
            enqueueSnackbar("Date of Birth is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!pob) {
            enqueueSnackbar("Place of Birth is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!address) {
            enqueueSnackbar("Address is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!gender) {
            enqueueSnackbar("Gender is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!maritalStatus) {
            enqueueSnackbar("Marital Status is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!bloodType) {
            enqueueSnackbar("Blood Type is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!position) {
            enqueueSnackbar("Position is required", { 
                variant: "error",
                autoHideDuration: 3000
            });
    
        } else if (!contactPerson) {
            enqueueSnackbar("Contact Person is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!contactPersonNo) {
            enqueueSnackbar("Contact Person No. is required", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }


    function PageLoader () {
        return (
            <div 
                style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                minWidth: "100% !important",
                maxWidth: "100% !important",
                borderRadius: "8px",
                cursor: "default",
                flexGrow: 0,
                height: (breakpoints == "xs" || breakpoints == "sm") ? "75vh" : "80vh",
                justifyContent: "center",
                margin: "auto",
                transition: "border-color .15s linear"
                }}
            >
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width={(breakpoints == "xs" || breakpoints == "sm") ? "50" : "65"}
                    visible={true}
                />
            </div>
        )
    }

    if (isFetchingData) return <PageLoader />


    return (
        <>
            {record &&
                <Box sx={{border: "0.1px solid #3333cf", borderRadius: 2, mb: "28px"}}>
                    <form noValidate autoComplete="off">
                        <Grid container sx={{ margin: 'auto', padding: 2}}>
                            <Grid item xs={12}>
                                <Grid container spacing={3} direction="row">
                                    <Grid item xs={12} sm={7}>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>First Name<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    required
                                                    defaultValue={firstName}
                                                    onChange={(e) => handleTextField(e, "firstName")}
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters' // capitalizes all characters in input
                                                      }}
                                                      sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Last Name<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    required
                                                    defaultValue={lastName}
                                                    onChange={(e) => handleTextField(e, "lastName")}
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters' // capitalizes all characters in input
                                                      }}

                                                      sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Middle Name :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={middleName}
                                                    onChange={(e) => handleTextField(e, "middleName")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters' // capitalizes all characters in input
                                                      }}
                                                      sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Suffix :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={suffix}
                                                    onChange={(e) => handleTextField(e, "suffix")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters' // capitalizes all characters in input
                                                      }}
                                                      sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Position<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={position}
                                                    onChange={(e) => handleTextField(e, "position")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters'
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": {borderColor: "#3333cf4f !important"},
                                                        },
                                                        fontSize: 16
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Date of Birth<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                        <DatePicker
                                                            value={dob}
                                                            required
                                                            views={['year', 'month', 'day']}
                                                            inputProps={{style: {fontSize: 16}}}
                                                            sx={{
                                                                '& .MuiInputLabel-root': { color: '#3333cf4f' },
                                                                '& .MuiOutlinedInput-root': {
                                                                '& > fieldset': { borderColor: '#3333cf4f !important' },
                                                                },
                                                                fontSize: 16,
                                                            }}
                                                            onChange={(newValue) => {
                                                                setDob(newValue);
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Place of Birth<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={pob}
                                                    onChange={(e) => handleTextField(e, "pob")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters' // capitalizes all characters in input
                                                      }}
                                                      sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16
                                                      }}
                                                />
                                            </Grid>
                                        </Grid> 

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Address<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={address}
                                                    onChange={(e) => handleTextField(e, "address")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters' // capitalizes all characters in input
                                                      }}
                                                      sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                        },
                                                        fontSize: 16
                                                      }}
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Gender<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={gender}
                                                        onChange={(e)=>handleChangeGender(e)}
                                                        inputProps={{
                                                            style: {textTransform: 'uppercase', fontSize: 16},
                                                            autoCapitalize: 'characters' // capitalizes all characters in input
                                                        }}
                                                        sx={selectFieldStyles}
                                                    >
                                                        {gender_options.map((genderx)=>{
                                                            return(
                                                                <MenuItem value={genderx.name} sx={{textTransform: 'uppercase'}}>
                                                                    {genderx.name}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Blood Type<b style={{color: 'red'}}>*</b> :</Typography>  
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={bloodType}
                                                        onChange={(e)=>handleChangeBloodType(e)}
                                                        inputProps={{
                                                            style: {textTransform: 'uppercase', fontSize: 16},
                                                            autoCapitalize: 'characters'
                                                        }}
                                                        sx={selectFieldStyles}                                                      
                                                    >
                                                        {blood_types.map((blood)=>{
                                                            return(
                                                                <MenuItem value={blood.name} sx={{textTransform: 'uppercase'}}>
                                                                    {blood.name}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Marital Status<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={maritalStatus}
                                                        onChange={(e)=>handleChangeMaritalStatus(e)}
                                                        inputProps={{
                                                            style: {textTransform: 'uppercase', fontSize: 16},
                                                            autoCapitalize: 'characters' // capitalizes all characters in input
                                                        }}
                                                        sx={selectFieldStyles}
                                                        
                                                    >
                                                        {marital_status.map((status)=>{
                                                            return(
                                                                <MenuItem value={status.name} sx={{textTransform: 'uppercase'}}>{status.name}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Contact Person<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={contactPerson}
                                                    onChange={(e) => handleTextField(e, "contactPerson")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters'
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": {borderColor: "#3333cf4f !important"},
                                                        },
                                                        fontSize: 16
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container sx={{pt: 1}}>
                                            <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                                <Typography sx={{fontWeight: 'bold'}}>Contact Person No.<b style={{color: 'red'}}>*</b> :</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    autoComplete="off"
                                                    defaultValue={contactPersonNo}
                                                    onChange={(e) => handleTextField(e, "contactPersonNo")}
                                                    required
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase', fontSize: 16},
                                                        autoCapitalize: 'characters'
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& > fieldset": {borderColor: "#3333cf4f !important"},
                                                        },
                                                        fontSize: 16
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={5}>
                                        <Grid container spacing={3} sx={{mt: -2, alignItems: 'center', justifyContent:  'center' }}>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container sx={{alignItems: 'center', justifyContent:  'center' }}>
                                                    <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                        <Card sx={{border: 1, borderColor: "#3333cf4f !important", marginBottom: 2, marginTop: 0, boxShadow: 'none'}}>
                                                                {(userPhoto && !userPhotoPreview) ?
                                                                    <CardContent sx={{textAlign:'center'}}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            src={userPhoto}
                                                                            style={{width: '100%', height : 125, objectFit: 'contain'}}
                                                                        />
                                                                    </CardContent>

                                                                :

                                                                    (userPhotoPreview) ? 
                                                                        <CardContent sx={{textAlign:'center'}}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                src={userPhotoPreview}
                                                                                style={{width: '100%', height : 125, objectFit: 'contain'}}
                                                                            />
                                                                        </CardContent>
                                                                :
                                                                    
                                                                    <CardContent sx={{textAlign: "center"}}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            src={DefaultPhotoIcon}
                                                                            style={{width: "100%", height: 125, objectFit: "contain"}}
                                                                        />
                                                                    </CardContent>
                                                                }
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                        <Grid container sx={{justifyContent: 'center'}}>
                                                            {userPhotoPreview ?
                                                                <>
                                                                    <Grid item xs={5} sm={5} >
                                                                        <Button variant="outlined" sx={{borderColor: "#3333cf", color: "#3333cf", width: 'auto', fontWeight: 550, borderRadius: 10, textTransform: 'none'}} 
                                                                            onClick={ (e) => handleClearPhoto(e)}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </Grid>
                                                                </>
                                                            :
                                                                <Grid item xs={12} sm={12} >
                                                                    <Button variant="outlined" sx={{borderColor: "#3333cf !important", color: "#3333cf !important", width: "auto", borderRadius: 10, textTransform: "none"}}>
                                                                        <input 
                                                                            type="file" 
                                                                            accept="image/*" 
                                                                            id="upload-photo"
                                                                            onChange= {(e) => validatePhoto(e)}
                                                                            hidden
                                                                        />

                                                                        <label style={{color: "3333cf", cursor: "pointer", fontWeight: 550}} htmlFor="upload-photo">
                                                                            {(photoPreview) ? "Change Photo" : "Browse Photo"} 
                                                                        </label>
                                                                    </Button>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{textAlign: 'center', pt: 1}}>
                                                        <Typography variant="subtitle2" style={{fontSize: 16, fontWeight: 550}} className="required-field">Photo</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
                                                    <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                        <Card sx={{border: 1, borderColor: "#3333cf4f !important", marginBottom: 2, marginTop: 0, boxShadow: 'none'}}>
                                                                {(userSignature && !userSigPreview) ?
                                                                    <CardContent sx={{textAlign:'center'}}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            src={userSignature}
                                                                            style={{width: '100%', height : 125, objectFit: 'contain', backgroundColor: 'rgb(0 0 0 / 8%)'}}
                                                                        />
                                                                    </CardContent>

                                                                :

                                                                    (userSigPreview) ? 
                                                                        <CardContent sx={{textAlign:'center'}}>
                                                                            <CardMedia
                                                                                component="img"
                                                                                src={userSigPreview}
                                                                                style={{width: "100%", height: 125, objectFit: "contain", backgroundColor: 'rgb(0 0 0 / 8%)'}}
                                                                            />
                                                                        </CardContent>
                                                                :
                                                                   
                                                                    <CardContent sx={{textAlign: "center"}}>
                                                                        <CardMedia
                                                                            component="img"
                                                                            src={DefaultSignatureIcon}
                                                                            style={{width: "100%", height: 125, objectFit: "contain"}}
                                                                        />
                                                                    </CardContent>
                                                                }
                                                        </Card>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                        <Grid container sx={{justifyContent: 'center'}}>
                                                            <Grid item xs={12} sm={12}>
                                                                <Button variant="outlined" sx={{fontWeight: 550, borderColor: "#3333cf", color: "#3333cf", width: 'auto', borderRadius: 10, boxShadow: 'none',  textTransform: 'none'}}
                                                                    onClick={(e) => handleOpenSignatureDialog(e)}
                                                                >
                                                                    {(signaturePreview) ? "Change Signature" : "Create Signature"}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sx={{textAlign: 'center', pt: 1}}>
                                                    <Typography variant="subtitle2" style={{fontSize: 16, fontWeight: 550}} className="required-field">e-Signature</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>   
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "40px 16px 16px" : "0px 16px 16px", justifyContent: "flex-end", textAlign: "end"}}>
                            <Button  variant="contained" sx={{width: (isUpdatingRecord) ? {} : 120, backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', fontWeigth: 550, '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                                onClick={(e) => handleUpdateInfo(e)}
                                startIcon={isUpdatingRecord && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                            >
                                {isUpdatingRecord ? "Submitting" : "Submit"}
                            </Button>
                        </Grid>
                    </form>
                </Box>
            } 

            {openSuccessConfirmation &&
                <SuccessConfirmationDialog breakpoints={breakpoints}
                    openSuccessConfirmation={openSuccessConfirmation} setOpenSuccessConfirmation={setOpenSuccessConfirmation}
                />
            }

            {openSignatureDialog &&
                <CreateSignatureDialogComponent breakpoints={breakpoints}
                    openSignatureDialog={openSignatureDialog} setOpenSignatureDialog={setOpenSignatureDialog}
                    signature={signaturePreview} setOpenSigEditor={setOpenSigEditor}
                    signatureFile={signatureFile} setSignatureFile={setSignatureFile}
                />
            }

            {openImageEditor &&
                <ImageCropperDialog breakpoints={breakpoints} 
                    openImageEditor={openImageEditor} setOpenImageEditor={setOpenImageEditor}
                    userImg={userImg} setImageStateFromChild={setImageStateFromChild}
                    setUserPhotoPreview={setUserPhotoPreview} setPhotoFile={setPhotoFile}
                />
            }

            {openSigEditor &&
                <SignatureCropperDialog breakpoints={breakpoints} 
                    openSigEditor={openSigEditor} setOpenSigEditor={setOpenSigEditor}
                    signatureFile={signatureFile} setSignatureFile={setSignatureFile}
                    setSignature={setSignaturePreview} setUserSigPreview={setUserSigPreview}
                />
            }
        </>
    )
}

export default ApplicationFormComponent