import { useState } from "react";
import { useSnackbar } from "notistack";
import EIDSignatoriesService from "../services/api/signatories";

const useSignatories = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);
    const [signatories, setSignatories] = useState([]);
    const [isDeleting, setDeleting] = useState(false);
    const [isDeleted, setDeleted] = useState(false);
    const [settingAsDefault, setSettingAsDefault] = useState(false);

    const [isAddingSignatory, setAddingSignatory] = useState(false);
    const [isUpdatingSignatory, setUpdatingSignatory] = useState(false);


    const retrieveSignatories = () => {
        setLoading(true);

        EIDSignatoriesService.retrieveSignatories().then((res) => {
            console.log("retrieveSignatories data xxxx", res);

            setLoading(false);

            let signatories_list = res.data.data;
            let stringifySignatoriesList = JSON.stringify(signatories_list);

            if (res.data.success) {
                setSignatories(res.data.data);
                localStorage.setItem("savedSignatoriesList", stringifySignatoriesList);
            }

        }).catch((error) => {
            console.log("retrieveSignatories error xxxx", error);
            setLoading(false);


            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }


        })

    }

    const deleteSignatory = (document_id) => {

        setDeleting(true);

        EIDSignatoriesService.deleteSignatory(document_id).then((res) => {

            setDeleting(false);

            if (res.data.success) {
                enqueueSnackbar("Successfully deleted Signatory.", {
                    variant: "success",
                    autoHideDuration: 3000
                });
                setDeleted(true);
            }

        }).catch((error) => {

            console.log("delete signatory error xxxx", error);

            setDeleting(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const setAsDefaultSignatory = (document_id) => {

        setSettingAsDefault(true);

        EIDSignatoriesService.setAsDefaultSignatory(document_id).then((res) => {
            console.log("setAsDefaultSignatory data xxxx", res);
            setSettingAsDefault(false);

            if (res.data.success) {
                enqueueSnackbar("Successfully set Signatory as Default.", {
                    variant: "success",
                    autoHideDuration: 3000
                });
                window.location.reload();
            }

        }).catch((error) => {
            console.log("setAsDefaultSignatory error xxxx", error);
            setSettingAsDefault(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else if (error.response.data.message) {
                enqueueSnackbar(error.response.data.message, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }

        })
    }

    const addSignatory = (formData, setOpenSigEditor) => {
        setAddingSignatory(true);

        EIDSignatoriesService.addSignatory(formData).then((res) => {
            console.log("addSignatory data xxxx", res);

            if (res.data.success) {
                setAddingSignatory(false);
                setOpenSigEditor(false);

                enqueueSnackbar("Successfully added signatory", {
                    variant: "success",
                    autoHideDuration: 3000
                });

                window.location.href = "/signatory";
            }

        }).catch((error) => {
            console.log("addSignatory error xxxx", error);
            setAddingSignatory(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }

    const updateSignatory = (formData, setOpenSigEditor) => {
        setUpdatingSignatory(true);

        EIDSignatoriesService.updateSignatory(formData).then((res) => {
            console.log("updateSignatory data xxxx", res);

            if (res.data.success) {
                setUpdatingSignatory(false);
                setOpenSigEditor(false);

                enqueueSnackbar("Successfully updated signatory", {
                    variant: "success",
                    autoHideDuration: 3000
                });

                window.location.href = "/signatory";
            }

        }).catch((error) => {
            console.log("updateSignatory error xxxx", error);
            setUpdatingSignatory(false);

            if (error.response.data.error_message) {
                enqueueSnackbar(error.response.data.error_message, {
                    variant: "error",
                    autoHideDuration: 3000
                });

            } else {
                enqueueSnackbar(error.response.data.error, {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        })
    }





    return {
        loading, retrieveSignatories, signatories, deleteSignatory, isDeleted, isDeleting, setAsDefaultSignatory, settingAsDefault,
        isAddingSignatory, isUpdatingSignatory, addSignatory, updateSignatory
    }

}

export default useSignatories