import React, {useState, useEffect} from "react";
import {Box, Typography} from "@mui/material";
import {CustomTab, CustomTabs} from "../../../common/custom-tabs";
import CreateSignatureByDrawComponent from "./create-signature-by-draw.component";
import CreateSignatureByUploadComponent from "./create-signature-by-upload.component";

function useSignatureTabs (props) {
  const [signatureTabs, setSignatureTabs] = useState(null);

  const setupTabs = () => {
    var default_tabs = [
      {name: "Draw", component: <CreateSignatureByDrawComponent props={props} />},
      {name: "Upload", component: <CreateSignatureByUploadComponent props={props} />},
    ]

    setSignatureTabs(default_tabs);
  }

  useEffect(() => { 
    setupTabs(); 
  }, [])

  return signatureTabs;
}


const SignatureTabs = (props) => {
  
  const {breakpoints} = props;

  const tabs = useSignatureTabs(props);

  const [value, setValue] = useState(0);

  const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
      
    return (
      <div
        role="tabpanel"
        component="div" 
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && 
          <Box>
            <Typography>
              {children}
            </Typography>
          </Box>
        }
      </div>
    )
  }

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  
  return (
    <Box sx={{width: "100%", ml: (breakpoints == "xs" || breakpoints == "sm") ? "50px" : {}}}>
      <Box>
        <CustomTabs
          variant={(breakpoints == 'xs' || breakpoints == 'sm') ? "scrollable" : ""}
          value={value} 
          onChange={handleChange} 
          sx={{mt: 2}} 
          TabIndicatorProps={{sx: {backgroundColor: "#3333cf", top: "25px !important", height: "4px !important"}}}
          centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })}
        </CustomTabs>

        {tabs?.map((tab, index) => {
          return (
            <TabPanel value={value} index={index} style={{padding: "0px"}}>
              {tab.component}
            </TabPanel> 
          ) 
        })}
      </Box>
    </Box>
  )
}

export default SignatureTabs