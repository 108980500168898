import environment from "../../../environment/environment";
import routes from "../common/api-routes";
import {HttpPostMultipart, HttpGet, HttpPost, CustomHttpPostMultipart, HttpPublicPost} from "../common/HttpRequestBuilder";

const SUBSCRIPTION_KEY = environment.eID_service.subscriptionKey;

 
const viewUser = () => {
    let query_params = null;
    let subscriptionKey = SUBSCRIPTION_KEY;

    let result = HttpGet(routes.VIEW_USER, query_params, subscriptionKey);

    return result;

}

const ProfileService = {
    viewUser: viewUser,
    
}

export default ProfileService;