import {Box, Grid, Typography} from "@mui/material";
import ErrorRecordsComponent from "../components/error-records/error-records.component";

const ErrorResponseListScreen = (props) => {

    const {csvErrorList} = props;

    return (
        <Box>
            <Grid container spacing={2} sx={{textAlign: "start", mb: 2}}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        Error Records
                    </Typography>
                </Grid>

                <ErrorRecordsComponent csvErrorList={csvErrorList} />
            </Grid>
        </Box>
    )
}

export default ErrorResponseListScreen