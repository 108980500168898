import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import {FileUploader} from "react-drag-drop-files";
import {useSnackbar} from "notistack";

const CreateSignatureByUploadComponent = ({props}) => {

    const {breakpoints, setOpenSignatureDialog, setSignatureFile, setOpenSigEditor} = props;

    const {enqueueSnackbar} = useSnackbar();

    const [sigFile, setSigFile] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);

    const handleChange = (file) => {
        var fileName = file.name;
        var fileExt = fileName.lastIndexOf(".") + 1;
        var mimetype = fileName.substr(fileExt, fileName.length).toLowerCase();
        
        if (mimetype == "jpg" || mimetype == "jpeg" || mimetype == "png" || mimetype == "jfif") {
            setSigFile(file);
            setSignaturePreview(URL.createObjectURL(file));

        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleResetFile = () => {
        setSigFile(null);
        setSignatureFile(null);
        setSignaturePreview(null);
    }

    const handleUploadSignature = (e) => {
        if (sigFile) {
            setSignatureFile(sigFile);
            setOpenSigEditor(true);
            setOpenSignatureDialog(false);
        }
    }


    return (
        <Box sx={{padding: (signaturePreview && (breakpoints == 'xs' || breakpoints == 'sm')) ? "0px 0px 16px 0px" 
            : (signaturePreview && (breakpoints == 'md' || breakpoints == 'lg' ||  breakpoints == 'xl')) ? "0px 40px 16px 40px" 
            : (!signaturePreview && (breakpoints == 'xs' || breakpoints == 'sm')) ? "23px 0px 16px 0px" : "23px 40px 16px 40px", 
            mt: (signaturePreview) ? "-13px" : {}}}
        >
            {signaturePreview &&
                <Grid sx={{textAlign: "end"}}>
                    <Button onClick={(e) => handleResetFile(e)} sx={{color: "#3333cf", cursor: "pointer", textTransform: "none", borderRadius: 0, fontSize: 14}}>
                        Clear
                    </Button>
                </Grid>
            }

            <Grid item xs={12}>
                {(sigFile) ? 
                    <div 
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            minWidth: "100% !important",
                            maxWidth: "100% !important",
                            border: "dashed 2px #3333cf",
                            borderRadius: "8px",
                            cursor: "pointer",
                            flexGrow: 0,
                            height: "210px",
                            justifyContent: "center",
                            margin: "auto",
                            transition: "border-color .15s linear",
                        }}
                    >
                        <img alt="" src={signaturePreview} style={{height: (breakpoints == 'xs' || breakpoints == 'sm') ? 55 : 150}} />
                    </div>

                :
                    <FileUploader
                        hoverTitle=""
                        multiple={false}
                        handleChange={handleChange}
                        name="file"
                        label="Drag and Drop file here or click here to upload"
                        children={
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "100% !important",
                                    maxWidth: "100% !important",
                                    border: "dashed 2px #3333cf",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    flexGrow: 0,
                                    height: "210px",
                                    justifyContent: "center",
                                    margin: "auto",
                                    transition: "border-color .15s linear",
                                }}
                            >
                                <p style={{color: "#666", fontWeight: 600, fontSize: (breakpoints == 'xs' || breakpoints == 'sm') ? "10px" : {}}}>
                                    Drag and Drop file here or click here to upload
                                </p>
                            </div>
                        }
                    />
                }
            </Grid>

            <Box sx={{paddingTop: 6, textAlign: "end"}}>
                <Button onClick={(e) => setOpenSignatureDialog(false)} variant="outlined" 
                    sx={{borderColor: "#3333cf", color: "#3333cf", textTransform: "none", width: 120, borderRadius: 10}}
                >
                    Cancel
                </Button>

                &nbsp; &nbsp;

                <Button variant="contained" sx={{textTransform: "none", width: 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none", '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => handleUploadSignature(e)}
                >
                    Save
                </Button>
            </Box>
        </Box>
    )
}

export default CreateSignatureByUploadComponent