import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import ForbiddenImage from "../images/forbidden.png";


const returnHome = () => {
    sessionStorage.clear();
    window.location.href = "/";
}


const ForbiddenScreen = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#FFF' , overflow: 'hidden'}}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
                    <Typography variant="h3" component="div" textAlign="center" mb={2}>
                        You are not authorized.
                    </Typography>
                    <Typography variant="body1" component="div" textAlign="center" fontSize={18}>
                        You tried to access a page you did not have prior authorization for.
                    </Typography>
                    <br/><br/>
                    <Button variant="contained"  onClick={(e) => returnHome(e)}
                        sx={{ width: 'auto', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', 
                            ":hover" : { backgroundColor: '#b5b5b5' }
                        }}  
                    >
                        Return to Home
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" justifyContent="center">
                    <img alt="" src={ForbiddenImage} style={{ height: 500 }} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default ForbiddenScreen;
