import {Skeleton, Card, CardContent, Grid, CardActions} from "@mui/material";


function AgencySkeletonComponent(props) {

    const {breakpoints} = props;

    const url = window.location.pathname;


    return(

        <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',  height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : url == '/update-agency-information' ? 500 : 380}}>
            <CardContent>
                <Grid container direction="row" sx={{justifyContent: 'center', alignSelf: 'center'}}>
                    <Grid item xs={12} sx={{alignSelf: 'center', margin: 'auto', textAlign: 'center'}}>
                        <Grid container sx={{mb: 1, mt: 1}}>
                            <Grid item xs={12} sm={12} sx={{mb: 1}}> 
                                <Skeleton variant="square" width={280} height={110} style={{margin: 'auto', backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Grid container spacing={2} sx={{marginBottom: 1, alignItems: 'center'}}>
                            <Grid item xs={12} sm={4}>
                                <Skeleton variant="rectangular" height={40} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Skeleton variant="rectangular" height={40} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid> 
                        
                            <Grid item xs={12} sm={4}>
                                <Skeleton variant="rectangular" height={40} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid>

                            <Grid item xs={12} sm={8}>
                                <Skeleton variant="rectangular" height={40} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Skeleton variant="rectangular" height={40} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid> 

                            <Grid item xs={12} sm={8}>
                                <Skeleton variant="rectangular" height={40} style={{backgroundColor: 'rgb(108 139 222 / 30%)',}} />
                            </Grid> 
                            
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {(url == "/update-agency-information") ?
                <CardActions sx={{mt: 1.5}}>
                    <Grid container spacing={2} sx={{justifyContent: "end", textAlign: "end"}}>
                        <Skeleton variant="rectangular" height={40} width="15%" style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        
                        &nbsp; &nbsp;

                        <Skeleton variant="rectangular" height={40} width="15%" style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                    </Grid>
                </CardActions>
            :
                null
            }
        </Card>
       
    )
}

export default AgencySkeletonComponent