import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import useEID from "../../hooks/useEID";
import {ThreeDots} from 'react-loader-spinner';

const SendAllAppBindingDialog = (props) => {

    const {openSendAllAppBinding, setOpenSendAllAppBinding, csvList} = props;

    const {sendingAppBinding, sendAllAppBinding} = useEID();

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);


    useEffect(() => {
        if (csvList.length == 1) {
            csvList?.map((record) => {
                setFirstName(record.firstname);
                setLastName(record.lastname);
            })
        }
    }, [])

    const handleSendAppBinding = (e) => {
        console.log("csvList sendAllAppBinding dialog xxxx", csvList); 

        for (let i = 0; i < csvList.length; i++) {
            const uuid = csvList[i].uuid;

            let payload ={
                uuid : uuid
            }

            sendAllAppBinding(payload, i === csvList.length - 1, setOpenSendAllAppBinding);
        }
    }


    return (
        <Dialog open={openSendAllAppBinding} onClose={(e) => setOpenSendAllAppBinding(false)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}> 
                Send App Binding Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: "20px 24px 24px"}}>
                {(csvList?.length == 1) ?
                    <Typography>
                        Are you sure you want to send App Binding to <b>{firstName} {lastName}</b>?
                    </Typography>
                :
                    <Typography>
                        Are you sure you want to send App Binding to <b>all</b>?
                    </Typography>
                }
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{textTransform: "none", width: 80, borderRadius: 10, color: "#3333cf", borderColor: "#3333cf", "&:hover": {borderColor: "#3333cf", color: "#3333cf"}}} 
                    onClick={(e) => setOpenSendAllAppBinding(false)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: "none", width: (sendingAppBinding) ? "auto" : 80, backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => handleSendAppBinding(e)}
                    startIcon={sendingAppBinding && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {sendingAppBinding ? "Sending" : "Send"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SendAllAppBindingDialog