import React from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NEIDLogo from "../../icons/nextix.png";

const CreateSignatoryDialog = (props) => {

    const {breakpoints, openCreateSignatory, setOpenCreateSignatory} = props;

    const handleCloseConfirmationDialog = (e) => {
        setOpenCreateSignatory(false);
    }

    const CustomDialogTitle = (props) => {
        const {children, onClose, ...other} = props;

        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}

                {onClose ? 
                    (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{position: "absolute", right: 8, top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) 
                : 
                    null
                }
          </DialogTitle>
        )
    }

    return (
        <Dialog
            maxWidth={"sm"}
            fullWidth
            open={openCreateSignatory}
            onClose={(e) => handleCloseConfirmationDialog(e)}
        >
            <CustomDialogTitle id="customized-dialog-title" onClose={(e) => handleCloseConfirmationDialog(e)}>
                <img alt="" src={NEIDLogo} style={{width: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 95}} /> 
            </CustomDialogTitle>

            <DialogContent dividers sx={{borderBottom: "none", pt: 0, borderTop: "none", mt: "-10px", padding: (breakpoints == "xs" || breakpoints == "sm") ? "0px 28px 24px" : "10px 38px 24px"}}>
                To generate eID, signatory is required. Please create signatory.
            </DialogContent>

            <DialogActions sx={{justifyContent: "end", padding: "0px 24px 18px"}}>
                <Button variant="contained" 
                    sx={{width: "auto", backgroundColor: "#248bf1", borderRadius: 10, textTransform: "none", fontSize: 14, 
                        '&:hover': {backgroundColor: "#248bf1", color: "white"}
                    }} 
                    onClick={(e) => window.location.href = "/create-signatory"}
                >
                    Create Signatory
                </Button>     
            </DialogActions>
        </Dialog> 
    )
}

export default CreateSignatoryDialog