import UpdateAgencyInformationComponent from "../components/agency-information/update-agency-information.component";
import AgencyInformationScreen from "../screens/AgencyInformationScreen";
import ErrorResponseListScreen from "../screens/ErrorResponseListScreen";
import ProfileScreen from "../screens/ProfileScreen";
import LandingScreen from "../screens/LandingScreen";
import ViewEIDDetailsScreen from "../screens/ViewEIDDetailsScreen";
import EIDRecordListScreen from "../screens/EIDRecordListScreen";
import RecordsScreen from "../screens/RecordsScreen";
import ApplicationFormScreen from "../screens/ApplicationFormScreen";
import UploadCSVScreen from "../screens/UploadCSVScreen";
import ConfigScreen from "../screens/ConfigScreen";
import EditRecordScreen from "../screens/EditRecordScreen";
import CreateRecordScreen from "../screens/CreateRecordScreen";
import UpdateConfigEIDComponent from "../components/agency-information/update-config-eid.component";
import PreRegistrationFormScreen from "../screens/PreRegistrationFormScreen";
import RegistrationLinkScreen from "../screens/RegistrationLinkScreen";
import CreateAgencyInformationComponent from "../components/agency-information/create-agency.component";
import ForbiddenScreen from "../screens/ForbiddenScreen";
import SignatoryScreen from "../screens/SignatoryScreen";
import PDFViewerComponent from "../components/csv/pdf-page-viewer.component";
import CreateSignatoryScreen from "../screens/CreateSignatoryScreen";
import EditSignatoryScreen from "../screens/EditSignatoryScreen";

export const routes = [
    {
        path: "/",
        name: 'LandingScreen',
        element: <LandingScreen />,
        isPrivate: false
    },

    {
        path: "/upload-csv",
        name: 'UploadCSVScreen',
        element: <UploadCSVScreen />,
        isPrivate: false
    },

    {
        path: "/view-record",
        name: 'ViewEIDDetailsScreen',
        element: <ViewEIDDetailsScreen />,
        isPrivate: false
    },

    {
        path: "/edit-record",
        name: 'EditRecordScreen',
        element: <EditRecordScreen />,
        isPrivate: false
    },

    {
        path: "/create-record",
        name: 'CreateRecordScreen',
        element: <CreateRecordScreen />,
        isPrivate: false
    },

    {
        path: "/csv-records",
        name: 'RecordsScreen',
        element: <RecordsScreen />,
        isPrivate: false
    },

    {
        path: "/records",
        name: 'EIDRecordListScreen',
        element: <EIDRecordListScreen />,
        isPrivate: false
    },

    {
        path: "/profile",
        name: 'ProfileScreen',
        element: <ProfileScreen/>,
        isPrivate: false
    },

    {
        path: "/view-agency-information",
        name: 'AgencyInformationScreen',
        element: <AgencyInformationScreen />,
        isPrivate: false
    },

    {
        path: "/update-agency-information",
        name: 'UpdateAgencyComponent',
        element: <UpdateAgencyInformationComponent />,
        isPrivate: false
    },

    {
        path: "/error-list",
        name: 'ErrorResponseListScreen',
        element: <ErrorResponseListScreen/>,
        isPrivate: true
    },

    {
        path: "/application-form",
        name: 'ApplicationFormScreen',
        element: <ApplicationFormScreen/>,
        isPrivate: false
    },

    {
        path: "/config-eid",
        name: 'ConfigScreen',
        element: <ConfigScreen/>,
        isPrivate: false
    },

    {
        path: "/update-config-eid",
        name: 'UpdateConfigEIDComponent',
        element: <UpdateConfigEIDComponent/>,
        isPrivate: false
    },

    {
        path: "/pre-registration-form",
        name: 'PreRegistrationFormScreen',
        element: <PreRegistrationFormScreen/>,
        isPrivate: false
    },

    {
        path: "/registration-link",
        name: 'RegistrationLinkScreen',
        element: <RegistrationLinkScreen/>,
        isPrivate: false
    },

    {
        path: "/create-agency",
        name: 'CreateAgencyInformationComponent',
        element: <CreateAgencyInformationComponent />,
        isPrivate: false
    },

    {
        path: "/forbidden",
        name: 'ForbiddenScreen',
        element: <ForbiddenScreen />,
        isPrivate: false
    },

    {
        path: "/signatory",
        name: 'SignatoryScreen',
        element: <SignatoryScreen />,
        isPrivate: false
    },

    {
        path: "/view/eID",
        name: 'PDFViewerComponent',
        element: <PDFViewerComponent />,
        isPrivate: false
    },

    {
        path: "/create-signatory",
        name: 'CreateSignatoryScreen',
        element: <CreateSignatoryScreen />,
        isPrivate: false
    },

    {
        path: "/edit-signatory",
        name: 'EditSignatoryScreen',
        element: <EditSignatoryScreen />,
        isPrivate: false
    },
 

]
