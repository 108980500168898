import React, { useEffect } from 'react';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import useEID from '../../hooks/useEID';
import { format } from 'date-fns';
import ActivityLogsSkeletonLoaderComponent from '../skeletons/activity-logs-skeleton-loader.component';

const ActivityLogsComponent = (props) => {

    const { breakpoints, eid } = props;

    const { loading, retrieveActivityLogs, logs } = useEID();

    useEffect(() => {
        let uuid = eid?.uuid;
        retrieveActivityLogs(uuid);
    }, [])

    return (
        <Grid container sx={{ mt: 1, pl: 2, pr: 2 }}>
            <Grid item xs={12}>
                <Box sx={{ border: (breakpoints === "xs" || breakpoints === "sm") ? 'none' : '0.1px solid #3333cf', height: '100%' }}>
                    <Box sx={{ p: 1 }}>
                        {loading ?
                            <ActivityLogsSkeletonLoaderComponent />
                            :

                            <List>
                                {logs?.map((record, index) => (
                                    <ListItem>
                                        <ListItemIcon>
                                            <FiberManualRecordIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={record?.user + ' ' + record?.action + ' ' + "on" + ' ' + format(new Date(record?.created_at), 'PPpp')} />
                                    </ListItem>
                                ))}
                            </List>

                        }

                        {!loading && logs?.length === 0 && (
                            <Grid container spacing={2} sx={{ justifyContent: 'center', alignSelf: 'center' }}>
                                <Typography style={{ color: "rgba(0, 0, 0, 0.54)", fontWeight: 600, fontSize: "14px", marginTop: 50, marginBottom: 30, textAlign: 'center' }}>
                                    No activity logs available to display yet.
                                </Typography>
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ActivityLogsComponent;
