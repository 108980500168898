import React, {useState, useEffect} from "react";
import {Box, Typography} from "@mui/material";
import {CustomTab, CustomTabs} from "../../../common/custom-tabs";
import CreateSignatoryByDrawComponent from "./create-signatory-by-draw.component";
import CreateSignatoryByUploadComponent from "./create-signatory-by-upload.component";
import EditSignatoryByDrawComponent from "./edit-signatory-by-draw.component";
import EditSignatoryByUploadComponent from "./edit-signatory-by-upload.component";

function useSignatoryTabs (breakpoints, url) {
  const [signatureTabs, setSignatureTabs] = useState(null);

  const setupTabs = () => {
    if (url == "/create-signatory") {
      let default_tabs = [
        {name: "Draw Signature", component: <CreateSignatoryByDrawComponent breakpoints={breakpoints} url={url} />},
        {name: "Upload Signature", component: <CreateSignatoryByUploadComponent breakpoints={breakpoints} url={url} />},
      ]

      setSignatureTabs(default_tabs);

    } else {
      let editted_tabs = [
        {name: "Draw Signature", component: <EditSignatoryByDrawComponent breakpoints={breakpoints} url={url} />},
        {name: "Upload Signature", component: <EditSignatoryByUploadComponent breakpoints={breakpoints} url={url} />},
      ]

      setSignatureTabs(editted_tabs);

    }
  }

  useEffect(() => { 
    setupTabs(); 
  }, [])

  return signatureTabs;
}


const SignatoryTabs = (props) => {
  
  const {breakpoints, url} = props;

  const tabs = useSignatoryTabs (breakpoints, url);

  const [value, setValue] = useState(0);

  const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
      
    return (
      <div
        role="tabpanel"
        component="div" 
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && 
          <Box>
            <Typography>
              {children}
            </Typography>
          </Box>
        }
      </div>
    )
  }

  const a11yProps = (index) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  }

  
  return (
    <Box sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? "90%" : "100%", margin: "auto"}}>
      <Box>
        <CustomTabs
          variant={(breakpoints == 'xs' || breakpoints == 'sm') ? "scrollable" : ""}
          value={value} 
          onChange={handleChange} 
          sx={{mt: 2}} 
          TabIndicatorProps={{sx: {backgroundColor: "#3333cf", top: "25px !important", height: "4px !important"}}}
          centered
        >
          {tabs?.map((tab, index) => {
            return (
              <CustomTab label={tab.name} disableRipple {...a11yProps(index)} />
            )
          })}
        </CustomTabs>

        {tabs?.map((tab, index) => {
          return (
            <TabPanel value={value} index={index} style={{padding: "0px"}}>
              {tab.component}
            </TabPanel> 
          ) 
        })}
      </Box>
    </Box>
  )
}

export default SignatoryTabs