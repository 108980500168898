import {Skeleton, Card, CardContent, Grid, CardActions} from "@mui/material";

function ConfigSkeletonComponent(props) {

    const {breakpoints} = props;

    const url = window.location.pathname;


    return(

        <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 2 : 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height:breakpoints == 'xs' || breakpoints == 'sm' ? 'auto' : url !== "/config-eid" ? 450 : 370  }}>
            <CardContent >
                <Grid container>
                    <Grid item xs={12} sm={12} sx={{mt: 2}}>
                        <Grid container spacing={2} >
                                
                            <Grid item xs={12} sm={12} sx={{textAlign: 'start !important', pt: 3}}>
                                <Grid container spacing={2} sx={{marginBottom: 1, mt: 3}}>
                                    <Grid item xs={12} sm={4} >
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>

                                    <Grid item xs={12} sm={8} sx={{marginBottom: 2}}>
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>
                                </Grid> 
                                <Grid container spacing={2} sx={{marginBottom: 3}}>
                                    <Grid item xs={12} sm={4} >
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>
                                </Grid> 
                                <Grid container spacing={2} sx={{mmarginBottom: 3}}>
                                    <Grid item xs={12} sm={4} >
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>
                                </Grid> 
                                <Grid container spacing={2} sx={{mmarginBottom: 3, mt: 0.5}}>
                                    <Grid item xs={12} sm={4} >
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 50%)'}} />
                                    </Grid>
                                </Grid> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            {url !== "/config-eid" ?
                <CardActions sx={{mt: 1.5}}>
                    <Grid container spacing={2} sx={{justifyContent: "end", textAlign: "end"}}>
                        <Skeleton variant="rectangular" height={40} width="15%" style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        
                        &nbsp; &nbsp;

                        <Skeleton variant="rectangular" height={40} width="15%" style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                    </Grid>
                </CardActions>
            :
                null
            }
        </Card>  
           
    )
    
}

export default ConfigSkeletonComponent