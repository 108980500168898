import React, { useState, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { CustomTab, CustomTabs } from "../common/custom-tabs.component";
import ViewEIDDetailsComponent from "./view-eid-details.component";
import ActivityLogsComponent from "./activity-logs.component";

function useViewEIDTabs(props) {

  const { breakpoints, eid, approveEID, loadingApprovalEID, disapproveEID, loadingDisapproveEID, setLoading, signatories } = props;

  const [view_eid_tabs, setEIDTabs] = useState(null);

  function setupTabs() {

    var tabs_default = [
      {
        name: 'Details',
        component:
          <ViewEIDDetailsComponent
            breakpoints={breakpoints}
            eid={eid}
            approveEID={approveEID}
            loadingApprovalEID={loadingApprovalEID}
            disapproveEID={disapproveEID}
            loadingDisapproveEID={loadingDisapproveEID}
            setLoading={setLoading}
            signatories={signatories}
          />
      }, 
      { name: 'Activity Logs', component: <ActivityLogsComponent eid={eid} /> },
    ]

    setEIDTabs(tabs_default);
  }

  useEffect(() => { setupTabs(); }, [])

  return view_eid_tabs;

}


function ViewEIDTabsComponent(props) {

  const { breakpoints, signatories } = props;

  const tabs = useViewEIDTabs(props);

  const [value, setValue] = useState(0);

  useEffect(() => {
    let stringifySignatories = JSON.stringify(signatories);

    localStorage.setItem("savedSignatoriesList", stringifySignatories);
  }, [signatories])

  function TabPanel(props) {

    const { children, value, index, ...other } = props;


    return (
      <div
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Box sx={{ width: '100%', margin: 'auto', marginRight: 0 }}>
      <Box sx={(breakpoints == 'xs' || breakpoints == 'md') ? null : { flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
        <CustomTabs
          orientation={(breakpoints == 'xs' || breakpoints == 'md') ? "horizontal" : "vertical"}
          variant={(breakpoints == 'xs' || breakpoints == 'md') ? "scrollable" : ""}
          value={value}
          onChange={handleChange}
          indicatorColor="transparent"
          sx={(breakpoints == 'xs' || breakpoints == 'md') ? { borderColor: 'divider' } : { marginTop: 2, borderRight: 2, borderColor: 'divider', width: 200 }}
        >
          {tabs?.map((tab, index) => {
            return (<CustomTab label={<Typography sx={{ textAlign: 'start' }}>{tab.name}</Typography>} disableRipple {...a11yProps(index)} sx={{ alignItems: 'flex-start !important', mb: 1 }} />)
          })}
        </CustomTabs>
        {tabs?.map((tab, index) => {
          return (
            <TabPanel value={value} index={index} style={{ padding: "0px", width: '100%' }}>
              {tab.component}
            </TabPanel>
          )
        })}
      </Box>
    </Box>
  )
}

export default ViewEIDTabsComponent;