import React from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {Toolbar, Button} from "@mui/material";
import logo from "../icons/nextix.png"
import useWidth from "../hooks/useWidth";

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),

        ...(open && {
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));


const CustomHeader = (props) => {

    const {open} = props;

    const breakpoints = useWidth();

    return (
        <AppBar position="fixed" open={open} sx={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? "100%" : {}, backgroundColor: "#fff", height: 64, boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}>
            <Toolbar>
                <DrawerHeader>
                    <Button variant="text" disableElevation sx={{textTransform: "none", mr: "auto", ...(!open && {display: "none"}),
                        color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                    >
                        <img alt="" src={logo} style={{cursor: "default", height: (breakpoints == 'xs' || breakpoints == 'sm') ? 25 : 30}}/>
                    </Button>
                </DrawerHeader>
            </Toolbar>
        </AppBar>
    )
}

export default CustomHeader