import {Skeleton, Card, CardContent, Grid} from "@mui/material";


function RegistrationLinkSkeletonComponent(props) {

    const {breakpoints} = props;



    return(
        <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, paddingTop: 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : 550}}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}  sx={{alignSelf: "center"}}>
                        <Grid container  spacing={2}>
                            <Grid item xs={10} sx={{alignSelf: 'center', textAlign: 'start'}}>
                                <Grid container  spacing={2}>
                                    <Grid item xs={12} sm={6} sx={{alignSelf: 'center', textAlign: 'start'}}>
                                        <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} sx={{alignSelf: 'center', textAlign: breakpoints == 'xs' || breakpoints == 's' ? 'start' : 'end'}}>
                                        <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={11} sm={10} sx={{alignSelf: 'center', textAlign: 'start'}}>
                                <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                            <Grid item xs={1} sm={2} sx={{alignSelf: 'center', justifyContent: 'center', alignItems: 'center'}}>
                                <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}  sx={{alignSelf: "center"}}>
                        <Grid container  spacing={2}>
                            <Grid item xs={5}  sx={{alignSelf: "center", textAlign: 'start'}}>
                                <Skeleton variant="rectangular" height={150} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                            <Grid item xs={7}  sx={{alignSelf: "center"}}></Grid>
                            <Grid item xs={3}  sx={{alignSelf: "center"}}>
                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}  sx={{alignSelf: "center"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}  sx={{alignSelf: "center"}}>
                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                            <Grid item xs={10}  sx={{alignSelf: "center"}}>
                                <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2}  sx={{alignSelf: "center", textAlign: 'end', justifyContent: 'flex-end', marginLeft: 'auto'}}>
                        <Skeleton variant="rectangular" height={50} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                    </Grid>
                </Grid>  
            </CardContent>
        </Card>  
        
    )
    
}

export default RegistrationLinkSkeletonComponent