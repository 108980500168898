import {Box, Grid, Typography} from "@mui/material";
import useWidth from "../hooks/useWidth";
import ViewConfigEIDComponent from "../components/agency-information/view-config-eid.component";


function Header () {
    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={12}>
                    <Typography variant="h6" sx={{fontWeight: "bold"}}>
                        Config eID
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}


function ConfigScreen() {

    const breakpoints = useWidth();

    return(

        <Box sx={{overflow: "hidden", position: 'relative', width: breakpoints == 'xs' || breakpoints == 's' ? "100%" :'95%', margin:'auto'}}>

            <Header />

            <Box style={{flexGrow: 1, margin:'auto'}}>
                <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        <ViewConfigEIDComponent  breakpoints={breakpoints} />
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
    
}

export default ConfigScreen