import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {ThreeDots} from 'react-loader-spinner';
import usePreRegistration from "../../hooks/usePreRegistration";

const RegenerateLinkDialog = (props) => {

    const {openRegenerate, handleCloseRegenerate} = props;

    const { regenerateRegistrationLink , generating } = usePreRegistration();

    const [expiration_days, setExpiration] = useState(1);

    const handleTextChange = (e) => {
        setExpiration(e.target.value);
    }

    const handleGenerateLink = () => {
        regenerateRegistrationLink(expiration_days);
    }


    return(
        <Dialog open={openRegenerate} onClose={handleCloseRegenerate} handleCloseRegenerate={handleCloseRegenerate} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Regenerate Link Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography sx={{fontStyle: 'italic', color: 'orange'}}>
                    Note : The previous link is no longer accessible.
                </Typography>

                <br/>

                <Grid item xs={12}  sx={{alignSelf: "center"}}>
                    <Grid container>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            Expiration in Days
                        </Grid>
                        <Grid item xs={12}  sx={{alignSelf: "center"}}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                type="number"
                                value={expiration_days}
                                onChange={(e) => handleTextChange(e)}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        "& > fieldset": { borderColor: "#3333cf4f !important" },
                                    },
                                    fontSize: 16
                                }} 
                            />
                        </Grid>
                    </Grid>
                </Grid>


            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, boxShadow: 'none', borderColor: '#3333cf', color : '#3333cf', ":hover": {borderColor: '#b5b5b5', color: '#b5b5b5' } }}
                    onClick={(e) => handleCloseRegenerate(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: 'auto', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none', ":hover": {backgroundColor: '#b5b5b5' } }}
                    onClick={(e) => handleGenerateLink(e)}
                    startIcon={generating && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    { generating ? "Regenerating" : "Generate" }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RegenerateLinkDialog