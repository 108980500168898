import "./App.css";
import {history} from "./router/history.js";
import {BrowserRouter} from "react-router-dom"; 
import Routes from "./router";
import MainScreen from "./layout/navbar";
import LandingScreen from "./screens/LandingScreen";
import ApplicationFormScreen from "./screens/ApplicationFormScreen";
import AuthService from "./services/auth";
import {MsalProvider} from "@azure/msal-react";
import PreRegistrationFormScreen from "./screens/PreRegistrationFormScreen";
import ForbiddenScreen from "./screens/ForbiddenScreen";
import PDFViewerScreen from "./screens/PDFViewerScreen";



const App = ({publicClientApp}) => {

  const url = window.location.pathname;
  var isUserLoggedIn = AuthService.isUserLoggedIn();


  return (
    <div>
      <MsalProvider instance={publicClientApp}>
        <BrowserRouter history={history}>
          {(isUserLoggedIn) ? 
            <>
              {(url !== "/" && url !== "/application-form" && url !== "/pre-registration-form" && url !== "/forbidden"  && url !== "/view/pdf") ?
                <MainScreen>
                  <Routes /> 
                </MainScreen>
              :    
                (url == "/application-form") ?
                  <ApplicationFormScreen />
              :  
                (url == "/pre-registration-form") ?
                  <PreRegistrationFormScreen />
              :
                (url == "/forbidden") ?
                <ForbiddenScreen />
              :
                (url == "/view/pdf") ?
                  <PDFViewerScreen />
              :            
                <LandingScreen/>                   
              }
            </>
          :   
            (url == "/application-form") ?
            <ApplicationFormScreen />
          :
            (url == "/pre-registration-form") ?
            <PreRegistrationFormScreen />
          :

            (url == "/forbidden") ?
                <ForbiddenScreen />
          :
            (url == "/view/pdf") ?
            <PDFViewerScreen />
          :
            <LandingScreen/>
          }
        </BrowserRouter>
      </MsalProvider> 
    </div>
  )
}

export default App
