import React, {useEffect} from "react";
import useWidth from "../hooks/useWidth";
import useSignatories from "../hooks/useSignatories";
import {Box, Grid, Typography, Button} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CreateSignatoryComponent from "../components/signatory/create-signatory.component";

const CreateSignatoryScreen = () => {

    const url = window.location.pathname;

    const breakpoints = useWidth();

    const {retrieveSignatories, signatories} = useSignatories();
    
    useEffect(() => {
        retrieveSignatories();

        if (signatories.length === 3) {
            window.location.href = "/";
        }
    }, [])


    function Header ({signatories, breakpoints}) {
        return (
            <Grid sx={{padding: "10px 20px 0px 20px", width: "100%", borderRadius: 0}}>
                <Button startIcon={
                    <ArrowBackIosIcon style={{width: 20, height: 20, color: "#3333cf"}} />} 
                        style={{color: "black", fontSize: 16, textTransform: "none", fontWeight: 600}}
                        onClick={() => window.location.href = "/signatory"} 
                    >

                    {(signatories?.length === 0) ?
                        <Typography variant="h6" sx={{fontWeight: "bold", textAlign: "start", fontSize: 16}}>
                                Create Signatory
                        </Typography>
                    :
                        (signatories?.length < 3 && signatories?.length !== 0) ?
                            <Typography variant="h6" sx={{fontWeight: "bold", textAlign: "start", fontSize: 16}}>
                                    Add Signatory
                            </Typography>
                    : 
                        null
                    }
                </Button>
            </Grid>
        )
    }

    return (
        <>
            {(signatories?.length === 3) ?
                null
            :
       
                <Box sx={{overflow: "hidden", width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "95%", margin: "auto"}}>
                    <Header breakpoints={breakpoints} signatories={signatories} />

                    <CreateSignatoryComponent url={url} breakpoints={breakpoints} signatories={signatories} />
                </Box>
            }
        </>
    ) 
}

export default CreateSignatoryScreen