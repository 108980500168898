import React, {useState, useRef, useEffect} from "react";
import {Stack, Slider, IconButton, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import {convertUrlToFile} from "../../../utils/convertUrlToFileUtil";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {ThreeDots} from "react-loader-spinner";
import useSignatories from "../../../hooks/useSignatories";

const EditSignatoryCropperDialog = (props) => {

    const {breakpoints, openSigEditor, setOpenSigEditor, signatureFile, setSignatureFile, 
        isByDraw, setByDraw, signatoryName, isSignatoryDefault, documentId, signatoryPosition, tempSignatureFile,
    } = props;

    const editorRef = useRef();
    const {isUpdatingSignatory, updateSignatory} = useSignatories();

    const [imageToCrop, setImageToCrop] = useState(null);
    const [zoomValue, setZoomValue] = useState(0.5);
    const [isZoomInDisabled, setZoomInDisabled] = useState(false);
    const [isZoomOutDisabled, setZoomOutDisabled] = useState(false);

    useEffect(() => {
        if (tempSignatureFile) {
            setImageToCrop(tempSignatureFile);

        } else {
            setImageToCrop(signatureFile);
        }
    }, [tempSignatureFile])

    const handleCloseDialog = (e) => {
        if (isByDraw) {
            setByDraw(false);
            setOpenSigEditor(false);

        } else {      
            setOpenSigEditor(false);
            setImageToCrop(null);
        }
    }

    function removeBackground(imageUrl, callback) {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.src = imageUrl;
      
        image.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = image.width;
          canvas.height = image.height;
      
          ctx.drawImage(image, 0, 0);
      
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const data = imageData.data;
      
          // Iterate through each pixel
          for (let i = 0; i < data.length; i += 4) {
            const red = data[i];
            const green = data[i + 1];
            const blue = data[i + 2];
      
            // Check if the pixel is close to white
            if (red >= 200 && green >= 200 && blue >= 200) {
              // Set alpha to 0 (transparent)
              data[i + 3] = 0;
            }
          }
      
          ctx.putImageData(imageData, 0, 0);
      
          // Convert canvas image to Data URL
          const editedImageUrl = canvas.toDataURL('image/png');
      
          // Invoke the callback function with the edited image URL
          callback(editedImageUrl);
        };
      
        image.onerror = function () {
          throw new Error('Failed to load the image.');
        };
      }

    const handleSave = (e) => {
        let formData = new FormData();

        const canvas = editorRef.current.getImageScaledToCanvas();
        const base64Image = canvas.toDataURL();

        removeBackground(base64Image, function (editedImageUrl) {
            convertUrlToFile(editedImageUrl, "edited_signature_file.png", "image/png").then(function (editedSignatureFile) {
                setSignatureFile(editedSignatureFile);

                formData.append("document_id", documentId);
                formData.append("file", editedSignatureFile);
                formData.append("name", signatoryName);
                formData.append("position", signatoryPosition);
                formData.append("is_default", isSignatoryDefault);
                
                updateSignatory(formData, setOpenSigEditor);
            });
        });
    }

    const handleSliderChange = (event, newValue) => {
        let minValue = 0.5;
        let maxValue = 10;

        if ((zoomValue == minValue) || (zoomValue < minValue)) {
            setZoomInDisabled(true);
            setZoomOutDisabled(false);

        } else if ((zoomValue == maxValue) || (zoomValue > maxValue)) {
            setZoomOutDisabled(true);
            setZoomInDisabled(false);

        } else {
            setZoomInDisabled(false);
            setZoomOutDisabled(false);
        }

        setZoomValue(newValue);
    }

    const handleZoomIn = () => {
        let minValue = 0.5;

        setZoomOutDisabled(false);

        if ((zoomValue == minValue) || (zoomValue < minValue)) {
            setZoomInDisabled(true);

        } else {
            setZoomInDisabled(false);
            setZoomValue(zoomValue - 0.1);
        }
    }

    const handleZoomOut = () => {
        let maxValue = 10;

        setZoomInDisabled(false);

        if ((zoomValue == maxValue) || (zoomValue > maxValue)) {
            setZoomOutDisabled(true);

        } else {
            setZoomOutDisabled(false);
            setZoomValue(zoomValue + 0.1);
        }
    }

 
    return (
        <Dialog open={openSigEditor} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 10}}}
            onClose={(e) => handleCloseDialog(e)}
        >
            <DialogTitle sx={{fontWeight: "bold"}}>
                Crop Signature
            </DialogTitle>

            <Divider/>

            <DialogContent sx={{padding: "24px 24px 10px"}}>
                <>
                    <Box sx={{objectFit: "contain", mt: -1, textAlign: "center", zoom: (imageToCrop && (breakpoints == "md" || breakpoints == "lg" || breakpoints == "xl")) ? "40%" : (imageToCrop && (breakpoints == "xs" || breakpoints == "sm")) ? "15%" : {}}}>
                        <div style={{backgroundColor: "rgb(211 211 211 / 55%)", width: "max-content", margin: "auto"}}>
                            <AvatarEditor
                                ref={editorRef}
                                image={imageToCrop}
                                border={50}
                                color={[179, 215, 243, 0.4]}
                                rotate={0}
                                width={1200}
                                height={600}
                                scale={zoomValue}
                                crossOrigin="anonymous"
                            />
                        </div>
                    </Box>

                    <Stack spacing={2} direction="row" alignItems="center" sx={{margin: (breakpoints == "xs" || breakpoints == "sm") ? {} : "16px auto 0px"}}>
                        <IconButton onClick={(e) => (isZoomInDisabled) ? null : handleZoomIn()} 
                            sx={{cursor: (isZoomInDisabled) ? "no-drop" : "pointer"}}
                        >
                            <RemoveIcon fontSize="small" sx={{color: (isZoomInDisabled) ? "#8080808f" : "#000"}} />
                        </IconButton>

                        <Slider 
                            size="large"
                            value={zoomValue} 
                            onChange={handleSliderChange}
                            aria-label="Default"
                            min={0.5}
                            max={10}
                            step={0.1}
                            disableBoundaryChecks={false}
                        />

                        <IconButton onClick={(e) => (isZoomOutDisabled) ? null : handleZoomOut()} 
                            sx={{cursor: (isZoomOutDisabled) ? "no-drop" : "pointer"}}
                        >
                            <AddIcon fontSize="small" sx={{color: (isZoomOutDisabled) ? "#8080808f" : "#000"}} />
                        </IconButton>
                    </Stack>
                </>
            </DialogContent>
            
            <DialogActions sx={{padding: 6, paddingTop: 3, paddingBottom: 3, paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                <Button sx={{width: "15%", borderRadius: 10, color: "#0178f2", textTransform: "none", fontSize: 14}} variant="outlined"
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{width: (isUpdatingSignatory) ? "auto" : "18%", backgroundColor: "#0178f2", borderRadius: 10, textTransform: "none", 
                    fontSize: 14, '&:hover': {backgroundColor: "#0178f2", color: "white"}}}
                    onClick={(e) => (imageToCrop) ? handleSave(e) : null}
                    startIcon={isUpdatingSignatory && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {(isUpdatingSignatory) ? "Updating" : "Update"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditSignatoryCropperDialog