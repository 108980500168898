import {Avatar, CardContent, Typography} from "@mui/material";
import {stringAvatar} from "../../utils/stringAvatarUtil";

const ProfileComponent = (props) => {

    const {breakpoints, user} = props;

    return (
        <CardContent>
            <Avatar 
                {...stringAvatar(user?.firstname ? user?.firstname : user?.email)}
                sx={{width: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 100, height: (breakpoints == "xs" || breakpoints == "sm") ? 80 : 100, 
                    fontSize: (breakpoints == "xs" || breakpoints == "sm") ? 24 : 30, margin: "auto", backgroundColor: "#3333cf", backgroundSize: "cover", "&.MuiAvatar-img": {borderRadius: "50% !important"}
                }} 
            />
               
            <Typography sx={{fontWeight:'bold', marginTop: 2}}>
                {user?.firstname ? user?.firstname : null}
            </Typography>

            <Typography sx={{marginTop: 1}}>
                <strong>Email:</strong>  {user?.email}
            </Typography>
        </CardContent>
    )
}

export default ProfileComponent