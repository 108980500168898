import React, { useEffect, useState } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import useWidth from "../hooks/useWidth";
import useCSV from "../hooks/useCSV";
import ViewEIDDetailsComponent from "../components/csv/view-eid-details.component";
import useEID from "../hooks/useEID";
import ViewEIDDetailsSkeletonComponent from "../components/skeletons/view-eid-details-skeleton.component";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ViewEIDTabsComponent from "../components/csv/view-eid-tab.component";
import useSignatories from "../hooks/useSignatories";
import { useNavigate } from "react-router-dom";

function BackButton(props) {

    const { eid } = props;
    const navigate = useNavigate();

    const handleBackButtonClick = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const storedPage = urlParams.get("page");
        const initialPage = storedPage ? parseInt(storedPage) : 1;
        // navigate("/records?page=" + initialPage);
        window.location.href = '/records?page=' + initialPage
    };

    return <Button startIcon={
        <ArrowBackIosIcon style={{ width: 20, height: 20, color: "#3333cf" }} />}
        style={{ color: "black", fontSize: 16, textTransform: "none", fontWeight: 600 }}
        onClick={handleBackButtonClick}
    >
        {eid?.firstname} {eid?.middlename} {eid?.lastname} {eid?.suffix} eID
    </Button>
}

const ViewEIDDetailsScreen = () => {

    const breakpoints = useWidth();
    const { eidDetails, vieweIDDetails, isFetchingDetails, setFetchingDetails } = useCSV();
    const { approveEID, loadingApprovalEID, disapproveEID, loadingDisapproveEID } = useEID();
    const { retrieveSignatories, signatories } = useSignatories();

    const [expanded, setExpanded] = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const uuid = (new URLSearchParams(window.location.search)).get("id");

    useEffect(() => {
        vieweIDDetails(uuid);
    }, [])

    useEffect(() => {
        retrieveSignatories();
    }, [])


    return (
        <Box sx={{ overflow: "hidden", width: breakpoints == 'xs' || breakpoints == 's' ? '100%' : "95%", margin: "auto", mt: (breakpoints == "xs" || breakpoints == "sm") ? "0px" : {} }}>
            <Box style={{ flexGrow: 1, margin: "auto" }}>
                <Grid container spacing={2} direction="row" sx={{ padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2 }}>
                    {isFetchingDetails ? <></> : <Grid item xs={12} sm={12} sx={{ pt: 0 }}><BackButton eid={eidDetails} /></Grid>}
                    <Grid item xs={12} sm={12} sx={{ pt: 0 }}>
                        <Card sx={{ borderRadius: 2, padding: breakpoints === 'xs' || breakpoints === 's' ? 1 : 2, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                            {isFetchingDetails ?
                                <ViewEIDDetailsSkeletonComponent breakpoints={breakpoints} />
                                :
                                <ViewEIDTabsComponent
                                    breakpoints={breakpoints}
                                    eid={eidDetails}
                                    approveEID={approveEID}
                                    loadingApprovalEID={loadingApprovalEID}
                                    disapproveEID={disapproveEID}
                                    loadingDisapproveEID={loadingDisapproveEID}
                                    setLoading={setFetchingDetails}
                                    signatories={signatories}
                                />
                            }
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default ViewEIDDetailsScreen