const clearLocalStorage = () => {
    const keys = Object.keys(localStorage);

    for (const key of keys) {
      localStorage.removeItem(key);
    }
}

export {
    clearLocalStorage
}
