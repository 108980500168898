import { Button, ButtonGroup, TableCell, TableRow } from "@mui/material"
import SendAppBindingDialog from "../dialogs/send-app-binding-dialog";
import { useEffect, useState } from "react";
import DownloadEIDDialog from "../dialogs/download-eID-dialog";
import { format } from 'date-fns';
import useEID from "../../hooks/useEID";
import { ThreeDots } from 'react-loader-spinner';
import DeleteEIDDialog from "../dialogs/delete-eID-dialog";
import useWidth from "../../hooks/useWidth";
import useSignatories from "../../hooks/useSignatories";
import CreateSignatoryDialog from "../dialogs/create-signatory.dialog";
import SelectSignatoryDialog from "../dialogs/select-signatory.dialog";

function RecordsListComponent(props) {

    const { record, setLoading, page } = props;
    
    const breakpoints = useWidth();

    const { generateEID, generatingEID, generated } = useEID();
    const {retrieveSignatories, signatories} = useSignatories();

    const [openDownload, setOpenDownload] = useState(false);
    const [openSendAppBinding, setOpenAppBinding] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [isResend, setResend] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    // const [status, setStatus] = useState(record?.status); // Add status state variable
    const [status, setStatus] = useState(record?.status || generated?.status);
    const [is_instruction_sent, setIsInstructionSent] = useState(record?.instruction_is_sent); // Add instruction_is_sent state variable

    const [recordId, setRecordId] = useState(null);
    const [openSelectSignatory, setOpenSelectSignatory] = useState(false);
    const [openCreateSignatory, setOpenCreateSignatory] = useState(false);
    const [isNoAvailableSignatories, setNoAvailableSignatories] = useState(false);

    useEffect(() => {
        retrieveSignatories();
    }, [])

    useEffect(() => {
        const storedSignatories = localStorage.getItem("savedSignatoriesList");
        const parsedSignatories = JSON.parse(storedSignatories);

        if (signatories?.length !== 0 || parsedSignatories?.length !== 0) {
            setNoAvailableSignatories(false);

        }  else {
            setNoAvailableSignatories(true);
        }
    }, [signatories])


    const handleOpenSendAppBinding = () => {
        setOpenAppBinding(true);
    }

    const handleOpenResendAppBinding = () => {
        setResend(true);
        setOpenAppBinding(true);
    }

    const handleCloseAppBinding = () => {
        setOpenAppBinding(false);
    }

    const handleOpenDownload = () => {
        setOpenDownload(true);
    };

    const handleCloseDownload = () => {
        setOpenDownload(false);
    };

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleGenerateEID = (e, uuid) => {
        setRecordId(uuid);

        if (isNoAvailableSignatories) {
            setOpenCreateSignatory(true);

        } else {
            setOpenSelectSignatory(true);
        }
    }

    useEffect(() => {
        if (generated) {
            setLoading(false); // Set the loading state to false

            // Check if the eID is generated for the current record
            if (record?.uuid === record?.uuid) {
                // Call the updateStatus function passed as a prop
                setStatus("Authentication");
            }

        }
    }, [generated, setLoading, record, setStatus]);


    const handleRecordClick = (record) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('page', page);
        const newURL = `/view-record?id=${record.uuid}&${urlParams.toString()}`;
        window.location.href = newURL;
    };



    return (
        <>
            {!isDeleted && (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                    <TableCell sx={{ color: "#3333cf", fontWeight: 530, cursor: "pointer" }} component="th" scope="row"
                        // onClick={(e) => window.location.href = "/view-record?id=" + record.uuid}
                        onClick={() => handleRecordClick(record)}
                    >
                        {record.id_no}
                    </TableCell>

                    <TableCell align="left" >
                        {record.lastname} &nbsp;  {record.suffix}
                    </TableCell>

                    <TableCell align="left" >
                        {record.firstname}
                    </TableCell>

                    <TableCell align="left" >
                        {record.middlename ? record.middlename : 'NA'}
                    </TableCell>

                    <TableCell align="left" >
                        {format(new Date(record?.dob), 'PP')}
                    </TableCell>

                    <TableCell align="left"
                        sx={{
                            fontWeight: 530,
                            color: (status == "Pending") ? "red"
                                :
                                (status == "Pending for Approval" || status == "Suspended") ? "#ff8c00"
                                    :
                                    (status == "Approved" || status == "Completed") ? "green"
                                        :
                                        (status == "Authentication") ? "#0178f2"
                                            :
                                            (status == "Reject" || status == "Revoked") ? "red"
                                                :
                                                "#404040"
                        }}
                    >
                        {status}
                    </TableCell>

                    <TableCell align="left">
                        <ButtonGroup variant="text" aria-label="text button group" >
                            {status == 'Reject' ?
                                <Button sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }}
                                    onClick={(e) => window.location.href = "/edit-record?id=" + record?.uuid}
                                >
                                    Edit
                                </Button>
                                :
                                null
                            }

                            {status === 'New' || status === 'Pending' ?
                                (is_instruction_sent ?
                                    <Button sx={{ color: '#3333cf', textTransform: 'none', textAlign: 'start', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }}
                                        onClick={(e) => handleOpenResendAppBinding(e)}
                                    >
                                        Resend
                                    </Button>
                                    :
                                    <Button sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }}
                                        onClick={(e) => handleOpenSendAppBinding()}
                                    >
                                        Send App Binding
                                    </Button>
                                )
                                :
                                null
                            }

                            {status == "New" || status == 'Pending' ?
                                <Button sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }} onClick={(e) => handleOpenDelete(e)} >
                                    Delete
                                </Button>
                                :
                                null
                            }

                            {status == "Approved" &&
                                <Button sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }}
                                    onClick={(e) => handleGenerateEID(e, record?.uuid)}
                                    startIcon={generatingEID && <ThreeDots height="20" width="20" radius="9" color="#0178f2" ariaLabel="three-dots-loading" visible={true} />}
                                >
                                    Generate eID
                                </Button>
                            }

                            {status == "Pending for Approval" &&
                                <Button onClick={() => window.location.href = "/view-record?id=" + record.uuid} sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }} >
                                    Approve
                                </Button>
                            }

                            {status == "Pending for Approval" &&
                                <Button onClick={() => window.location.href = "/view-record?id=" + record.uuid} sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }} >
                                    Reject
                                </Button>
                            }

                            {status == 'Completed' &&
                                <Button sx={{ color: '#3333cf', textTransform: 'none', borderColor: '#00000033 !important', pt: 0, pb: 0, cursor: "pointer" }} onClick={(e) => handleOpenDownload(e)} >
                                    Download
                                </Button>
                            }
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
            )}

            {openSendAppBinding &&
                <SendAppBindingDialog
                    openAppBinding={openSendAppBinding}
                    handleClose={handleCloseAppBinding}
                    handleCloseAppBinding={handleCloseAppBinding}
                    record={record}
                    isResend={isResend}
                    setLoading={setLoading}
                    updateStatus={setStatus} // Pass setStatus function as prop
                    updateInstruction={setIsInstructionSent}
                />
            }

            {openDownload &&
                <DownloadEIDDialog
                    openDownload={handleOpenDownload}
                    handleClose={handleCloseDownload}
                    handleCloseDownload={handleCloseDownload}
                    record={record}
                    setLoading={setLoading}
                />
            }

            {openDelete &&
                <DeleteEIDDialog
                    openDelete={handleOpenDelete}
                    handleClose={handleCloseDelete}
                    handleCloseDelete={handleCloseDelete}
                    record={record}
                    setLoading={setLoading}
                    setIsDeleted={setIsDeleted}
                />
            }

            {openSelectSignatory &&
                <SelectSignatoryDialog 
                    breakpoints={breakpoints} 
                    recordId={recordId} 
                    signatories={signatories}
                    openSelectSignatory={openSelectSignatory} 
                    setOpenSelectSignatory={setOpenSelectSignatory} 
                />
            }

            {openCreateSignatory &&
                <CreateSignatoryDialog 
                    breakpoints={breakpoints} 
                    openCreateSignatory={openCreateSignatory} 
                    setOpenCreateSignatory={setOpenCreateSignatory}
                />
            }
        </>
    )

}

export default RecordsListComponent