import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";
import { useEffect } from "react";
import {ThreeDots} from 'react-loader-spinner';
import usePreRegistration from "../../hooks/usePreRegistration";


const ResetRegistrationLinkDialog = (props) => {

    const {openReset, handleCloseReset} = props;

    const { resetRegistrationLink, reseting, reseted } = usePreRegistration();

    const handleCloseResetDialog = (e) => {
        handleCloseReset();
    }

    const handleReset = () => {
        resetRegistrationLink();
    }

    useEffect(() => {
        if (reseted) {
            handleCloseResetDialog();
            window.location.reload();
        }
    }, [reseted])


    return(
        <Dialog open={openReset} onClose={handleCloseReset} handleCloseReset={handleCloseReset} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
              Reset Registration Link Confirmation
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: 6, paddingTop: 2}}>
                <Typography >
                    Are you sure you want reset registration link?
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="outlined" sx={{textTransform: "none", width: 'auto', borderRadius: 10, color: "#3333cf" }} 
                    onClick={(e) => handleCloseResetDialog(e)}
                >
                    Cancel
                </Button>

                <Button variant="contained" sx={{textTransform: "none", width: 'auto', backgroundColor: '#168401', borderRadius: 10, boxShadow: 'none','&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                    onClick={(e) => handleReset(e)}
                    startIcon={reseting && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                >
                    {reseting ? "Resetting" : "Reset"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ResetRegistrationLinkDialog