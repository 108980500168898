import React from "react";
import {Skeleton, Button, Box, Grid, ListItem, ListItemText} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ViewEIDDetailsSkeletonComponent = (props) => {

    const {breakpoints} = props;

   

    return (
        <>
            <Box sx={{border: "none"}}>
                <Grid container sx={{mt: 1}}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{paddingLeft: "16px"}}>
                     

                        {/* display if mobile view */}
                        <Grid container sx={{paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? "14px" : {}, mt: 3, display: (breakpoints == "xs" || breakpoints == "sm") ? "visible" : "none"}}>
                            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} 
                                sx={{display: "flex", justifyContent: "center", ml:  "0px"}}
                            >
                                <Skeleton variant="text" height={30} width="100%" style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                            </Grid>
                        </Grid>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />  
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />  
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />  
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />  
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />  
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />   
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} /> 
                                }         
                            />
                        </ListItem>

                        <ListItem>
                            <ListItemText
                                primary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                } 
                                secondary={
                                    <Skeleton variant="text" height={30} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} /> 
                                }         
                            />
                        </ListItem>
                    </Grid>

                    {/* display if desktop view */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{mb: 3}}>
                        <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
                            <Grid container sx={{mt: (breakpoints == "xs" || breakpoints == "sm") ? 2 : "66px", mr: (breakpoints == "xs" || breakpoints == "sm") ? "-18px" : "-70px"}}>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, mt: -1, justifyContent: "center", textAlign: "center"}}>
                                    <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 125 : 160} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Skeleton variant="text" height={30} width="55%" style={{backgroundColor: "rgb(108 139 222 / 30%)", margin: "auto"}} />
                                    </Grid>
                                </Grid>

                                &nbsp; &nbsp; &nbsp; &nbsp;

                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, mt: (breakpoints == "xs" || breakpoints == "sm") ? {} : -1, justifyContent: "center", textAlign: "center"}}>
                                    <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 125 : 160} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Skeleton variant="text" height={30} width="55%" style={{backgroundColor: "rgb(108 139 222 / 30%)", margin: "auto"}} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Box>
        </>
    )
}

export default ViewEIDDetailsSkeletonComponent