
import React, {useState} from "react";
import {Box, Grid, Card, CardContent,Typography, TextField, CardActions, Button} from "@mui/material";
import {useNavigate} from "react-router";
import useWidth from "../../hooks/useWidth";
import useAgency from "../../hooks/useAgency";
import {ThreeDots} from 'react-loader-spinner';
import {useSnackbar} from "notistack";

const CreateAgencyInformationComponent = () => {

    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const breakpoints = useWidth();
    const {isCreatingAgency, createAgency} = useAgency();

    const [agencyName, setAgencyName] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [logoPreview, setLogoPreview] = useState(null);
    const [isChangingLogo, setChangingLogo] = useState(false);
    const [logoFile, setLogoFile] = useState(null);
    const [isAgencyNameRequired, setAgencyNameRequired] = useState(false);
    const [isDescriptionRequired, setDescriptionRequired] = useState(false);

    const handleNameChange = (e) => {
        setAgencyName(e.target.value);
        setAgencyNameRequired(false);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        setDescriptionRequired(false);  
    }

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    }


    const validateLogo = (e) => {
        var fileName = document.getElementById("upload-logo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            setChangingLogo(true);
            setLogoFile(e.target.files[0]);
            setLogoPreview(URL.createObjectURL(e.target.files[0]));
 
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const handleClearLogo = () => {
        setLogoPreview(null);
    }

    const handleAddAgencyInfo = (e) => {
        let formData = new FormData();

        formData.append("logo", logoFile);
        formData.append("name", agencyName);
        formData.append("description", description);
        formData.append("website", website);

        if (!agencyName) {
            setAgencyNameRequired(true);

        } else if (!description) {
            setDescriptionRequired(true);

        } else {
            setAgencyNameRequired(false);
            setDescriptionRequired(false);

            createAgency(formData);
        }
    }


    function Header () {
        return (
            <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
                <Grid container sx={{textAlign: "start", ml: 2}}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>
                            Create Agency Information
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }


    return (
        <>
            <Box style={{flexGrow: 1, width: (breakpoints == "xs" || breakpoints == "sm") ? "100%" : "95%", margin: "auto"}}>
            
                <Header />

                <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                        <Card sx={{borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',  height: breakpoints == 'xs' || breakpoints == 's' ? 'auto' : 580}}>
                            <CardContent>
                                <Grid container direction="row" sx={{justifyContent: 'center', alignSelf: 'center'}}>
                                    <Grid item xs={12} sx={{alignSelf: 'center', margin: 'auto', textAlign: 'center'}}>
                                        <Grid container sx={{mb: 1, mt: 1, alignItems: 'center', justifyContent: breakpoints == 'xs' || breakpoints == 's' ? 'center' : 'flex-end'}}>
                                            <Grid item xs={12} sm={6} sx={{textAlign: 'end'}}> 
                                                {logoPreview ?
                                                    <img alt="" src={logoPreview} style={{height: 90, objectFit: "contain"}} />
                                                :
                                                    <p>
                                                        {null}
                                                    </p>
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={6} sx={{textAlign: 'start'}}>
                                                <Grid container sx={{justifyContent: 'center'}}>
                                                    {isChangingLogo ?
                                                        <>
                                                            <Grid item xs={6} >
                                                                <Button variant="outlined" onClick={ (e) => [handleClearLogo(e), setChangingLogo(false)]}
                                                                    sx={{color: "#3333cf", borderColor: "#3333cf", width: "auto", borderRadius: 10, textTransform: "none",
                                                                        '&:hover': {color: "#3333cf", borderColor: "#3333cf"}
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    :
                                                        <Grid item xs={6} >
                                                            <Button variant="contained"
                                                                sx={{
                                                                    width: "auto", backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none',  textTransform: 'none',
                                                                    '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                                                                }}
                                                            >
                                                                <input 
                                                                    type="file" 
                                                                    accept="image/*" 
                                                                    id="upload-logo"
                                                                    onChange= {(e) => validateLogo(e)}
                                                                    hidden
                                                                />

                                                                <label style={{cursor: "pointer"}} htmlFor="upload-logo">
                                                                    Browse Logo 
                                                                </label>
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sx={{mt: 4}}>
                                        <Grid container spacing={2} sx={{marginBottom: 1, alignItems: 'center'}}>
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight: 600}}>
                                                    Agency Name:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={8}>
                                                <TextField
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    onChange={handleNameChange}  
                                                    autoComplete="off"
                                                    value={agencyName}
                                                    helperText={
                                                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                                            {(isAgencyNameRequired) && "Agency Name is required"}
                                                        </p>
                                                    }
                                                    sx={{"& .MuiOutlinedInput-root": {
                                                        "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                    }
                                                }}
                                                />
                                            </Grid> 
                                        
                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight: 600}}>
                                                    Description:
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={8}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline
                                                    rows={3} 
                                                    onChange={handleDescriptionChange} 
                                                    autoComplete="off" 
                                                    value={description}
                                                    helperText={
                                                        <p style={{fontSize: 12, color: "#bf5a4c", margin: "0px 0px -1px -12px"}}>
                                                            {(isDescriptionRequired) && "Description is required"}
                                                        </p>
                                                    }
                                                    sx={{"& .MuiOutlinedInput-root": {
                                                        "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                    }
                                                }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <Typography sx={{fontWeight: 600}}>
                                                    Website:
                                                </Typography>
                                            </Grid> 

                                            <Grid item xs={12} sm={8}>
                                                <TextField 
                                                    fullWidth 
                                                    variant="outlined" 
                                                    multiline 
                                                    onChange={handleWebsiteChange}
                                                    autoComplete="off" 
                                                    value={website}
                                                    sx={{"& .MuiOutlinedInput-root": {
                                                        "& > fieldset": {borderColor: "#3333cf4f !important"}
                                                    }
                                                }}
                                                />
                                            </Grid> 
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <CardActions sx={{justifyContent: "end", marginRight: (breakpoints == "xs") ? {} : "32px"}}>
                                <Button variant="outlined" sx={{color: "#3333cf", borderColor: "#3333cf", width: 'auto', borderRadius: 10, '&:hover': {color: "#3333cf", borderColor: "#3333cf"}}} 
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </Button>

                                <Button variant="contained" onClick={(e) => handleAddAgencyInfo(e)}
                                    sx={{width: 'auto', backgroundColor: "#168401", borderRadius: 10, boxShadow: 'none',
                                        '&:hover': {backgroundColor: "#b5b5b5", color: "white"}
                                    }}
                                    startIcon={isCreatingAgency && <ThreeDots height="20" width="20" radius="9" color="#ffff" ariaLabel="three-dots-loading" visible={true} />}
                                >
                                    {isCreatingAgency ? "Creating" : "Create"}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>      
    )
}

export default CreateAgencyInformationComponent