import React, { useEffect, useState } from "react";
import {styled} from "@mui/material/styles";
import {Button, ListItem, Grid, IconButton, CssBaseline, Toolbar, Box, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DrawerHeader from "./drawer-header";
import useWidth from "../hooks/useWidth";
import Sidebar from "./sidebar";
import AccountPopper from "./account-popper";
import AccountProfileIcon from "../icons/userProfile.png";
import logo from "../icons/nextix.png"
import CustomHeader from "./custom-header";
import TopNavigationComponent from "./top-navigation";
import ProfileService from "../services/api/user";

const drawerWidth = 250;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),

        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Navbar = ({children}) => {

    const breakpoints = useWidth();

    const url = window.location.pathname;

    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleDrawerOpen = () => {setOpen(true);}
    const handleDrawerClose = () => {setOpen(false);}
    const handleOpenMenu = (event) => {setAnchorEl(event.currentTarget);}
    const handleCloseMenu = () => {setAnchorEl(null);}

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const viewProfile = () => {
        setLoading(true);
        ProfileService.viewUser().then((res)=>{
            setLoading(false);
            console.log('get profile res', res)
            setUser(res.data.data)
        }).catch((error) => {
            console.log('get profile error', error)
            setLoading(false);
        })  
    }

    useEffect(()=>{
        viewProfile()
    },[])


    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline />

            <AppBar position="fixed" open={open} sx={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? "100%" : {}, backgroundColor: "#fff", height: 64, boxShadow: (url == "/view-record" || url == "/edit-record") ? "none" : "0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)"}}>
                <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}, paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}>

                    {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') &&
                        <IconButton edge="start" color="inherit" aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            sx={{mr: 5, ...((open || url == "/view-record" || url == "/edit-record") && {display: "none"})}}
                        >
                            <MenuIcon sx={{color: "#000000c4"}} />
                        </IconButton>
                    } 

                <Button variant="text" disableElevation sx={{textTransform: "none", cursor: "default", ...(open && {display: 'none'}), 
                        ...((breakpoints == 'xs' || breakpoints == 'sm') && {display: "visible"}), 
                        color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                    >
                        <img alt="" src={logo} style={{cursor: "default", height: 25}}/>
                    </Button>

                    <ListItem sx={{ml: "auto", width: "auto"}}>
                        <ListItemAvatar sx={{minWidth: 0, margin: (breakpoints == 'xs' || breakpoints == 'sm') ? {} : "6px -7px 0px 0px"}}>
                            {(breakpoints == 'xs' || breakpoints == 'sm') ?
                                <Grid>
                                    <IconButton
                                        aria-label="dropdown-menu"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenMenu}
                                    >
                                        <img alt="" src={AccountProfileIcon} style={{width: 30, height: 30}} />
                                    </IconButton>

                                    {/* account popper menu dropdown if mobile view */}
                                    <AccountPopper anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} />
                                </Grid>

                            : 
                                <img alt="" src={AccountProfileIcon} style={{width: 30, height: 30}} />
                            }
                        </ListItemAvatar>

                        {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
                            <ListItemText
                                secondary={
                                loading ? 
                                    null
                                :
                                    <Typography variant="caption" sx={{color: "#000", margin: "3px 0px 0px 12px", textAlign: "justify", fontSize: 14}}>
                                        {user && user.email}
                                    </Typography>
                                }
                            />
                        }
                            
                        {(breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && 
                            <Grid>
                                <IconButton
                                    aria-label="dropdown-menu"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenMenu}
                                >
                                    <ExpandMoreIcon fontSize="medium" style={{color: "#3333cf"}} />
                                </IconButton>

                                {/* account popper menu dropdown if laptop view */}
                                <AccountPopper anchorEl={anchorEl} handleCloseMenu={handleCloseMenu} /> 
                            </Grid>
                        }
                    </ListItem>
                </Toolbar>
            </AppBar>

            {/* for sidebar */}
            {((breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && (url !== "/view-record" && url !== "/edit-record")) && 
                <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
            }

            {/* for custom appbar  */}
            {((breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') && (url == "/view-record" || url == "/edit-record")) && 
                <CustomHeader open={open} handleDrawerClose={handleDrawerClose} />
            }

            {/* top navigation bar if mobile view */}
            {(breakpoints == "xs" || breakpoints == "sm") && 
                <TopNavigationComponent breakpoints={breakpoints} />
            } 

            <Box component="main" sx={{height: "100vh", flexGrow: 1, pt: breakpoints == 'xs' || breakpoints == 'sm' ? 3 : 1, width: "100%"}}>
                <DrawerHeader />   

                {(breakpoints == 'xs' || breakpoints == 'sm') && <DrawerHeader/>}

                {children}   
            </Box>
        </Box>
    )
}

export default Navbar