
const stringAvatar = (name) => {
    const rgx = /([A-Za-z])[A-Za-z]*[_-]?([A-Za-z])?[A-Za-z]*/;
    const match = name.match(rgx);
    if (match === null) {
      return { children: "" };
    }
    const [firstLetter, secondLetter] = match.slice(1);
    const initials = (firstLetter || "") + (secondLetter || "");
    return {
      children: initials.toUpperCase(),
    };
};

export { stringAvatar };

  