import {Box, Button, Toolbar, CssBaseline} from "@mui/material"
import useWidth from "../hooks/useWidth";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import ApplicationFormComponent from "../components/application-form/application-form.component";
import eIDLogo from "../icons/nextix.png";


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));


function ApplicationFormScreen () {

    const breakpoints = useWidth();

    const [open] = useState(true);


    return(

        <Box sx={{ width: breakpoints == 'xs' || breakpoints == 'sm' ? '95%' : '100%', margin: 'auto'}}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{backgroundColor: "#fff", height: 64, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)'}}>
                <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {},
                    paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}
                >
                    <Button variant="text" disableElevation sx={{textTransform: "none", cursor: "default", 
                        ...((breakpoints == 'xs' || breakpoints == 'sm') && {display: "visible"}), 
                        color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                    >
                        <img alt="" src={eIDLogo} style={{height: 30}} />
                    </Button>

                </Toolbar>
            </AppBar>

            <Box sx={{margin: breakpoints == 'xs' || breakpoints == 'sm' ? '0px' : '0px 50px 0px 60px'}}>
                <Box sx={{mt: 12}}>
                    <ApplicationFormComponent />
                </Box>
            </Box>
        </Box>
    
    )
}

export default ApplicationFormScreen