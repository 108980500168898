import React, {useState} from "react";
import {TextField, Button, Box, Grid, FormControl, Select, MenuItem, Typography, Card, CardContent, CardMedia} from "@mui/material";
import DefaultPhotoIcon from "../../icons/photo-placeholder.png";
import DefaultSignatureIcon from "../../icons/signature-placeholder.png";
import UploadESignatureDialog from "../dialogs/upload-esignature-dialog";
import {ThreeDots} from 'react-loader-spinner';
import {useNavigate} from "react-router-dom";
import ImageCropperDialog from "../dialogs/image-cropper.dialog";
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {useSnackbar} from "notistack";

const selectFieldStyles = {
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#3333cf4f",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3333cf4f",
        borderWidth: "thin",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#3333cf4f",
        borderWidth: "thin",
    },
};


const blood_types = [
    {name: 'A+'},
    {name: 'A-'},
    {name: 'B+'},
    {name: 'B-'},
    {name: 'AB+'},
    {name: 'AB-'},
    {name: 'O+'},
    {name: 'O-'}
]

const marital_status = [
    {name: 'SINGLE'},
    {name: 'MARRIED'},
    {name: 'DIVORCED'},
    {name: 'SEPARATED'},
    {name: 'WIDOWED'},
]

const gender_options = [
    {name: 'MALE'},
    {name: 'FEMALE'}
]


const CreateRecordsComponent = (props) => {

    const {breakpoints, createEIDRecord, loadingRecordCreation} = props;

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [email_address, setEmailAddress] = useState("");
    const [id_no, setIDNumber] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [bloodType, setBloodType] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [pob, setPob] = useState("");
    const [suffix, setSuffix] = useState("");
    const [photo, setPhoto] = useState(null);
    const [photoFile, setPhotoFile] = useState(null);
    const [signature, setSignature] = useState(null);
    const [openUpdateESignature, setOpenUpdateESignature] = useState(false);
    const [signaturePreview, setSignaturePreview] = useState(null);
    const [position, setPosition] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonNo, setContactPersonNo] = useState("");

    const [openImageEditor, setOpenImageEditor] = useState(false);
    const [userImg, setUserImg] = useState(null);
    const [isValidEmail, setValidEmail] = useState(true);

    const {enqueueSnackbar} = useSnackbar();

    const validateEmail = (email) => {
        const re = /^\S+@\S+\.\S+$/
        return re.test(email);
    }

    const handleTextField = (e, name) => {
        const regex = /^[\d-]+$/;

        if (name == "email_address") {
            let email = e.target.value;

            setEmailAddress(email);

            if (email) {
                setValidEmail(validateEmail(email));
            } else {
                setValidEmail(true);
            }

        } else if (name == "id_no") { 
            setIDNumber(e.target.value);

        }  else if (name == "firstName") {
            const selectedfirstName = e.target.value;
            setFirstName(selectedfirstName.toUpperCase());

        }  else if (name == "middleName") {
            const selectedmiddleName = e.target.value;
            setMiddleName(selectedmiddleName.toUpperCase());

        } else if (name == "suffix") {
            const selectedsuffix = e.target.value;
            setSuffix(selectedsuffix.toUpperCase());

        } else if (name == "lastName") {
            const selectedlastName = e.target.value;
            setLastName(selectedlastName.toUpperCase());

        } else if (name == "address") {
            const selectedaddress = e.target.value;
            setAddress(selectedaddress.toUpperCase());

        } else if (name == "pob") {
            const selectedpob = e.target.value;
            setPob(selectedpob.toUpperCase());

        } else if (name == "gender") {
            const selectedgender = e.target.value;
            setGender(selectedgender.toUpperCase());

        } else if (name == "bloodType") {
            const selectedbloodType = e.target.value;
            setBloodType(selectedbloodType.toUpperCase());

        } else if (name == "maritalStatus") {
            const selectedmaritalStatus = e.target.value;
            setMaritalStatus(selectedmaritalStatus.toUpperCase());

        } else if (name == "position") {
            const selectedposition = e.target.value;
            setPosition(selectedposition.toUpperCase());

        }   else if (name == "contactPerson") {
            const selectedcontactPerson = e.target.value;
            setContactPerson(selectedcontactPerson.toUpperCase());

        }  else if (name == "contactPersonNo") {
            if (e.target.value === "" || regex.test(e.target.value)) {
                setContactPersonNo(e.target.value);
            }
        }
    }

    const validatePhoto = (e) => {
        setImageStateFromChild();

        var fileName = document.getElementById("upload-photo").value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    
        if (extFile == "jfif" || extFile == "svg" || extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            const imageFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                setUserImg(e.target.result);
            }

            reader.readAsDataURL(imageFile);

            setOpenImageEditor(true);
 
        } else {
            enqueueSnackbar("Image file only, please choose an image file", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }
    }

    const setImageStateFromChild = (child) => {
        setUserImg(child);
    }

    const handleResetFile = () => {
        setPhotoFile(null);
        setPhoto(null);
    }


    const formattedDob = dob ? dayjs(dob).format('YYYY-MM-DD') : '';

    const handleCreateEID = () => {
        let formData = new FormData();
        formData.append('id_no', id_no);
        formData.append('firstname', firstName);
        formData.append('middlename', middleName);
        formData.append('lastname', lastName);
        formData.append('suffix', suffix);
        formData.append('dob', formattedDob);
        formData.append('pob', pob);
        formData.append('address', address);
        formData.append('email_address', email_address);
        formData.append('gender', gender);
        formData.append('blood_type', bloodType);
        formData.append('marital_status', maritalStatus);
        formData.append('position', position);
        formData.append('contact_person', contactPerson);
        formData.append('contact_person_no', contactPersonNo);


        if(photoFile){
            formData.append('photo', photoFile)
        }else{}
        if(signature){
            formData.append('e_signature', signature ? signature : null);
        }
        if (id_no && firstName && lastName && formattedDob && pob && address && 
            email_address && gender && bloodType && maritalStatus && isValidEmail &&
            position && contactPerson && contactPersonNo) {
            createEIDRecord(formData); 

        } else if (!isValidEmail) {
            enqueueSnackbar("Invalid email address format", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!email_address) {
            enqueueSnackbar("Email Address is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!id_no) {
            enqueueSnackbar("ID No is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!firstName) {
            enqueueSnackbar("First Name is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!lastName) {
            enqueueSnackbar("Last Name is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!formattedDob) {
            enqueueSnackbar("Date of Birth is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!pob) {
            enqueueSnackbar("Place of Birth is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!address) {
            enqueueSnackbar("Address is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!gender) {
            enqueueSnackbar("Gender is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!maritalStatus) {
            enqueueSnackbar("Marital Status is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!bloodType) {
            enqueueSnackbar("Blood Type is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!position) {
            enqueueSnackbar("Position is required", { 
                variant: "error",
                autoHideDuration: 3000
            });
        
        } else if (!contactPerson) {
            enqueueSnackbar("Contact Person is required", { 
                variant: "error",
                autoHideDuration: 3000
            });

        } else if (!contactPersonNo) {
            enqueueSnackbar("Contact Person No. is required", { 
                variant: "error",
                autoHideDuration: 3000
            });
        }    
    }


    return (
        <>
            <Box sx={{border: "0.1px solid #3333cf4f", borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 'sm' ? 1 : 3, pt: 1.5}}>
                <form noValidate autoComplete="off">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Email Address<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Email Address"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={email_address}
                                            onChange={(e) => handleTextField(e, "email_address")}
                                            helperText={
                                                <p style={{fontSize: 11, color: "#bf5a4c", margin: "2px 0px 0px -10px"}}>
                                                    {(!isValidEmail) ? "Email Address is invalid" : ""}
                                                </p>
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            ID No.<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="ID No"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{style: {fontSize: 16}}}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={id_no}
                                            onChange={(e) => handleTextField(e, "id_no")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Position<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Position"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": {borderColor: "#3333cf4f !important"},
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={position}
                                            onChange={(e) => handleTextField(e, "position")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            First Name<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="First Name"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={firstName}
                                            onChange={(e) => handleTextField(e, "firstName")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Middle Name :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Middle Name"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={middleName}
                                            onChange={(e) => handleTextField(e, "middleName")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Last Name<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Last Name"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={lastName}
                                            onChange={(e) => handleTextField(e, "lastName")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Suffix :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Suffix"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={suffix}
                                            onChange={(e) => handleTextField(e, "suffix")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Date of Birth<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                                                <DatePicker
                                                    defaultValue={dob}
                                                    required
                                                    views={['year', 'month', 'day']}
                                                    inputProps={{
                                                        style: {textTransform: 'uppercase',fontSize: 16},
                                                        autoCapitalize: 'characters'
                                                      }}
                                                    sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "& > fieldset": { borderColor: "#3333cf4f !important" },
                                                    },
                                                    fontSize: 16
                                                    }}
                                                    onChange={(newValue) => {
                                                        setDob(newValue);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Place of Birth<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Place of Birth"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={pob}
                                            onChange={(e) => handleTextField(e, "pob")}
                                        />
                                    </Grid>
                                </Grid> 

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Address<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Address"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": { borderColor: "#3333cf4f !important" },
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={address}
                                            onChange={(e) => handleTextField(e, "address")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Gender<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                inputProps={{
                                                    style: {textTransform: 'uppercase',fontSize: 16},
                                                    autoCapitalize: 'characters'
                                                }}
                                                sx={selectFieldStyles}
                                                value={gender}
                                                onChange={(e) => handleTextField(e, "gender")}
                                                displayEmpty
                                                renderValue={(gender) => {
                                                    if (gender.length === 0) {
                                                        return <span style={{color: "rgb(0 0 0 / 27%)"}}>
                                                            Gender
                                                        </span>;
                                                                        
                                                    } else {
                                                        return gender;
                                                    }
                                                }} 
                                            >
                                                {gender_options.map((gender) => {
                                                    return (
                                                        <MenuItem value={gender.name}>
                                                            {gender.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Blood Type<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue="A+"
                                                inputProps={{
                                                    style: {textTransform: 'uppercase',fontSize: 16},
                                                    autoCapitalize: 'characters'
                                                  }}
                                                sx={selectFieldStyles}
                                                value={bloodType}
                                                onChange={(e) => handleTextField(e, "bloodType")}
                                                displayEmpty
                                                renderValue={(bloodType) => {
                                                    if (bloodType.length === 0) {
                                                        return <span style={{color: "rgb(0 0 0 / 27%)"}}>
                                                            Blood Type
                                                        </span>;
                                                        
                                                    } else {
                                                        return bloodType;
                                                    }
                                                }}
                                            >
                                                {blood_types.map((blood) => {
                                                    return (
                                                        <MenuItem value={blood.name}>
                                                            {blood.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Marital Status<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue="Single"
                                                inputProps={{
                                                    style: {textTransform: 'uppercase',fontSize: 16},
                                                    autoCapitalize: 'characters'
                                                  }}
                                                sx={selectFieldStyles}
                                                value={maritalStatus}
                                                onChange={(e) => handleTextField(e, "maritalStatus")}
                                                displayEmpty
                                                renderValue={(maritalStatus) => {
                                                    if (maritalStatus.length === 0) {
                                                        return <span style={{color: "rgb(0 0 0 / 27%)"}}>
                                                            Marital Status
                                                        </span>;

                                                    } else {
                                                        return maritalStatus;
                                                    }
                                                }}
                                            >
                                                {marital_status.map((status) => {
                                                    return (
                                                        <MenuItem value={status.name}>
                                                            {status.name}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Contact Person<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Contact Person"
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16, textTransform: "uppercase"}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": {borderColor: "#3333cf4f !important"},
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={contactPerson}
                                            onChange={(e) => handleTextField(e, "contactPerson")}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Contact Person No.<b style={{color: 'red'}}>*</b> :
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <TextField
                                            placeholder="Contact Person No."
                                            fullWidth 
                                            variant="outlined" 
                                            multiline 
                                            autoComplete="off"
                                            required
                                            inputProps={{
                                                style: {fontSize: 16}, autoCapitalize: 'characters'
                                            }}
                                            sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "& > fieldset": {borderColor: "#3333cf4f !important"},
                                            },
                                            fontSize: 16
                                            }}
                                            defaultValue={contactPersonNo}
                                            onChange={(e) => handleTextField(e, "contactPersonNo")}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={5} sx={{mt: "5px"}}>
                                <Grid container spacing={3} sx={{alignItems: 'center', justifyContent:  'center' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
                                            <Grid item xs={12} sx={{textAlign: "center"}}>
                                                <Typography variant="subtitle2" style={{display: "none", mb: "10px", fontSize: 16, fontWeight: 550}} className="required-field">
                                                    Photo
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Card sx={{border: 1, borderColor:'#3333cf4f', marginBottom: 2, marginTop: 0, boxShadow: 'none'}}>
                                                        {photo ?
                                                            <CardContent sx={{textAlign:'center'}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    src={photo}
                                                                    style={{width: '100%', height : 125, objectFit: 'contain'}}
                                                                />
                                                            </CardContent>
                                                        :
                                                            <CardContent sx={{textAlign:'center'}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    src={DefaultPhotoIcon}
                                                                    style={{width: '100%', height : 125, objectFit: 'contain'}}
                                                                />
                                                            </CardContent>
                                                        }
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: "center", textAlign: "center"}}>
                                                <Grid container sx={{justifyContent: "center"}}>
                                                    {photoFile ?
                                                        <>
                                                            <Grid item xs={5} sm={5}>
                                                                <Button variant="outlined" sx={{width: "auto", fontWeight: 550, borderRadius: 10, textTransform: 'none', color: "#3333cf", borderColor: "#3333cf",  ":hover": {borderColor: "#3333cf", color: "#3333cf"}}} 
                                                                    onClick={(e) => handleResetFile(e)}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </Grid>
                                                        </>
                                                    :
                                                        <Grid item xs={12} sm={12} >
                                                            <Button variant="outlined" sx={{width: 'auto', borderRadius: 10, textTransform: 'none', borderColor: '#168401', color: '#168401', ":hover": {borderColor: "#168401", color: "#168401"}}}>
                                                                <input 
                                                                    type="file" 
                                                                    accept="image/*" 
                                                                    id="upload-photo"
                                                                    onChange= {(e) => validatePhoto(e)}
                                                                    hidden
                                                                />

                                                                <label style={{cursor: "pointer", fontWeight: 550}} htmlFor="upload-photo">
                                                                    Browse Photo 
                                                                </label>
                                                            </Button>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sx={{textAlign: 'center', pt: 1}}>
                                                <Typography variant="subtitle2" style={{fontSize: 16, fontWeight: 550}} className="required-field">Photo</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Grid container sx={{alignItems: 'center', justifyContent:  'center'}}>
                                            <Grid item xs={12} sx={{textAlign: "center"}}>
                                                <Typography variant="subtitle2" style={{display: "none", mb: "10px", fontSize: 16, fontWeight: 550}} className="required-field">
                                                    E-Signature
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Card sx={{border: 1, borderColor:'#3333cf4f', marginBottom: 2, marginTop: 0, boxShadow: 'none'}}>
                                                        {signaturePreview ? 
                                                            <CardContent sx={{textAlign:'center'}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    src={signaturePreview}
                                                                    style={{width: '100%', objectFit: 'contain', height: 125, backgroundColor: 'rgb(0 0 0 / 8%)'}}
                                                                />
                                                            </CardContent>
                                                        :
                                                            <CardContent sx={{textAlign: "center"}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    src={DefaultSignatureIcon}
                                                                    style={{width: "100%", height: 125, objectFit: "contain"}}
                                                                />
                                                            </CardContent>
                                                        }
                                                </Card>
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Grid container sx={{justifyContent: 'center'}}>
                                                    <Grid item xs={12} sm={12} >
                                                        <Button variant="outlined" sx={{fontWeight: 550, borderColor: '#168401', color: '#168401', width: 'auto', borderRadius: 10, boxShadow: 'none',  textTransform: 'none',  ":hover": {borderColor: "#168401", color: "#168401"}}}
                                                            onClick={(e) => setOpenUpdateESignature(true)}
                                                        >
                                                            Upload Signature
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sx={{textAlign: 'center', pt: 1}}>
                                                <Typography variant="subtitle2" style={{fontSize: 16, fontWeight: 550}} className="required-field">e-Signature</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>   
                    </Grid>

                    <Grid container direction="row" sx={{justifyContent: "flex-end",  textAlign: "end", pb: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 3, pt: 3, mt: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                        <Button variant="outlined" sx={{width: "auto", borderRadius: 10, color: "#3333cf", borderColor: "#3333cf", boxShadow: "none", fontWeigth: 550, ":hover": {borderColor: "#3333cf", color: "#3333cf"}}}
                            onClick={() => navigate(-1)}
                        >
                            CANCEL
                        </Button>

                        &nbsp; &nbsp;   
                
                        <Button onClick={()=> handleCreateEID()} variant="contained" sx={{width: "auto", backgroundColor: "#168401", borderRadius: 10, boxShadow: "none", fontWeigth: 550, ":hover": {backgroundColor: '#b5b5b5' }}}
                            startIcon={loadingRecordCreation && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                        >
                            {loadingRecordCreation? "CREATING" : "CREATE"}
                        </Button>
                    </Grid>
                </form>
            </Box>

            {openUpdateESignature &&
                <UploadESignatureDialog breakpoints={breakpoints}
                    openUpdateESignature={openUpdateESignature} setOpenUpdateESignature={setOpenUpdateESignature}
                    setSignaturePreview={setSignaturePreview} setSignature={setSignature}
                />
            }

            {openImageEditor &&
                <ImageCropperDialog breakpoints={breakpoints} 
                    openImageEditor={openImageEditor} setOpenImageEditor={setOpenImageEditor}
                    userImg={userImg} setImageStateFromChild={setImageStateFromChild}
                    setPhoto={setPhoto} setPhotoFile={setPhotoFile}
                />
            }
        </>
    )
}

export default CreateRecordsComponent