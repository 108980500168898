import {Box, Button, Toolbar, CssBaseline} from "@mui/material"
import useWidth from "../hooks/useWidth";
import { useState } from "react";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import NextixLogo from "../icons/nextix.png"
import PreRegistrationFormComponent from "../components/pre-registration/pre-registration-form.component";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

function PreRegistrationFormScreen () {

    const breakpoints = useWidth();

    const [open] = useState(true);


    return(

        <Box sx={{ width: breakpoints == 'xs' || breakpoints == 'sm' ? '95%' : '100%', margin: 'auto'}}>
            <CssBaseline />
            
            <AppBar position="fixed" open={open} sx={{backgroundColor: "#fff", height: 64, boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 11%), 0px 4px 5px 0px rgb(0 0 0 / 3%), 0px 1px 10px 0px rgb(0 0 0 / 3%)'}}>
                <Toolbar sx={{paddingLeft: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {},
                    paddingRight: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px !important" : {}}}
                >
                    <Button variant="text" disableElevation sx={{textTransform: "none", cursor: "default", 
                        ...((breakpoints == 'xs' || breakpoints == 'sm') && {display: "visible"}), 
                        color: "rgb(0 0 0 / 87%)", fontSize: "20px", fontWeight: "bold"}}
                    >
                        <img alt="" src={NextixLogo} style={{height: 28}} />
                    </Button>

                </Toolbar>
            </AppBar>

            <Box sx={{margin: (breakpoints == 'xs' || breakpoints == 'sm') ? '0px' : "0px 50px 30px 60px"}}>
                <Box sx={{mt: 12}}>
                    <PreRegistrationFormComponent />
                </Box>
            </Box>
        </Box>
    
    )
}

export default PreRegistrationFormScreen