import environment from "../../../environment/environment"

const EID_ADMIN_SERVICE_BASE_URL = environment.eID_service.url ;
const EID_PUBLIC_SERVICE_BASE_URL = environment.eID_public_service.url ;



export default {

   // admin - eid
   RETRIEVE_EID_RECORD_LIST :                   EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/list',
   UPLOAD_CSV:                                  EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/uploadcsv',
   CREATE_EID_RECORD :                          EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/create',
   VIEW_EID_DETAILS:                            EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/view',
   GENERATE_EID :                               EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/generate',
   SEND_APP_BINDING :                           EID_ADMIN_SERVICE_BASE_URL + '/api/v1/add-eid/send/instruction',
   DELETE_EID :                                 EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/delete',
   REVOKE_SUSPEND_EID_APPLICATION :             EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/update/status',
   APPROVE_EID:                                 EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/approve',
   DISAPPROVE_EID:                              EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/disapprove',
   DOWNLOAD_EID :                               EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/download',
   EDIT_EID_DETAILS:                            EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/update',
   SEND_TO_EWALLET:                             EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/e-wallet/send',
   RETRIEVE_ACTIVITY_LOGS:                      EID_ADMIN_SERVICE_BASE_URL + '/api/v1/logs/eid/list',

   
   //admin - agency
   RETRIEVE_AGENCY_INFORMATION :                EID_ADMIN_SERVICE_BASE_URL + '/api/v1/agency/view',
   UPDATE_AGENCY_INFORMATION :                  EID_ADMIN_SERVICE_BASE_URL + '/api/v1/agency/update',
   CREATE_AGENCY_INFORMATION:                   EID_ADMIN_SERVICE_BASE_URL + '/api/v1/agency/create',

   //admin - config
   RETRIEVE_CONFIG_EID :                        EID_ADMIN_SERVICE_BASE_URL + '/api/v1/config/view',
   UPDATE_CONFIG_EID :                          EID_ADMIN_SERVICE_BASE_URL + '/api/v1/config/update',



   //user
   VIEW_USER :                                  EID_ADMIN_SERVICE_BASE_URL + '/api/v1/users/profile' ,

   //pre-registration
   RETRIEVE_PRE_REGISTRATION_LINK :             EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/reg-client/link',
   REGENERATE_PRE_REGISTRATION_LINK :           EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/reg-client/generate/link',
   DELETE_PRE_REGISTRATION_LINK :               EID_ADMIN_SERVICE_BASE_URL + '/api/v1/eid/reg-client/link',

   //eID - public
   RETRIEVE_EID_APPLICATION_RECORD :            EID_PUBLIC_SERVICE_BASE_URL + '/api/v1/eid/form/retrieve/data',
   SUBMIT_EID_APPLICATION_RECORD :              EID_PUBLIC_SERVICE_BASE_URL + '/api/v1/eid/form/submit',
   VALIDATE_ACCESS_KEY:                         EID_PUBLIC_SERVICE_BASE_URL + '/api/v1/eid/register-link/validation',
   PRE_REGISTER_EID:                            EID_PUBLIC_SERVICE_BASE_URL + '/api/v1/eid/pre-register',
   
   //signatories
   RETRIEVE_SIGNATORIES :                       EID_ADMIN_SERVICE_BASE_URL + '/api/v1/signatories',
   DELETE_SIGNATORY :                           EID_ADMIN_SERVICE_BASE_URL + '/api/v1/signatories',
   SET_AS_DEFAULT_SIGNATORY :                   EID_ADMIN_SERVICE_BASE_URL + '/api/v1/signatories/is-default',
   ADD_SIGNATORY :                              EID_ADMIN_SERVICE_BASE_URL + '/api/v1/signatories',
   UPDATE_SIGNATORY :                           EID_ADMIN_SERVICE_BASE_URL + '/api/v1/signatories',


}