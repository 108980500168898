import {Box, Grid, Skeleton, Typography} from "@mui/material";
import ViewAgencyInformationComponent from "../components/agency-information/view-agency-information.component";
import useWidth from "../hooks/useWidth";
import useAgency from "../hooks/useAgency";
import AgencySkeletonComponent from "../components/skeletons/agency-skeleton-loader.component";
import { useEffect } from "react";


function Header (props) {

    const {agencyInformation, loading} = props;

    return (
        <Box sx={{padding: 1, width: "100%", borderRadius: 0}}>
            <Grid container sx={{textAlign: "start", ml: 2}}>
                <Grid item xs={12}>
                    {loading ?
                        <Skeleton variant="rectangular" height={30} width="20%" style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                    :
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>
                            { agencyInformation ? 'Agency Information' : 'Add Agency' }
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}


function AgencyInformationScreen() {

    const breakpoints = useWidth();

    const { loading, retrieveAgencyInformation, agencyInformation} = useAgency();

    useEffect(() => {
        retrieveAgencyInformation();
    },[])

    return(

        <Box sx={{overflow: "hidden", position: 'relative', width: breakpoints == 'xs' || breakpoints == 's' ? '100%' : '95%', margin:'auto'}}>

            <Header loading={loading} agencyInformation={agencyInformation} /> 

            <Box style={{flexGrow: 1, margin:'auto'}}>
                <Grid container spacing={2} direction="row" sx={{padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 2}}>
                    <Grid item xs={12} sm={12} sx={{pt: 0}}>
                       {loading ? (
                            <AgencySkeletonComponent />
                        ) : (
                            <>
                                {agencyInformation &&
                                    <ViewAgencyInformationComponent breakpoints={breakpoints} 
                                        agencyInformation={agencyInformation} 
                                    />
                                }
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>

        </Box>
    )
    
}

export default AgencyInformationScreen