import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Grid, InputBase, Typography, TextField } from "@mui/material";
import SignaturePad from "react-signature-canvas";
import { useSnackbar } from "notistack";
import useSignatories from "../../../hooks/useSignatories";
import { convertUrlToFile } from "../../../utils/convertUrlToFileUtil";
import SignatoryCropperDialog from "./signatory-cropper.dialog";

const CreateSignatoryByDrawComponent = (props) => {

  const { breakpoints, url } = props;

  const { enqueueSnackbar } = useSnackbar();
  const signaturePadRef = useRef(null);
  const { retrieveSignatories, signatories } = useSignatories();

  const [isCanvasEmpty, setCanvasEmpty] = useState(true);
  const [signatoryName, setSignatoryName] = useState("");
  const [signatoryPosition, setSignatoryPosition] = useState("");
  const [isSigNameRequired, setSigNameRequired] = useState(false);
  const [isSigPosRequired, setSigPosRequired] = useState(false);
  const [signatureFile, setSignatureFile] = useState(null);
  const [openSigEditor, setOpenSigEditor] = useState(false);
  const [isByDraw, setByDraw] = useState(false);
  const [isSignatoryDefault, setSignatoryDefault] = useState(null);

  useEffect(() => {
    retrieveSignatories();
  }, [])

  useEffect(() => {
    if (signatories?.length === 0) {
      setSignatoryDefault(true);

    } else if (signatories?.length < 3 && signatories?.length !== 0) {
      setSignatoryDefault(false);
    }
  }, [signatories])


  const handleCanvasChange = () => {
    setCanvasEmpty(signaturePadRef.current.isEmpty());
  }

  const handleClearSignature = () => {
    signaturePadRef.current.clear();
    setCanvasEmpty(true);
  }


  const handleTextFieldChange = (e, name) => {

    if (name == "signatoryName") {
      let signatory_name = e.target.value;

      setSignatoryName(signatory_name);
      setSigNameRequired(false);

    } else if (name == "signatoryPosition") {
      let signatory_position = e.target.value;

      setSignatoryPosition(signatory_position);
      setSigPosRequired(false);
    }

  }



  const handleUploadSignature = (e) => {
    let trimmedSignature = signaturePadRef.current.getTrimmedCanvas();
    let signatureDataUrl = trimmedSignature.toDataURL();

    if (signatoryName && signatoryPosition && !isCanvasEmpty) {
      convertUrlToFile(signatureDataUrl, "signature_file.png", "image/png").then(function (file) {
        setByDraw(true);
        setSignatureFile(file);
        setOpenSigEditor(true);
      })

    } else if (isCanvasEmpty) {
      enqueueSnackbar("Signature is required", {
        variant: "error",
        autoHideDuration: 3000
      });

    } else if (!signatoryName) {
      setSigNameRequired(true);

    } else if (!signatoryPosition) {
      setSigPosRequired(true);
    }
  }


  return (
    <>
      <Box sx={{mt: "11px !important", width: "93%", margin: "auto", border: "solid 1px #3333cf", borderRadius: "8px" }}>
        <Box sx={{
          padding: (breakpoints == 'xs' || breakpoints == 'sm') ? "0px 0px 16px 0px" :
            (breakpoints == 'md' || breakpoints == 'lg' || breakpoints == 'xl') ? "0px 40px 16px 40px" : "23px 40px 16px 40px"
        }}
        >
          <Box style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <SignaturePad ref={signaturePadRef} clearOnResize={false} onEnd={handleCanvasChange}
              canvasProps={{ width: (breakpoints == "xs" || breakpoints == "sm") ? 200 : 800, height: 250 }}
            />
          </Box>

          <div style={{ borderBottom: "1px solid #d3d3d3", marginTop: "-45px" }}>
            <InputBase disabled sx={{ color: "#b9b9b9", mb: 1, ml: 1, width: "100%" }} />
          </div>

          <Typography sx={{ fontSize: "14px", color: "#b9b9b9", textAlign: "center", mt: "10px", letterSpacing: "0.6px" }}>
            Draw Signature Here
          </Typography>
        </Box>
      </Box>

      <Grid container sx={{display: "flex", width: "93%", mt: "12px !important", margin: "auto" }} spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{pl: "0px !important"}}>
          <TextField
            fullWidth
            variant="outlined"
            autoComplete="off"
            placeholder="Signatory Name"
            value={signatoryName}
            onChange={(e) => handleTextFieldChange(e, "signatoryName")}
            inputProps={{ style: { padding: "10px", fontSize: 14 } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: "#3333cf !important" }
              }
            }}
            helperText={
              <p style={{ fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -14px" }}>
                {(isSigNameRequired) ? "Signatory Name is required" : ""}
              </p>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? "0px !important" : {}}}>
          <TextField
            fullWidth
            variant="outlined"
            autoComplete="off"
            placeholder="Signatory Position"
            value={signatoryPosition}
            onChange={(e) => handleTextFieldChange(e, "signatoryPosition")}
            inputProps={{ style: { padding: "10px", fontSize: 14 } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& > fieldset": { borderColor: "#3333cf !important" }
              }
            }}
            helperText={
              <p style={{ fontSize: 12, color: "#bf5a4c", margin: "2px 0px 0px -14px" }}>
                {(isSigPosRequired) ? "Signatory Position is required" : ""}
              </p>
            }
          />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: "end", mt: (breakpoints == "xs" || breakpoints == "sm") ? 3 : "4px", mb: 5 }}>
          {!isCanvasEmpty &&
            <>
              <Button variant="outlined" sx={{borderColor: "#3333cf", color: "#3333cf", width: 120, borderRadius: 10, textTransform: "none",
                '&:hover': {color: "#3333cf", borderColor: "#3333cf"}}}
                onClick={(e) => { handleClearSignature() }}
              >
                CLEAR
              </Button>

              {(breakpoints == "xs" || breakpoints == "sm") ?
                <> <br /> <br /> </>
                :
                <> &nbsp; &nbsp; </>
              }
            </>
          }

          <Button variant="contained" sx={{width: 120, backgroundColor: "#168401", borderRadius: 10, boxShadow: "none", textTransform: "none",
            '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
            onClick={(e) => handleUploadSignature()}
          >
            UPLOAD
          </Button>
        </Grid>
      </Grid>

      {openSigEditor &&
        <SignatoryCropperDialog breakpoints={breakpoints}
          openSigEditor={openSigEditor} setOpenSigEditor={setOpenSigEditor}
          signatureFile={signatureFile} setSignatureFile={setSignatureFile}
          signaturePadRef={signaturePadRef} setCanvasEmpty={setCanvasEmpty}
          signatoryName={signatoryName} isByDraw={isByDraw} setByDraw={setByDraw}
          isSignatoryDefault={isSignatoryDefault} url={url} signatoryPosition={signatoryPosition}
        />
      }
    </>
  )
}

export default CreateSignatoryByDrawComponent