import React, {useState, useEffect} from "react";
import {Button, Box, Grid, ListItem, ListItemText, Typography, Card, CardContent, CardMedia, IconButton, fabClasses} from "@mui/material";
import RejectApplicationDialog from "../dialogs/reject-application-dialog";
import ApproveApplicationDialog from "../dialogs/approve-application-dialog";
import DownloadEIDDialog from "../dialogs/download-eID-dialog";
import SendAppBindingDialog from "../dialogs/send-app-binding-dialog";
import RevokeApplicationDialog from "../dialogs/revoke-application.dialog";
import useEID from "../../hooks/useEID";
import {ThreeDots} from 'react-loader-spinner';
import DeleteEIDDialog from "../dialogs/delete-eID-dialog";
import SelectSignatoryDialog from "../dialogs/select-signatory.dialog";
import CreateSignatoryDialog from "../dialogs/create-signatory.dialog";

const ViewEIDDetailsComponent = (props) => {

    const {breakpoints, eid, approveEID, loadingApprovalEID, disapproveEID, loadingDisapproveEID, setLoading, signatories} = props;

    const {sendToEWallet, sending, generated, isOpeningPDFViewer, viewPdfCopy} = useEID();

    const [openRejectApplication, setOpenRejectApplication] = useState(false);
    const [openApproveApplication, setOpenApproveApplication] = useState(false);
    const [openDownload, setOpenDownload] = useState(false);
    const [openAppBinding, setOpenAppBinding] = useState(false);
    const [openRevokeApplication, setOpenRevokeApplication] = useState(false);
    const [revoke, setRevoke] = useState(false);
    const [activate, setActivate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [isResend, setResend] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [status, setStatus] = useState(eid?.status); // Add status state variable
    const [is_instruction_sent, setIsInstructionSent] = useState(eid?.instruction_is_sent); // Add instruction_is_sent state variable
    const [pdfName, setPDFName] = useState(null);

    const [openSelectSignatory, setOpenSelectSignatory] = useState(false);
    const [openCreateSignatory, setOpenCreateSignatory] = useState(false);
    const [isNoAvailableSignatories, setNoAvailableSignatories] = useState(false);

    const storedPDFUrl = localStorage.getItem("pdfUrl");
    const storedEIDPDFData = localStorage.getItem("eidPDFBase64Data");

    const recordId = new URLSearchParams(window.location.search).get("id");


    useEffect(() => {
        const storedSignatories = localStorage.getItem("savedSignatoriesList");
        const parsedSignatories = JSON.parse(storedSignatories);

        if (signatories.length !== 0 || parsedSignatories.length !== 0) {
            setNoAvailableSignatories(false);
            
        }  else {
            setNoAvailableSignatories(true);
        }
    }, [signatories])


    useEffect(() => {
        setPDFName(eid?.lastname + "_" + eid?.firstname);
    }, [])


    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleOpenResendAppBinding = () => {
        setResend(true);
        setOpenAppBinding(true);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleOpenAppBinding = () => {
        setOpenAppBinding(true);
    }

    const handleCloseAppBinding  = () => {
        setOpenAppBinding(false);
    }

    const handleOpenDownload = () => {
        setOpenDownload(true);
    };

    const handleCloseDownload = () => {
        setOpenDownload(false);
    };

    const handleGenerateEID = (e) => {
        if (isNoAvailableSignatories) {
            setOpenCreateSignatory(true);

        } else {
            setOpenSelectSignatory(true);
        }
    }

    const handleViewPDFCopy = (e) => {
        let uuid = eid?.uuid;

        if (storedEIDPDFData && storedPDFUrl) {
            window.open(storedPDFUrl, '_blank');

        } else {
            viewPdfCopy(uuid, pdfName);
        }
    }



    useEffect(() => {
        if (generated) {
            window.location.reload();
        }
    }, [generated])

    const handleSentToEWallet = (e) => {
        let uuid = eid?.uuid;
        sendToEWallet(uuid);
    }


    useEffect(() => {
        if(isDeleted){
            window.location.href = "/records"
        }
    },[isDeleted])



    return (
        <>
            <Box>
                <Grid container spacing={2} sx={{mt: 1, pl: 2, pr: 2}}>

                    {/* For Mobile View */}
                    <Box  sx={{ display: (breakpoints == "xs" || breakpoints == "sm") ? "visible" : "none", border: (breakpoints == "xs" || breakpoints == "sm") ? 'none' : '0.1px solid #3333cf', height: '100%'}}>
                        <Grid container sx={{mt: 2,}}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  sx={{justifyContent:  "start"}} >
                                <Grid container sx={{pl: 2, pr: 2}}>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Status:
                                        </Typography> 
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle2" 
                                            sx={{fontSize: "16px", mt: "-3px", fontWeight: "bold",
                                            color: (status == "Pending") ? "red" 
                                            :   
                                                (status == "Pending for Approval" || status == "Suspended") ? "#ff8c00" 
                                            : 
                                                (status == "Approved" || status == "Completed") ? "green"
                                            : 
                                                (status == "Authentication") ? "#0178f2"
                                            : 
                                                (status == "Reject" || status == "Revoked") ? "red" 
                                            : 
                                                "#404040"
                                            }}
                                        >
                                            {status}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            {(eid?.status == "Reject") && 
                                <Grid item xs={12} sx={{display: "flex", ml: "35px" }}>
                                    <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                        Reason:
                                    </Typography> 
                                    &nbsp;
                                    <Typography variant="subtitle2" 
                                        sx={{fontSize: "16px", mt: "-3px",color:"#404040", fontWeight: 'bold'}}
                                    >
                                        {eid?.reject_reason}
                                    </Typography>
                                </Grid>
                            }

                        </Grid>
                    </Box>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  sx={{mb: 3}}>
                        <Box  sx={{border: (breakpoints == "xs" || breakpoints == "sm") ? 'none' : '0.1px solid #3333cf', height: '100%'}}>
                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            ID No.
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.id_no}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Fullname
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.firstname} {eid?.middlename} {eid?.lastname} {eid?.suffix}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Email Address
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.email_address}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Date of Birth
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.dob}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Address
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.address}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Gender
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.gender}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Blood Type
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.blood_type}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Marital Status
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.marital_status}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary={
                                        <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                            Place of Birth
                                        </Typography>
                                    } 
                                    secondary={
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", color: "rgb(0 0 0 / 87%)"}}>
                                            {eid?.pob}
                                        </Typography>   
                                    }         
                                />
                            </ListItem>
                        </Box>

                    </Grid>

                    {/* display if desktop view */}
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{mb: 3}}>
                        <Box  sx={{border: (breakpoints == "xs" || breakpoints == "sm") ? 'none' : '0.1px solid #3333cf', height: '100%'}}>
                            <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
                                <Grid container sx={{mt: 2, display: (breakpoints == "xs" || breakpoints == "sm") ? "none" : "visible" }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  sx={{justifyContent:  "start"}} >
                                        <Grid container sx={{pl: 2, pr: 2}}>
                                            <Grid item xs={12} sm={2}>
                                                <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                                    Status:
                                                </Typography> 
                                            </Grid>
                                            <Grid item xs={12} sm={10}>
                                                <Typography variant="subtitle2" 
                                                    sx={{fontSize: "16px", mt: "-3px", fontWeight: "bold",
                                                    color: (status == "Pending") ? "red" 
                                                    :   
                                                        (status == "Pending for Approval" || status == "Suspended") ? "#ff8c00" 
                                                    : 
                                                        (status == "Approved" || status == "Completed") ? "green"
                                                    : 
                                                        (status == "Authentication") ? "#0178f2"
                                                    : 
                                                        (status == "Reject" || status == "Revoked") ? "red" 
                                                    : 
                                                        "#404040"
                                                    }}
                                                >
                                                    {status}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                
                                    {(eid?.status == "Reject") && 
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  sx={{justifyContent:  "start"}} >
                                            <Grid container sx={{pl: 2, pr: 2}}>
                                                <Grid item xs={12} sm={2}>
                                                    <Typography variant="subtitle2" sx={{fontWeight: "bold", fontSize: 14}}>
                                                        Reason:
                                                    </Typography> 
                                                </Grid>
                                                <Grid item xs={12} sm={10}>
                                                    <Typography variant="subtitle2" sx={{fontSize: "16px", mt: "-3px",color:"#404040", fontWeight: 'bold'}} >
                                                        {eid?.reject_reason}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                </Grid>

                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, mt: "30px", justifyContent: "center", textAlign: "center"}}>
                                    <Card sx={{border: 1, borderColor: "#3333cf", mb: 1, mt: 0, boxShadow: "none"}}>
                                        <CardContent sx={{textAlign: "center", height: (eid?.photo) ? {} : 165}}>
                                            {eid?.photo ? 
                                                <CardMedia
                                                    component="img"
                                                    src={eid?.photo}
                                                    style={{width: "100%", height: 125, objectFit: "contain"}}
                                                />
                                            :
                                                <Typography sx={{pt: "45px"}}>
                                                    No available photo
                                                </Typography>
                                            }
                                        </CardContent>                       
                                    </Card>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", fontWeight: "bold"}}>
                                            Photo
                                        </Typography>
                                    </Grid>
                                </Grid>

                                &nbsp; &nbsp; &nbsp; &nbsp;

                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{paddingRight: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, paddingLeft: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}, mt: (breakpoints == "xs" || breakpoints == "sm") ? {} : "30px", justifyContent: "center", textAlign: "center"}}>
                                    <Card sx={{border: 1, borderColor: "#3333cf", mb: 1, mt: 0, boxShadow: "none"}}>
                                        <CardContent sx={{textAlign: "center", height: (eid?.e_signature) ? {} : 165}}>
                                            {(eid?.e_signature) ? 
                                                <CardMedia
                                                    component="img"
                                                    src={eid?.e_signature}
                                                    style={{width: "100%", height: 125, objectFit: "contain", backgroundColor: 'rgb(0 0 0 / 8%)'}}
                                                />
                                            :
                                                <Typography sx={{pt: "45px"}}>
                                                    No available signature
                                                </Typography>
                                            }
                                        </CardContent>                       
                                    </Card>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="subtitle2" sx={{fontSize: "16px", fontWeight: "bold"}}>
                                            e-Signature
                                        </Typography>
                                    </Grid>
                                </Grid>
                            
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{mb: 3}}>
                        <Box  sx={{border: (breakpoints == "xs" || breakpoints == "sm") ? 'none' : '0.1px solid #3333cf', height: '100%'}}>
                            <Grid container spacing={2}  sx={{pl: 3, pr: 3, mt: 0.5, alignItems: "center", justifyContent: "center", textAlign: 'center', alignSelf: "center"}}>
                                {(status == "Revoked")  ?
                                    <>
                                        <Grid item xs={12} >
                                            <Button variant="contained" sx={{width: 'auto', backgroundColor: "#248bf1", borderRadius: 10, width: '100%', boxShadow: 'none',  background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                            onClick={(e) => window.location.href = "/edit-record?id=" + eid?.uuid}>
                                                Edit
                                            </Button>
                                        </Grid> 
                                        <Grid item xs={12} >
                                            <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                onClick={(e) => [setOpenRevokeApplication(true), setRevoke(false), setActivate(true)]}
                                            >
                                                ACTIVATE
                                            </Button>
                                        </Grid>
                                    </>
                               
                                :
                                    (status == "New" || status == 'Pending') ?
                                        <>
                                            <Grid item xs={12} >
                                                <Button variant="contained" sx={{width: 'auto', backgroundColor: "#248bf1", borderRadius: 10, width: '100%', boxShadow: 'none',  background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => window.location.href = "/edit-record?id=" + eid?.uuid}>
                                                    Edit
                                                </Button>
                                            </Grid> 
                                            <Grid item xs={12} >
                                                {is_instruction_sent ? 
                                                    <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => setOpenAppBinding(true)}
                                                    > 
                                                        Resend 
                                                    </Button>
                                                : 
                                                    <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => setOpenAppBinding(true)}
                                                    > 
                                                        Send App Binding
                                                    </Button>
                                                } 
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Button variant="contained" sx={{width: 120, color: "#fff", borderRadius: 10,width: '100%',
                                                    boxShadow: "none", background: "red", ":hover": {background: "red"}}}
                                                    onClick={(e) => handleOpenDelete(e)} >
                                                    Delete
                                                </Button>
                                            </Grid>
                                        </>
                                    
                               
                               :

                                    (status == "Pending for Approval") ?
                                        <>
                                            <Grid item xs={12} >
                                                <Button variant="contained" sx={{width: 120, borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#2ecb35", ":hover": {background: "#2ecb35"}}}
                                                    onClick={(e) => setOpenApproveApplication(true)}
                                                >
                                                    APPROVE
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Button variant="contained" sx={{width: 120, color: "#fff", borderRadius: 10,width: '100%',
                                                    boxShadow: "none", background: "red", ":hover": {background: "red"}}}
                                                    onClick={(e) => setOpenRejectApplication(true)}
                                                >
                                                    REJECT
                                                </Button> 
                                            </Grid>
                                        </>

                                : 
                            
                                    (status == "Approved") ?  
                                    
                                        <>
                                            <Grid item xs={12}>
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => handleGenerateEID(e)}
                                                >
                                                   GENERATE eID
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button variant="contained" sx={{width: 120, color: "#fff", borderRadius: 10,width: '100%',
                                                    boxShadow: "none", background: "red", ":hover": {background: "red"}}}
                                                    onClick={(e) => setOpenRejectApplication(true)}
                                                >
                                                    REJECT
                                                </Button> 
                                            </Grid>
                                        </>

                                :
                                    (status == "Completed") ?
                                        <> 
                                           <Grid item xs={12}>
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    textTransform: "none", background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => handleViewPDFCopy()}
                                                    startIcon={isOpeningPDFViewer && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                                                >
                                                    {(isOpeningPDFViewer) ? "Redirecting to viewer" : "VIEW EID"}
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => setOpenDownload(true)}
                                                >
                                                    DOWNLOAD
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "red", ":hover": {background: "red"}}}
                                                    onClick={(e) => [setOpenRevokeApplication(true), setRevoke(true)]}
                                                >
                                                    REVOKE
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none",width: '100%',
                                                    background: "orange", ":hover": {background: "orange"}}}
                                                    onClick={(e) => [setOpenRevokeApplication(true), setRevoke(false)]}
                                                >
                                                    SUSPEND
                                                </Button>
                                            </Grid>


                                            <Grid item xs={12} sx={{mt: 3}}>
                                                <Typography sx={{textAlign: 'center', fontWeight: 550, fontSize: 16}}>Misc</Typography>
                                            </Grid>

                                            <Grid item  sm={12}>
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => setOpenAppBinding(true)}
                                                >
                                                    SEND APP BINDING
                                                </Button>
                                            </Grid>

                                            <Grid item  sm={12} >
                                                <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                    background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                    onClick={(e) => handleSentToEWallet(e)}
                                                    startIcon={sending && <ThreeDots height="20" width="20" radius="9" color="#fff" ariaLabel="three-dots-loading" visible={true} />}
                                                >
                                                    {sending ? 'SENDING' : 'PUSH EID'}
                                                </Button>
                                            </Grid>
                                            
                                        </>                        

                                : 
                                    (status == "Reject" ) ?  
                                        <>
                                            <Grid item xs={12} >
                                                <Button variant="contained" sx={{width: 'auto', backgroundColor: "#248bf1", borderRadius: 10, width: '100%', boxShadow: 'none', '&:hover': {backgroundColor: "#b5b5b5", color: "white"}}}
                                                    onClick={(e) => window.location.href = "/edit-record?id=" + eid?.uuid}>
                                                    Edit
                                                </Button>
                                            </Grid> 
                                            
                                        </>

                                : 
                                    (status == "Suspended") ? 
                                        <Grid item xs={12} >
                                            <Button variant="contained" sx={{borderRadius: 10, boxShadow: "none", width: '100%',
                                                background: "#248bf1", ":hover": {background: "#248bf1"}}}
                                                onClick={(e) => [setOpenRevokeApplication(true), setRevoke(false), setActivate(true)]}
                                            >
                                                ACTIVATE
                                            </Button>
                                        </Grid>
                                : 
                                    null
                                }
                            </Grid>
                        </Box>
                    </Grid>
                </Grid> 
            </Box>

            {openRejectApplication &&
                <RejectApplicationDialog breakpoints={breakpoints} openRejectApplication={openRejectApplication} 
                    setOpenRejectApplication={setOpenRejectApplication} disapproveEID={disapproveEID} eid={eid} loadingDisapproveEID={loadingDisapproveEID}
                />
            }

            {openApproveApplication &&
                <ApproveApplicationDialog breakpoints={breakpoints} openApproveApplication={openApproveApplication} eid={eid}
                    setOpenApproveApplication={setOpenApproveApplication} approveEID={approveEID} loadingApprovalEID={loadingApprovalEID}
                />
            }

            {openDownload &&
                <DownloadEIDDialog breakpoints={breakpoints} record={eid}  handleClose={handleCloseDownload}
                handleCloseDownload={handleCloseDownload} openDownload={openDownload} setOpenDownload={setOpenDownload}
                />
            }

            {openAppBinding && 
                <SendAppBindingDialog breakpoints={breakpoints}  handleCloseAppBinding={handleCloseAppBinding} setLoading={setLoading}
                    openAppBinding={openAppBinding} setOpenAppBinding={setOpenAppBinding} record={eid} isResend={isResend}  updateStatus={setStatus} // Pass setStatus function as prop
                    updateInstruction={setIsInstructionSent}
                />
            }

            {openRevokeApplication &&
                <RevokeApplicationDialog breakpoints={breakpoints}
                    openRevokeApplication={openRevokeApplication} setOpenRevokeApplication={setOpenRevokeApplication}
                    revoked={revoke} activated={activate} record={eid} 
                />
            }

            {openDelete &&
                <DeleteEIDDialog openDelete={handleOpenDelete} handleClose={handleCloseDelete}
                handleCloseDelete={handleCloseDelete} record={eid}  setLoading={setLoading} setIsDeleted={setIsDeleted} />
            }

            {openSelectSignatory &&
                <SelectSignatoryDialog breakpoints={breakpoints} recordId={recordId} signatories={signatories}
                    openSelectSignatory={openSelectSignatory} setOpenSelectSignatory={setOpenSelectSignatory} 
                />
            }

            {openCreateSignatory &&
                <CreateSignatoryDialog breakpoints={breakpoints} 
                    openCreateSignatory={openCreateSignatory} setOpenCreateSignatory={setOpenCreateSignatory}
                />
            }
        </>
    )
}

export default ViewEIDDetailsComponent