import React from "react";
import {Dialog, DialogContent, DialogTitle, Divider} from "@mui/material";
import SignatureTabs from "./signature-tabs.component";

const CreateSignatureDialogComponent = (props) => {

    const {breakpoints, openSignatureDialog, setOpenSignatureDialog, setSignatureFile, signature, signatureFile, setOpenSigEditor} = props;
 
    const handleCloseDialog = () => {
        setOpenSignatureDialog(false);
    }


    return (
        <Dialog open={openSignatureDialog} onClose={handleCloseDialog} maxWidth={"sm"} fullWidth={"sm"} PaperProps={{style: {boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", borderRadius: 5}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 16, display: "flex"}}>
                Create Electronic Signature
            </DialogTitle>

            <Divider />
            
            <DialogContent sx={{padding: 3, paddingTop: 2}}>
               <SignatureTabs breakpoints={breakpoints}
                    setOpenSignatureDialog={setOpenSignatureDialog}
                    signatureFile={signatureFile} setSignatureFile={setSignatureFile} 
                    signature={signature}  setOpenSigEditor={setOpenSigEditor}
                />
            </DialogContent>
        </Dialog>
    )
}

export default CreateSignatureDialogComponent