import React from "react";
import {Skeleton, Button, Box, Grid, Typography} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const EditRecordSkeletonComponent = (props) => {

    const {breakpoints, uuid} = props;

    function BackButton () {
        return <Button startIcon={
            <ArrowBackIosIcon style={{width: 20, height: 20, color: "#0178f2"}} />} 
                style={{color: "black", fontSize: 16, textTransform: "none", fontWeight: 600}}
                onClick={(e) => window.location.href = "/view-record?id=" + uuid}
            >
                Back
            </Button>
    }

    
    return (
        <>
            <Box sx={{border: "0.1px solid rgb(0 0 0 / 12%)", borderRadius: 2, padding: breakpoints == 'xs' || breakpoints == 's' ? 1 : 3, pt: 1.5}}>
                <BackButton />

                <form noValidate autoComplete="off">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            ID No:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            Position:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            First Name:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Middle Name:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Last Name:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Email Address:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Date of Birth:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Place of Birth:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid> 

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Address:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>
                                
                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Gender:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} /> 
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Blood Type:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} /> 
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} sx={{alignItems: 'center', alignSelf: 'center'}}>
                                        <Typography sx={{fontWeight: 'bold'}}>
                                            Marital Status:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            Contact Person:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>

                                <Grid container sx={{pt: 1}}>
                                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{alignItems: "center", alignSelf: "center"}}>
                                        <Typography sx={{fontWeight: "bold"}}>
                                            Contact Person No:
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                                        <Skeleton variant="rectangular" height={50} width="100%" style={{margin: "3px 0px", backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={5} sx={{mt: "5px"}}>
                                <Grid container spacing={3} sx={{alignItems: 'center', justifyContent:  'center' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container sx={{alignItems: "center", justifyContent: "center"}}>
                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                               <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 125 : 160} width="100%" style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: "center", textAlign: "center", mt: "10px"}}>
                                                <Grid container sx={{justifyContent: "center"}}>
                                                    <Typography variant="subtitle2" style={{mb: "10px", fontSize: 16, fontWeight: 550}} className="required-field">
                                                        Photo
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6} sx={{mt: "10px"}}>
                                        <Grid container sx={{alignItems: 'center', justifyContent:  'center', mt: "-10px"}}>
                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Skeleton variant="rectangular" height={(breakpoints == "xs" || breakpoints == "sm") ? 125 : 160} width="100%" style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} sx={{justifyContent: 'center', textAlign: 'center'}}>
                                                <Grid container sx={{justifyContent: 'center'}}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Typography variant="subtitle2" sx={{mt: "10px", fontSize: 16, fontWeight: 550}} className="required-field">
                                                            e-Signature
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>   
                    </Grid>

                    <Grid container direction="row" sx={{justifyContent: "flex-end", textAlign: "end", pb: (breakpoints == "xs" || breakpoints == "sm") ? 0 : 3, pt: 3, mt: (breakpoints == "xs" || breakpoints == "sm") ? 3 : {}}}>
                        <Skeleton variant="rectangular" height={30} width={(breakpoints == "xs" || breakpoints == "sm") ? "20%" : "15%"} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />

                        &nbsp; &nbsp;   
                
                        <Skeleton variant="rectangular" height={30} width={(breakpoints == "xs" || breakpoints == "sm") ? "20%" : "15%"} style={{backgroundColor: "rgb(108 139 222 / 30%)"}} />
                    </Grid>
                </form>
            </Box>
        </>
    )
}

export default EditRecordSkeletonComponent