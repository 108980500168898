import React from 'react';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Skeleton } from "@mui/material";

const ActivityLogsSkeletonLoaderComponent = (props) => {

    return (
        <Grid container sx={{ mt: 1, pl: 2, pr: 2 }}>
            <Grid item xs={12}>
                <Box sx={{border: "none"}}>
                    <Box sx={{ p: 1 }}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <Skeleton variant="circular" width={20} height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Skeleton variant="circular" width={20} height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Skeleton variant="circular" width={20} height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Skeleton variant="circular" width={20} height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Skeleton variant="circular" width={20} height={20} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default ActivityLogsSkeletonLoaderComponent;
