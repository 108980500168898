import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography} from "@mui/material";

const DefaultSignatoryDialog = (props) => {

    const {breakpoints, record, openDefaultSignatory, setOpenDefaultSignatory} = props;

    const handleCloseDialog = (e) => {
        setOpenDefaultSignatory(false);    
    }


    return (
        <Dialog open={openDefaultSignatory} onClose={(e) => handleCloseDialog(e)} maxWidth={'sm'} fullWidth={'sm'} PaperProps={{style: {boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: 10}}}>
            <DialogTitle style={{fontWeight: "bold", fontSize: 20}}>
                Default Signatory
            </DialogTitle>

            <Divider />

            <DialogContent sx={{padding: (breakpoints == "xs" || breakpoints == "sm") ? "48px 32px" : 6, paddingTop: 2}}>
                <Typography>
                    <strong>{record.name}</strong> is the default signatory, deleting it is strictly forbidden.
                </Typography>
            </DialogContent>

            <DialogActions sx={{padding: 6, paddingTop: 0, paddingBottom: 3}}>
                <Button variant="contained" sx={{textTransform: "none", width: 75, backgroundColor: "#0178f2", borderRadius: 10, boxShadow: "none"}}
                    onClick={(e) => handleCloseDialog(e)}
                >
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DefaultSignatoryDialog