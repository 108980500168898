import {Box, Card, Grid} from "@mui/material";
import BackButton from "../components/common/back-button";
import ProfileComponent from "../components/profile/profile.component";
import useWidth from "../hooks/useWidth";
import ProfileService from "../services/api/user";
import { useEffect, useState } from "react";
import ViewProfileSkeletonComponent from "../components/skeletons/view-profile-skeleton-loader.component";

function ProfileScreen(){

    const breakpoints = useWidth();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const viewProfile = () => {
        setLoading(true);
        ProfileService.viewUser().then((res)=>{
            setLoading(false);
            console.log('get profile res', res)
            setUser(res.data.data)
        }).catch((error) => {
            console.log('get profile error', error)
            setLoading(false);
        })  
    }

    useEffect(()=>{
        viewProfile()
    },[])

    return(
        <Box sx={{width: (breakpoints == 'xs' || breakpoints == 'sm') ? '85%' : '95%', margin:'auto', textAlign:'center'}}>
            <Box sx={{textAlign: 'start'}}>
                <BackButton />
            </Box>

            {!loading ?
                <Card sx={{textAlign:'center', padding: breakpoints == 'xs' ? 1 : 6, pl: 0, pr: 0, borderRadius: 2,  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", width: breakpoints == 'xs' ? "100%" : "95%", margin: 'auto'}}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} >
                            {user && <ProfileComponent user={user} breakpoints={breakpoints} /> }
                        </Grid>
                    </Grid>
                </Card>
            :
                <ViewProfileSkeletonComponent breakpoints={breakpoints} />
            }
        </Box>
    )
}

export default ProfileScreen