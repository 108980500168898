import { Box, Card, CardContent, CardHeader, Grid, Skeleton} from "@mui/material";
import SignatoryPopper from "../signatory/signatory-popper.component";



const SignatoryPopperHeader = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SignatoryPopper />
        </Box>
    );
  };
  

const SignatoryListSkeletonLoader = (props) => {

    const { breakpoints } = props;

    return (
        <>
            <Grid item xs={12} sm={6} md={4} >
                <Card sx={{ borderRadius: 2, padding: breakpoints === 'xs' || breakpoints === 's' ? 1 : 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints === 'xs' || breakpoints === 'sm' ? 'auto' : 340 }}>
                    <CardHeader
                        sx={{ pt: 0.8, pb: 0 }}
                        action={<SignatoryPopperHeader />}
                    />
                    <CardContent sx={{ textAlign: "center", pt: 0 }}>
                        <Skeleton  variant="rectangular" height={200} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        <Box sx={{ mt: 1 }}>
                            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}}  />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
                <Card sx={{ borderRadius: 2, padding: breakpoints === 'xs' || breakpoints === 's' ? 1 : 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints === 'xs' || breakpoints === 'sm' ? 'auto' : 340 }}>
                    <CardHeader
                        sx={{ pt: 0.8, pb: 0 }}
                        action={<SignatoryPopperHeader />}
                    />
                    <CardContent sx={{ textAlign: "center", pt: 0 }}>
                        <Skeleton  variant="rectangular" height={200} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        <Box sx={{ mt: 1 }}>
                            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}}  />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
                <Card sx={{ borderRadius: 2, padding: breakpoints === 'xs' || breakpoints === 's' ? 1 : 0, boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', height: breakpoints === 'xs' || breakpoints === 'sm' ? 'auto' : 340 }}>
                    <CardHeader
                        sx={{ pt: 0.8, pb: 0 }}
                        action={<SignatoryPopperHeader />}
                    />
                    <CardContent sx={{ textAlign: "center", pt: 0 }}>
                        <Skeleton  variant="rectangular" height={200} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        <Box sx={{ mt: 1 }}>
                            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}} />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Skeleton variant="rectangular" height={30} style={{backgroundColor: 'rgb(108 139 222 / 30%)'}}  />
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </>

    )
    
}

export default SignatoryListSkeletonLoader